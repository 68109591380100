// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-background-border {
  background: rgba(230, 105, 39, 0.2);
  border: 1px solid #e66927;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/deals/single-deals/components/ProductCard/ProductCard.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,yBAAyB;AAC3B","sourcesContent":[".custom-background-border {\r\n  background: rgba(230, 105, 39, 0.2);\r\n  border: 1px solid #e66927;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
