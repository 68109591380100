import React, { useEffect, useState } from 'react';
import useMediaQuery from "../../../hooks/useMediaQuery";
import flashsale from  "../../../assets/images/v1/homepage/flashsale-desktop.png"
import CountdownTimer from '../Navigations/components/CountdownTimer';
import { useDispatch, useSelector } from 'react-redux';
import { getFlashsale } from '../../../store/slices/flashsaleSlice';
import { timeRemainingFromNow } from '../constant'; // Adjust this path
const MobileHeaderDown = ({data}) => {
    
    const dispatch = useDispatch();
    const homeFlashsale = useSelector((state) => state.home_flashsale.data);
    const [timerObject, setTimerObject] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    });
    
    const isMobile = useMediaQuery("(max-width: 768px)"); // Define mobile breakpoint
  
    useEffect(() => {
      dispatch(getFlashsale());
    }, [dispatch]);
  
    useEffect(() => {
      if (homeFlashsale && homeFlashsale.fldSaleEndDate) {
        setTimerObject(timeRemainingFromNow(homeFlashsale.fldSaleEndDate));
      }
    }, [homeFlashsale]);

  return (
    <>
    {isMobile && <div className="w-full phone:block">
    <div className="flex flex-col sm:flex-row">
      <div className="w-full px-[24px] pb-[8px] sm:pb-[16px] pt-[16px] flex justify-between sm:justify-start sm:gap-6 items-center bg-[#E04C3F]">
        <div className="w-[95px] h-[16px]">
          <img src={flashsale} className="w-full" alt="flashsale" />
        </div>
        <div className="w-[84px] h-[40px]">
          <img
            src="../assets/images/v1/homepage/discount-tablet.png"
            className="w-full"
            alt="discount"
          />
        </div>
      </div>
      <div className="w-full px-[24px] pb-[16px] pt-[8px] phone:pt-0 sm:pt-[16px] flex justify-between sm:justify-start sm:gap-6 bg-[#E04C3F]">
        <div className="w-fit h-[40px] ml-[37px] tablet:ml-[30px] phone:ml-0">
          {/* <div className="flex gap-[10px]">
            <div
              className="flex flex-col items-center gap-1.5"
              data-countdown="days"
            >
              <div className="flex gap-[2px] phone:py-3">
                <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                  <p
                    className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                    style={{
                      boxShadow:
                        "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    data-time-block={0}
                  >
                    0
                  </p>
                </div>
                <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                  <p
                    className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                    style={{
                      boxShadow:
                        "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    data-time-block={1}
                  >
                    0
                  </p>
                </div>
              </div>
              <p className="text-[8px] leading-[14px] text-white">
                DAYS
              </p>
            </div>
            <div
              className="flex flex-col items-center gap-1.5"
              data-countdown="hours"
            >
              <div className="flex gap-[2px] phone:py-3">
                <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                  <p
                    className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                    style={{
                      boxShadow:
                        "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    data-time-block={0}
                  >
                    0
                  </p>
                </div>
                <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                  <p
                    className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                    style={{
                      boxShadow:
                        "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    data-time-block={1}
                  >
                    0
                  </p>
                </div>
              </div>
              <p className="text-[8px] leading-[14px] text-white">
                HOURS
              </p>
            </div>
            <div
              className="flex flex-col items-center gap-1.5"
              data-countdown="minutes"
            >
              <div className="flex gap-[2px] phone:py-3">
                <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                  <p
                    className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                    style={{
                      boxShadow:
                        "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    data-time-block={0}
                  >
                    0
                  </p>
                </div>
                <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                  <p
                    className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                    style={{
                      boxShadow:
                        "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    data-time-block={1}
                  >
                    0
                  </p>
                </div>
              </div>
              <p className="text-[8px] leading-[14px] text-white">
                MINUTES
              </p>
            </div>
            <div
              className="flex flex-col items-center gap-1.5"
              data-countdown="seconds"
            >
              <div className="flex gap-[2px] phone:py-3">
                <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                  <p
                    className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                    style={{
                      boxShadow:
                        "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    data-time-block={0}
                  >
                    0
                  </p>
                </div>
                <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
                  <p
                    className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
                    style={{
                      boxShadow:
                        "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    data-time-block={1}
                  >
                    0
                  </p>
                </div>
              </div>
              <p className="text-[8px] leading-[14px] text-white">
                SECONDS
              </p>
            </div>
          </div> */}
           <CountdownTimer
            initialDays={timerObject.days}
            initialHours={timerObject.hours}
            initialMinutes={timerObject.minutes}
            initialSeconds={timerObject.seconds}
          />
        </div>
        <div className="flex flex-col items-center gap-1 px-[4px]">
          <div className="flex flex-col items-center gap-1 px-[4px] packages-banner-wrapper">
            <div className="flex gap-[2px]">
              <div>
                <p
                  className="text-white text-[32px] packages-value font-bold"
                  data-time-block={0}
                >
                  30
                </p>
              </div>
            </div>
            <p className="text-[8px] leading-[14px] text-white whitespace-nowrap">
              REMAINING PACKAGES
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full p-[24px] py-[16px] bg-[#0355A5] phone:flex phone:text-center">
      <a
        href="https://wholesaledeals.vercel.app/wholesale/subscribe.html"
        className="py-[8px] no-underline px-[24px] text-[16px] font-semibold leading-[24px] text-white bg-[#E66927] rounded-[8px] w-full"
      >
        Join Now
      </a>
    </div>
  </div>}
  </>
  )
}

export default MobileHeaderDown