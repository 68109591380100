import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { PLACEORDER } from "../api";
export const placeOrder = createAsyncThunk(
  "order/placeOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(PLACEORDER, data.payload, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    data: [],
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(placeOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.isLoading = false;
        // if (action.payload.message === "Forbidden: Invalid Token") {
        //   logout();
        // }
      });
  },
});

export default orderSlice.reducer;
