import React from "react";
import { Route } from "react-router-dom";
import { UnAuthGuard } from "../guards";
import { routes } from "./route";
import SingleDealsWrapper from "../components/pages/deals/single-deals/SingleDealsWrapper";

const Login = React.lazy(() => import("../components/pages/Login/Login"));
const Homepage = React.lazy(() =>
  import("../components/pages/Homepage/HomepageWrapper")
);
const DealWrapper = React.lazy(() =>
  import("../components/pages/deals/DealWrapper")
);
const DealMain = React.lazy(() => import("../components/pages/deals/DealMain"));

const TourStep1 = React.lazy(() =>
  import("../components/pages/Tours/components/TourStep1")
);
const TourStep2 = React.lazy(() =>
  import("../components/pages/Tours/components/TourStep2")
);
const TourStep3 = React.lazy(() =>
  import("../components/pages/Tours/components/TourStep3")
);
const TourStep4 = React.lazy(() =>
  import("../components/pages/Tours/components/TourStep4")
);

const AllTestimonials = React.lazy(() =>
  import("../components/pages/common/Testimonials/AllTestimonialsWrapper")
);
const Subscribe = React.lazy(() =>
  import("../components/pages/common/Subscribe/SubscribeWrapper")
);

const DealsBasketEmpty = React.lazy(() =>
  import(
    "../components/pages/user/Common/DealsBasketEmpty/DealsBasketEmptyWrapper"
  )
);

const DealsBasketNoLogin = React.lazy(() =>
  import(
    "../components/pages/user/Common/DealsBasketNoLogin/DealsBasketNoLoginWrapper"
  )
);

const DealsBasketFilled = React.lazy(() =>
  import(
    "../components/pages/user/Common/DealsBasketFilled/DealsBasketFilledWrapper"
  )
);
const SupplierCategoryWrapper = React.lazy(() =>
  import("../components/pages/SupplierCategory/SupplierCategoryWrapper")
);
const UnAuthRoutes = [
  <Route
    key="User Login"
    path="/userlogin"
    element={<UnAuthGuard component={<Login />} />}
  />,
  <Route
    key="Reset-password"
    path="/reset-password/:token"
    element={<UnAuthGuard component={<Login />} />}
  />,
  <Route key="homepage" path="/" element={<Homepage />} />,
  <Route
    key={`${routes.dealsSlug}`}
    path={`/${routes.dealsSlug}/:slug`}
    element={<DealWrapper />}
  />,
  <Route
    key={`${routes.deals}`}
    path={`/${routes.deals}`}
    element={<DealMain />}
  />,
  <Route key="tour-step-1" path="/tour-step-1" element={<TourStep1 />} />,
  <Route key="tour-step-2" path="/tour-step-2" element={<TourStep2 />} />,
  <Route key="tour-step-3" path="/tour-step-3" element={<TourStep3 />} />,
  <Route key="tour-step-4" path="/tour-step-4" element={<TourStep4 />} />,

  // <Route key="/my-deals-basket-filled" path="/my-deals-basket-filled" element={< DealsBasketFilled/>} />,
  <Route
    key="all-testimonials"
    path="/all-testimonials"
    element={<AllTestimonials />}
  />,
  <Route key="subscribe" path="/subscribe" element={<Subscribe />} />,
  <Route
    key="my-deals-basket-empty"
    path="/my-deals-basket-empty"
    element={<DealsBasketEmpty />}
  />,
  <Route
    key="my-deals-basket-no-login"
    path="/my-deals-basket-no-login"
    element={<DealsBasketNoLogin />}
  />,
  <Route
    key="/my-deals-basket-filled"
    path="/my-deals-basket-filled"
    element={<DealsBasketFilled />}
  />,
  <Route
    key={`${routes.single_deals}`}
    path={`/${routes.single_deals}`}
    element={<SingleDealsWrapper />}
  />,
  <Route
    key={`${routes.supplier_category}`}
    path={`/${routes.supplier_category}/:slug`}
    element={<SupplierCategoryWrapper />}
  />,
];

export default UnAuthRoutes;
