import React from "react";
import ReactDOM from "react-dom";

const Spinner = () => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center bg-white/70 backdrop-blur-sm z-[9999]">
      <div className="w-8 h-8 border-4 border-t-4 border-t-orange-500 border-orange-200 rounded-full animate-spin"></div>
    </div>,
    document.body
  );
};

export default Spinner;
