import React, { useEffect, useRef, useState } from "react";
import chevronDown from "../../../../../assets/images/v1/icons/chevron-down.svg";
import search from "../../../../../assets/images/esources/suppliers/search.svg";
import trash from "../../../../../assets/images/v1/deals/trash.svg";
import SearchValueFilter from "./SearchValueFilter";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalSearch } from "../../../../../store/slices/globalSearchSlice";
const SearchFilterComponent = ({
  onSearch,
  setSearchTerm,
  searchInputRef,
  searchValue,
  setSearchValue,
  searchValues,
  setSearchValues,
  searchMode,
  setSearchMode,
  sortBy,
  setSortBy,
}) => {
  // State for managing search values (array) and input field
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const dispatch = useDispatch();
  const handleSearch = (event) => {
    event.preventDefault();
    if (searchValue.trim()) {
      // Add the searchValue to the array of search terms
      const updatedSearchValues = [...searchValues, searchValue.trim()];
      setSearchValues(updatedSearchValues);
      setSearchMode(searchMode ? searchMode : "any");

      // Clear the input field
      setSearchValue("");
      // Trigger the search with the array of search terms
      onSearch(updatedSearchValues, searchMode ? searchMode : "any", sortBy);
    }
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchModeChange = (event) => {
    setSearchMode(event.target.value);
  };

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
  };

  const resetFilters = () => {
    // Reset searchValues, searchMode, and sortBy
    setSearchValue("");
    setSearchValues([]);
    setSearchMode("any");
    setSortBy("");
    setSearchTerm("");
    onSearch([], "any", ""); // Trigger search reset with default values
    dispatch(
      getGlobalSearch({
        typeOfSearch: "",
        searchValues: [],
      })
    );
  };

  // useEffect to trigger onSearch when searchMode or sortBy changes
  useEffect(() => {
    onSearch(searchValues, searchMode, sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMode, sortBy, searchValues]);

  const removeSearchValue = (index) => {
    // Remove the search term at the specific index
    if (searchValues.length < 1 || searchValues.length == 0) {
      setSearchValues([]);
      setSearchTerm("");
      onSearch([], searchMode, sortBy);
    } else {
      const updatedSearchValues = searchValues.filter((_, i) => i !== index);
      setSearchValues(updatedSearchValues);
      // Trigger the search with updated values
      onSearch(updatedSearchValues, searchMode, sortBy);
    }
    dispatch(
      getGlobalSearch({
        typeOfSearch: "",
        searchValues: [],
      })
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchValue.trim()) {
        // Add the searchValue to the array of search terms
        const updatedSearchValues = [...searchValues, searchValue.trim()];
        setSearchValues(updatedSearchValues);
        setSearchMode(searchMode ? searchMode : "any");

        // Clear the input field
        setSearchValue("");
        // Trigger the search with the array of search terms
        onSearch(updatedSearchValues, searchMode ? searchMode : "any", sortBy);
      }
      handleSearch(event);
    }
  };

  return (
    <>
      <div className="w-full flex tablet:flex-col tablet:items-start phone:flex-col phone:items-start tablet:gap-[12px] items-center justify-between tablet:my-[12px]">
        {/* Filter Inputs */}
        <div className="flex items-center phone:w-full phone:flex-col tablet:w-full w-[601px] relative">
          <div className="relative w-full phone:max-w-none max-w-[206px] flex items-center">
            <div className="w-[16px] h-[16px] bg-transparent flex items-center justify-between absolute right-[16px] bg-white pointer-events-none">
              <img
                data-icon="arrow"
                src={chevronDown}
                className={`w-[24px] h-[24px] transition-transform z-0${
                  isOpen ? "rotate-180" : ""
                }`}
                alt="dropdown arrow"
              />
            </div>
            <select
              onClick={() => setIsOpen(!isOpen)}
              value={searchMode}
              onChange={handleSearchModeChange} // Update search mode
              className="py-[8px] w-full phone:w-full phone:border phone:rounded-t-[8px] phone:rounded-bl-[0px] phone:border-b-[0px] h-[39px] bg-white px-[16px] rounded-l-[8px] border border-r-[0px] border-neutral-300 text-[14px] leading-[21px] text-neutral-700 appearance-none"
            >
              <option value="any">Any of my keywords</option>
              <option value="all">All keywords in any order</option>
              <option value="exact">All keywords in the exact order</option>
            </select>
          </div>
          <div className="relative w-full">
            <input
              type="text"
              value={searchValue} // Single input field value
              onChange={handleInputChange}
              ref={searchInputRef}
              className="h-[39px] w-full phone:rounded-l-[8px] phone:rounded-t-[0px] py-[8px] px-[16px] rounded-r-[8px] border border-neutral-300 text-[14px] leading-[21px]"
              placeholder="What are you looking for?"
              name="search"
              id=""
              onKeyDown={handleKeyDown}
            />
            <img
              data-name="search-icon"
              src={search}
              className="w-[18px] h-[18px] phone:top-[12px] absolute top-[11px] right-[16px]"
              alt="Search icon"
              onClick={handleSearch} // Trigger search on icon click
            />
          </div>
        </div>

        {/* Reset Button */}
        <div className="w-full hidden  items-center gap-[8px]">
          <button
            className="flex gap-2 items-center px-[16px] py-[8px] phone:hidden"
            onClick={resetFilters}
          >
            <p className="phone:hidden text-secondary-main text-[14px] font-semibold leading-[21px] tracking-[0.2px]">
              Reset Filters
            </p>
            <img
              src={trash}
              className="w-[18px] h-[18px] text-secondary-main"
              alt="Reset icon"
            />
          </button>
        </div>

        {/* Sorting */}
        <div className="phone:hidden flex items-center w-[309px] gap-[8px] relative">
          <div className="w-[16px] h-[16px] bg-white flex items-center justify-between absolute right-3 pointer-events-none">
            <img
              data-icon="arrow"
              src={chevronDown}
              className={`w-[24px] h-[24px] transition-transform z-0${
                isOpenSort ? "rotate-180" : ""
              }`}
              alt="dropdown arrow"
            />
          </div>
          <p className="text-[14px] font-normal text-neutral-500 min-w-fit leading-[21px]">
            Sort offers :
          </p>
          <select
            onClick={() => {
              setIsOpenSort(!isOpenSort);
            }}
            value={sortBy}
            onChange={handleSortBy}
            className="w-full py-[8px] h-[38.4px] phone:w-full rounded-[8px] bg-white px-[16px] border border-neutral-300 text-[14px] leading-[21px] text-neutral-900 appearance-none"
          >
            <option value="best_match">Best Match</option>
            <option value="newest_first">Date Added: Newest First</option>
            <option value="oldest_first">Date Added: Oldest First</option>
            <option value="markup_high_to_low">%Markup: High to Low</option>
            <option value="markup_low_to_high">%Markup: Low to High</option>
            <option value="best_sellers_high_to_low">
              Best Sellers: High to Low
            </option>
            <option value="best_sellers_low_to_high">
              Best Sellers: Low to High
            </option>
            <option value="wholesale_price_low_to_high">
              Wholesale Price: Low to High
            </option>
            <option value="wholesale_price_high_to_low">
              Wholesale Price: High to Low
            </option>
            <option value="min_quantity_low_to_high">
              Minimum Quantity: Low to High
            </option>
            <option value="min_quantity_high_to_low">
              Minimum Quantity: High to Low
            </option>
            <option value="gross_profit_high_to_low">
              Gross Profit: High to Low
            </option>
            <option value="gross_profit_low_to_high">
              Gross Profit: Low to High
            </option>
            <option value="rrp_low_to_high">RRP: Low to High</option>
            <option value="rrp_high_to_low">RRP: High to Low</option>
            <option value="dropship_only">Dropship: Deals Only</option>
          </select>
        </div>
      </div>
      <SearchValueFilter
        searchValues={searchValues}
        removeSearchValue={removeSearchValue}
        resetFilters={resetFilters}
      />
      <div className="desktop:hidden phone:w-full  flex items-center w-[309px] gap-[8px] relative">
        <div className="w-[16px] h-[16px] bg-white flex items-center justify-between absolute right-3 pointer-events-none">
          <img
            data-icon="arrow"
            src={chevronDown}
            className={`w-[24px] h-[24px] transition-transform z-0${
              isOpenSort ? "rotate-180" : ""
            }`}
            alt="dropdown arrow"
          />
        </div>
        <p className="text-[14px] font-normal text-neutral-500 min-w-fit leading-[21px]">
          Sort offers :
        </p>
        <select
          onClick={() => {
            setIsOpenSort(!isOpenSort);
          }}
          value={sortBy}
          onChange={handleSortBy}
          className="w-full py-[8px] h-[38.4px] phone:w-full rounded-[8px] bg-white px-[16px] border border-neutral-300 text-[14px] leading-[21px] text-neutral-900 appearance-none"
        >
          <option value="best_match">Best Match</option>
          <option value="newest_first">Date Added: Newest First</option>
          <option value="oldest_first">Date Added: Oldest First</option>
          <option value="markup_high_to_low">%Markup: High to Low</option>
          <option value="markup_low_to_high">%Markup: Low to High</option>
          <option value="best_sellers_high_to_low">
            Best Sellers: High to Low
          </option>
          <option value="best_sellers_low_to_high">
            Best Sellers: Low to High
          </option>
          <option value="wholesale_price_low_to_high">
            Wholesale Price: Low to High
          </option>
          <option value="wholesale_price_high_to_low">
            Wholesale Price: High to Low
          </option>
          <option value="min_quantity_low_to_high">
            Minimum Quantity: Low to High
          </option>
          <option value="min_quantity_high_to_low">
            Minimum Quantity: High to Low
          </option>
          <option value="gross_profit_high_to_low">
            Gross Profit: High to Low
          </option>
          <option value="gross_profit_low_to_high">
            Gross Profit: Low to High
          </option>
          <option value="rrp_low_to_high">RRP: Low to High</option>
          <option value="rrp_high_to_low">RRP: High to Low</option>
          <option value="dropship_only">Dropship: Deals Only</option>
        </select>
      </div>
    </>
  );
};

export default SearchFilterComponent;
