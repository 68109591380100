

export const routes = {
  dealsSlug: "deals",
  supplierDealsSlug: "supplier-deals",
  deals: "deals",
  tourStep1: "/tour-step-1",
  tourStep2: "/tour-step-2",
  tourStep3: "/tour-step-3",
  tourStep4: "/tour-step-4",
  resetpassword: "/reset-password",
  userlogin: "/login",
  alltestimonials: "/all-testimonials",
  subscribe: "/subscribe",
  thankYou: "/thank-you",
  verifyEmail: "/verify-email",
  subscribeSuccess: "/subscribe-success",
  single_deals: "deal/:slug",
  single_deals_root: "/deal/",
  single_supplier: "supplier/:slug",
  single_supplier_root: "/supplier/",
  DealsBasket: "/cart",
  alphabetic_search: "alphabetical-keyword-index",
  contact:"/contact-us",
  Cookies:"/cookies",
  supplier_category_slug: "suppliers",
};

export const routesforAuth = [
  {
    dealsSlug: "/deals",
    supplierDealsSlug: "/supplier-deals",
    deals: "/deals",
    tourStep1: "/tour-step-1",
    tourStep2: "/tour-step-2",
    tourStep3: "/tour-step-3",
    tourStep4: "/tour-step-4",
    resetpassword: "/reset-password",
    userlogin: "/login",
    alltestimonials: "/all-testimonials",
    subscribe: "/subscribe",
    thankYou: "/thank-you",
    verifyEmail: "/verify-email",
    DealsBasketEmpty: "/cart-empty",
    DealsBasketNoLogin: "/cart-no-login",
    DealsBasketFilled: "/cart-filled",
    single_deals: "deal/:slug",
    single_supplier: "/single-supplier/",
    supplier_category: "supplier-category",
    supplier_category_slug: "suppliers",
    alphabetic_search: "alphabetical-keyword-index",
    contact:"/contact-us",
  
    PrivacyPolicy:"/privacy-policy",
    TermAndUse:"/terms-and-conditions",
    Cookies:"/cookies",
  },
];
