
export const getConvertedRate = (rate,currencyCode) => {
  switch (currencyCode) {
    case "EUR":
      return (Number(rate) * 0.85).toFixed(2);
    case "USD":
      return (Number(rate) * 0.78).toFixed(2);
    case "GBP":
      return Number(rate).toFixed(2);
    default:
      return Number(rate).toFixed(2);
  }
};

