import React, { useEffect, useRef } from "react";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/Checklist-Icon.svg";
import DeleteSvgIcon from "../../../assets/icons/JSXIcons/DeleteSvgIcon.jsx";
import ReactDOM from "react-dom";

const toastIcons = {
  success: SuccessIcon,
  error: DeleteSvgIcon,
};

const CustomToast = ({ type, message, closeToast, customImage }) => {
  const timerRef = useRef(null);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      closeToast();
    }, 3000);
    return () => clearTimeout(timerRef.current);
  }, [closeToast]);

  const handleClose = () => {
    clearTimeout(timerRef.current);
    closeToast();
  };

  let bgClasses = "";
  let borderClasses = "";
  let textClasses = "";

  switch (type) {
    case "success":
      bgClasses = "bg-green-100";
      borderClasses = "border-green-500";
      textClasses = "text-green-800";
      break;
    case "error":
      bgClasses = "bg-red-100";
      borderClasses = "border-[#E04C3F]";
      textClasses = "text-[#E04C3F]";
      break;
    case "info":
      bgClasses = "bg-blue-100";
      borderClasses = "border-blue-900";
      textClasses = "text-blue-800";
      break;
    case "warning":
      bgClasses = "bg-yellow-100";
      borderClasses = "border-yellow-900";
      textClasses = "text-yellow-800";
      break;
    case "orange":
      bgClasses = "bg-orange-100";
      borderClasses = "border-primary-main";
      textClasses = "text-primary-main";
      break;
    default:
      bgClasses = "bg-gray-100";
      borderClasses = "border-gray-500";
      textClasses = "text-gray-800";
  }

  const IconComponent = !customImage && toastIcons[type];

  return ReactDOM.createPortal(
    <>
      <div
        style={{
          position: "fixed",
          top: "20px",
          right: "20px",
          zIndex: 9999,
          animation: "toastIn 0.3s ease-out",
        }}
        onClick={handleClose}
      >
        <div
          className={`flex items-center p-4 ${bgClasses} ${textClasses} rounded-t-2xl rounded-b-md shadow-md relative overflow-hidden`}
        >
          <div
            className={`absolute bottom-0 left-0 h-1 ${borderClasses.replace(
              "border",
              "bg"
            )} progress-bar`}
          />
          <div className="mr-3 text-2xl">
            {customImage ? (
              <img src={customImage} alt="Toast Icon" className="w-8 h-8" />
            ) : IconComponent ? (
              <IconComponent className="w-8 h-8" />
            ) : null}
          </div>
          <div className="flex-1">
            <div className="phone:text-[14px] text-[18px] leading-[135%] phone:text-center">
              {message}
            </div>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            className={`ml-4 text-base ${textClasses} focus:outline-none`}
          >
            ✖
          </button>
        </div>
      </div>
      <style>
        {`
          @keyframes toastIn {
            from { opacity: 0; transform: translateY(-20px); }
            to { opacity: 1; transform: translateY(0); }
          }
          @keyframes progress {
            from { width: 0%; }
            to { width: 100%; }
          }
          .progress-bar {
            animation: progress 3s linear forwards;
          }
        `}
      </style>
    </>,
    document.body
  );
};

export default CustomToast;
