import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETCOMMONDEALS } from "../api";

export const getCommonDeals = createAsyncThunk(
  "/getCommonDeals",
  async (data, { rejectWithValue }) => {

    try {
    
      const queryString = data.params.toString() ? `?${data.params.toString()}` : "";
      // Fetch deals based on the dealId and other parameters
      const response = data.token
        ? await axios.get(`${GETCOMMONDEALS}${queryString}`, {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          })
        : await axios.get(`${GETCOMMONDEALS}${queryString}`);

      // Handle potential empty response
      if (!response.data) {
        return rejectWithValue("No deals found.");
      }

      return response.data; // Ensure this is the expected format
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the deal profile."
      );
    }
  }
);

const initialState = {
  data: {},
  isLoading: true,
  currentPage: 1,
  itemsPerPage: 10,
  totalPage: 0,
  error: null,
};

const commonDealsSlice = createSlice({
  name: "common_deals",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommonDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCommonDeals.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCommonDeals.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setPage, setError } = commonDealsSlice.actions;
export default commonDealsSlice.reducer;
