import { API_STARTING_FORIMAGE } from "../../../store/api";

export const getProductMultipleOrSingleImage = (
  imagename1,
  imagename2,
  imagename3
) => {
  const imagearr = [];

  // Push only non-null and non-empty image names to the array
  [imagename1, imagename2, imagename3].forEach((imagename) => {
    if (imagename) {
      imagearr.push(`${API_STARTING_FORIMAGE}/uploads/deals-images/${imagename}`);
    }
  });

  return imagearr;
};

export const getProductingleImage = (imagename) => {
  const image = `${API_STARTING_FORIMAGE}/uploads/deals-images/${imagename}`;
  return image;
};
export const getProductingleImageBuyer = (imagename) => {
  const image = `${API_STARTING_FORIMAGE}/
uploads/buyer-logos/${imagename}`;
  return image;
};
export const getProductingleImageSeller = (imagename) => {
  const image = `${API_STARTING_FORIMAGE}/uploads/supplier-logos/${imagename}`;
  return image;
};
