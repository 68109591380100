//for local
// const API_STARTING = "http://localhost:8000/api";
export const API_STARTING = "https://gazaniainfotech.ngrok.dev/api";
export const API_STARTING_FORIMAGE = "https://gazaniainfotech.ngrok.dev";
//for prod

//const API_STARTING="";

export const LOGIN = `${API_STARTING}/user/login`;
export const REGISTER = `${API_STARTING}/user/register`;
export const GETUSERBYID = `${API_STARTING}/user/get-user-by-id`;
export const SENDEMAILTOUSER = `${API_STARTING}/user/forgot-password`;
export const VALIDATETOKEN = `${API_STARTING}/user/reset-password`;
export const RESETPASSWORD = `${API_STARTING}/user/reset-password`;
export const GETCOUNTRYLIST = `${API_STARTING}/common/get-countries`;
export const GETLANGUAGELIST = `${API_STARTING}/common/get-languages`;
export const UPDATEUSERBYID = `${API_STARTING}/user/profile`;
export const UPDATEUSERNAME = `${API_STARTING}/user/change-username`;
export const UPDATEUSERPASSWORD = `${API_STARTING}/user/change-password`;
export const GETBUYERPROFILE = `${API_STARTING}/user/buyer-profile`;
export const UPDATEBUYERPROFILE = `${API_STARTING}/user/buyer-profile`;
export const GETALLCATEGORIES = `${API_STARTING}/common/get-categories`;
export const GETCURRENCIES = `${API_STARTING}/common/get-currencies`;
export const GETSUPPLIERPROFILE = `${API_STARTING}/user/supplier-profile`;
export const UPDATESUPPLIERPROFILE = `${API_STARTING}/user/supplier-profile`;
export const GETDEALS = `${API_STARTING}/user/deals`;
export const ELASTICSEARCH = `${API_STARTING}/user/brands/search`;
export const ADD_DEALS = `${API_STARTING}/user/add-deal`;
export const GETCOMMONDEALS = `${API_STARTING}/common/fetch-deals`;
export const GETRETAILERSLIST = `${API_STARTING}/common/get-retailers`;
export const MOST_POPULAR_RECENT_DEALS = `${API_STARTING}/common/most-popular-recent-deals`;
export const VALIDATEUSERTOKEN = `${API_STARTING}/user/validate-token`;
export const RECENTLY_ADDED_DEALS = `${API_STARTING}/common/recently-added-deals`;
export const SCROLLER_DEALS = `${API_STARTING}/common/scroller-deals`;
export const CHECK_USERNAME = `${API_STARTING}/user/check-username`;
export const GET_MANAGE_DEALSBYID = `${API_STARTING}/user/get-deal-by-id`;
export const UPDATE_DEALS = `${API_STARTING}/user/edit-deal`;
export const GET_TESTIMONIALS = `${API_STARTING}/common/testimonials`;
export const DEALS_FAVORITE_CATEGORIES = `${API_STARTING}/user/get-favorite-categories?type=2`;
export const LATEST_SUPPLIERS = `${API_STARTING}/common/latest-suppliers`;
export const FAV_CATEGORIES = `${API_STARTING}/user/category-add-favourite`;
export const CATEGORIES_FAVORITE_CATEGORIES = `${API_STARTING}/user/get-favorite-categories?type=3`;
export const LIKE_CATEGORY = `${API_STARTING}/user/category-add-favourite`;
export const DISLIKE_CATEGORY = `${API_STARTING}/user/remove-category-favorite`;
export const GET_ALL_TESTIMONAILS = `${API_STARTING}/common/all-testimonials`;
export const REPORT_DATA = `${API_STARTING}/user/report-supplier`;
export const GET_SINGLE_DEALS = `${API_STARTING}/common/get-deal-detail`;
export const GET_STATS = `${API_STARTING}/common/get-stats`;
export const GET_FLASHSALE = `${API_STARTING}/common/get-flashsale`;
export const GETSUPPLIERS_CATEGORY=`${API_STARTING}/common/fetch-suppliers`;
 export const FAVORITE_SUPPLIERS= `${API_STARTING}/user/get-favorite-suppliers`;
 export const  FAVORITE_DEALS= `${API_STARTING}/user/get-favorite-deals`;