import React, { useEffect, useState } from "react";
import filterIcon from "../../../../../assets/images/v1/deals/filter.svg";
import closeIcon from "../../../../../assets/images/v1/homepage/close.svg";
import FilterDropdown from "./../Filter/FilterDropdown";
import CountryFilterDropdown from "../CountryFilterDropdown";
import { useDispatch, useSelector } from "react-redux";
import GradeDropDown from "../GradeDropDown";
import FilterDescriptionDropdown from "./../Filter/FilterDescriptionDropdown";
import { getIdBySlug, getSlugFromUrl } from "../../constant";
import { useLocation } from "react-router-dom";
import { getCommonDeals } from "../../../../../store/slices/commonDealsSlice";
import { mostPopularRecentDeals } from "../../../../../store/slices/wholesalesuppliersSlice";
import { getScrollerDeals } from "../../../../../store/slices/scrollDealsSlice";
import SubscribeCard from "../SubscribeCard/SubscribeCard";
import { useMediaQuery } from "../../../../../hooks";

const MobileTabletFilterComponent = ({ auth, isPremium }) => {
  const isMobile=useMediaQuery("(max-width:768px)")
  const [filterOpen, setFilterOpen] = useState(false);

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const countries = useSelector((state) => state.country.data);
  const categories = useSelector((state) => state.categories.data);
  const deals = useSelector((state) => state.common_deals.data);

  const location = useLocation();
  const dispatch = useDispatch();
  // changes for filters api call

  const GetCurrentUrl = () => {
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

    return fullUrl;
  };
  // Get the current page, search query, and search mode from query params
  const slug = getSlugFromUrl(GetCurrentUrl());
  const id = getIdBySlug(categories, slug);
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const initialSearchTerm = queryParams.get("search") || "";
  const initialSearchMode = queryParams.get("searchMode") || "";
  const initialSortBy = queryParams.get("sortBy") || "";
  const initialCountry = queryParams.get("country")
    ? queryParams.get("country").split(",").map(Number)
    : [];
  const initialGrade = queryParams.get("product_condition")
    ? queryParams.get("product_condition").split(",")
    : [];
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchMode, setSearchMode] = useState(initialSearchMode);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [selectedGradeOptions, setSelectedGradeOptions] =
    useState(initialGrade);
  const [selectedCountries, setSelectedCountries] = useState(initialCountry);
  const gradeOptions = [
    { label: "New", value: "N" },
    { label: "Used", value: "U" },
    { label: "Returns / Mixed Stock", value: "MR" },
    { label: "Liquidation Stocklots", value: "O" },
    { label: "Refurbished", value: "R" },
  ];

  useEffect(() => {
    if(isMobile){
    dispatch(mostPopularRecentDeals());
    dispatch(getScrollerDeals());
    dispatch(
      getCommonDeals({
        dealId: id,
        page: 1,
        search: searchTerm,
        searchMode,
        sortBy,
        country: selectedCountries,
        product_condition: selectedGradeOptions,
      })
    );
  }
  }, [
    dispatch,
    selectedGradeOptions,
    selectedCountries,
    searchMode,
    sortBy,
    searchTerm,
    selectedGradeOptions,
    id,
    currentPage,
  ]);

  return (
    <div className="deals-filter">
      {/* Filter Button for screens up to 1280px */}
      <div className="flex lg:hidden items-center gap-2 py-3">
        <p className="text-neutral-500 font-normal text-sm leading-6">
          Filter Deals:
        </p>
        <button
          id="openfilter"
          className="flex items-center gap-2 py-2"
          onClick={toggleFilter}
        >
          <img src={filterIcon} className="w-5 h-5" alt="filter" />
          <p className="font-bold text-sm leading-6 text-neutral-900">
            View all available filters
          </p>
        </button>
      </div>

      {/* Filter Panel for Mobile and Tablet (up to 1280px) */}
      {filterOpen && (
        <div
          id="filterpanel"
          className="w-full h-screen bg-black/40 fixed z-30 top-0 left-0 overflow-y-scroll lg:max-w-screen-lg lg:mx-auto lg:max-h-[90vh]"
        >
          <div className="block w-full bg-white absolute z-50 top-0 left-0 lg:max-w-screen-lg lg:mx-auto lg:max-h-[90vh] lg:top-10">
            <div className="w-full px-4 py-4 flex items-center justify-between bg-white shadow-md">
              <h3 className="font-bold text-lg leading-7 text-neutral-900">
                Filter
              </h3>
              <button className="close-filterpanel" onClick={toggleFilter}>
                <img src={closeIcon} className="w-8 h-8" alt="close" />
              </button>
            </div>
            <FilterDropdown categories={categories} deals={deals} />
            <CountryFilterDropdown
              countries={countries}
              selectedCountries={selectedCountries || []}
              setSelectedCountries={setSelectedCountries}
            />
            <GradeDropDown
              title={"Grade"}
              options={gradeOptions}
              selectedGradeOptions={selectedGradeOptions}
              setSelectedGradeOptions={setSelectedGradeOptions}
            />
            <FilterDescriptionDropdown
              isOpenProps={true}
              heading={deals.categoryTitle}
              description={deals.categoryDescription}
            />
            <div className="p-5">
              {auth && !isPremium && (
                <>
                  <SubscribeCard classNames={"bg-primary-main"} />
                  <SubscribeCard classNames={"bg-additional-blue"} />
                </>
              )}
              {!auth && (
                <>
                  <SubscribeCard classNames={"bg-primary-main"} />
                  <SubscribeCard classNames={"bg-additional-blue"} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileTabletFilterComponent;
