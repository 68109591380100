import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FETCH_CART_DEALS } from "../api";
import logout from "../../shared/logout";

export const getCart = createAsyncThunk(
  "/getCart",
  async (token, { rejectWithValue }) => {
    // if (!data.token) {
    //   // If no token, return an empty array without making an API call
    //   return { data: [] };
    // }

    try {
      const response = await axios.get(FETCH_CART_DEALS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};

const getCartSlice = createSlice({
  name: "getCart",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCart.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCart.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload?.message === "Forbidden: Invalid Token") {
          logout();
        }
        state.error = action.payload;
      });
  },
});

export default getCartSlice.reducer;
