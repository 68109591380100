export const paymentOptionsData = [
  {
    icon: "../assets/images/v1/single/payment/cash.svg",
    title: "Cash",
    description:
      "Bills or coins paid by the recipient of goods or services to the provider.",
  },
  {
    icon: "../assets/images/v1/single/payment/bank.svg",
    title: "Bank Transfer",
    description: "Money is sent from one bank account to another.",
  },
  {
    icon: "../assets/images/v1/single/payment/online.svg",
    title: "Payment Online",
    description:
      "The electronic transfer of funds via the internet, usually between a merchant and a consumer.",
  },
];

export const deliveryOptions = [
  {
    icon: "../assets/images/v1/single/payment/collection.svg",
    title: "Collection in Person",
    description: "You can collect the item in person.",
  },
  {
    icon: "../assets/images/v1/single/payment/delivery.svg",
    title: "National Delivery",
    description:
      "Have the capabilities to use shipping routes that take a week to travel.",
  },
  {
    icon: "../assets/images/v1/single/payment/globe.svg",
    title: "International Delivery",
    description:
      "The process of transporting packages, or freight across borders to destinations outside of the sender's country.",
  },
];

export function deserializeStats(stats) {
  return [
    {
      title: "Average markup",
      value: `${stats.averageMarkup}%`,
      description: "Of wholesale prices",
      iconSrc: "../assets/images/v1/market/box.svg",
    },
    {
      title: "Active Deals",
      value: `${stats.activeDeals}+`,
      description: "as of today",
      iconSrc: "../assets/images/v1/market/bag.svg",
    },
    {
      title: "New Suppliers",
      value: `${stats.newSuppliers}+`,
      description: "in the past 7 days",
      iconSrc: "../assets/images/v1/market/open.svg",
    },
  ];
}

export function mapGrade(code) {
  const statusMap = {
    N: "New",
    U: "Used",
    R: "Refurbished",
    D: "Damaged", // This can be removed if not needed
    MR: "Mix / Returns",
    O: "Outlet",
  };

  return statusMap[code] || "Unknown";
}

export function getFullAddress(fldAddress, fldAddressline2) {
  return `${fldAddress || ""} ${fldAddressline2 || ""}`.trim();
}
