import Cookies from "js-cookie";
import { useRedirection } from "./";
import { createCookie, deleteCookie } from "../hooks/cookies";
const useAuth = () => {
  const { redirectLogin, redirectUserDashboard } = useRedirection();

  // const login = (user) => {
  //   createCookie(user);
  //   redirectLogin();
  // };

  const logout = () => {
    // setUserIsLoggedIn(false);
    deleteCookie();
    redirectLogin();
  };

  // Utility function to decode base64 URL encoded strings
  const decodeBase64Url = (str) => {
    // Convert from base64 URL encoding to base64 standard encoding
    const base64 = str.replace(/-/g, "+").replace(/_/g, "/");
    // Decode base64 string
    return decodeURIComponent(escape(atob(base64)));
  };

  const getUserInfo = () => {
    try {
      // Get the JWT from the cookie
      const token = Cookies.get("wd-team");
      const rememberme = Cookies.get("remember_me");
      if (token) {
        // Split the JWT into its components
        const [header, payload, signature] = token.split(".");
        // Decode the payload part of the JWT
        const user = JSON.parse(decodeBase64Url(payload));
        //Cookies.remove("wd-team");
        createCookie(token, rememberme);
        // Return the user information with a new timestamp
        return { ...user, timeStamp: Date.now() };
      }

      // Return null if no token was found
      return null;
    } catch (error) {
      // Handle any errors (e.g., malformed JWT, JSON parsing issues)
      console.error("Error decoding JWT:", error);
      return null;
    }
  };

  return {
    // login,
    logout,
    getUserInfo,
    createCookie,
    deleteCookie,
  };
};

export default useAuth;
