import React from "react";

// components
import TestimonialCard from "./TestimonialCard";

const TestimonialGrid = ({ testimonials }) => {
  return (
    <div className="w-full grid phone:hidden tablet:grid-cols-2 grid-cols-3 gap-[32px] mt-[40px] phone:mt-[16px]">
      {testimonials.map((testimonial, index) => (
        <TestimonialCard key={index} testimonial={testimonial} />
      ))}
    </div>
  );
};

export default TestimonialGrid;
