import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { SendEmailToUser } from "../../../../store/slices/passwordSlice"; // Assuming the action exists
import Spinner from "../../../../assets/spinner/Spinner"; // Import your Spinner component
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Validation schema for the form
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    try {
      setIsSubmitting(true); // Show loading spinner

      // Dispatch action to send email
      const response = await dispatch(SendEmailToUser(values));
      if (
        response.type === "auth/sendemail/fulfilled" 
      ) {
        setIsEmailSent(true); // Email sent successfully
        resetForm(); // Reset the form
        toast.success(response.payload.message); // Display success toast
        setIsEmailSent(false)
      } else if (response.type === "auth/sendemail/rejected") {
          setFieldError('email',response.payload.message)
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Something went wrong, please try again");
    } finally {
      setIsSubmitting(false); // Stop loading spinner
      setSubmitting(false); // Enable the submit button
    }
  };

  return (
    <>
        <>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting,errors }) => 
            isSubmitting || isEmailSent ? (
              <Spinner />
          ) : (
              <>
                <div className="flex flex-col gap-3 phone:gap-4">
                <div className="flex items-center gap-2">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.12" d="M15 15.8264C17.3649 14.7029 19 12.2924 19 9.5C19 5.63401 15.866 2.5 12 2.5C8.13401 2.5 5 5.63401 5 9.5C5 12.2924 6.63505 14.7029 9 15.8264V16.5C9 17.4319 9 17.8978 9.15224 18.2654C9.35523 18.7554 9.74458 19.1448 10.2346 19.3478C10.6022 19.5 11.0681 19.5 12 19.5C12.9319 19.5 13.3978 19.5 13.7654 19.3478C14.2554 19.1448 14.6448 18.7554 14.8478 18.2654C15 17.8978 15 17.4319 15 16.5V15.8264Z" fill="#E66927"></path>
                                    <path d="M9.5 22.5H14.5M10 10.5H14M12 10.5L12 16.5M15 15.8264C17.3649 14.7029 19 12.2924 19 9.5C19 5.63401 15.866 2.5 12 2.5C8.13401 2.5 5 5.63401 5 9.5C5 12.2924 6.63505 14.7029 9 15.8264V16.5C9 17.4319 9 17.8978 9.15224 18.2654C9.35523 18.7554 9.74458 19.1448 10.2346 19.3478C10.6022 19.5 11.0681 19.5 12 19.5C12.9319 19.5 13.3978 19.5 13.7654 19.3478C14.2554 19.1448 14.6448 18.7554 14.8478 18.2654C15 17.8978 15 17.4319 15 16.5V15.8264Z" stroke="#E66927" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                  <h5 className="text-xl text-neutral-900 font-bold phone:text-lg">
                    Lost Username or Password?
                  </h5>
                </div>
                <p className="text-sm leading-[21px] text-neutral-500">
                  Enter your email address below and we'll send you a link to your
                  registered email address.
                </p>
              </div>
              <Form id="forgotPasswordForm" className="mt-6">
                {/* Email Input */}
                <div className="flex flex-col gap-2 mb-4">
                  <label
                    htmlFor="email"
                    className="text-sm text-neutral-900 font-medium tracking-[0.2px]"
                  >
                    Enter your Email Address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter email address..."
                    className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal border border-neutral-200 px-4 py-3 rounded-lg"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                {/* Error Message */}
                {errors.general && (
                  <div className="text-red-600 text-sm mb-4">
                    {errors.general}
                  </div>
                )}
                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="py-[11.5px] flex justify-center text-sm text-white font-semibold bg-[#0355A5] rounded-lg w-full tracking-[0.2px]"
                >
                  {isSubmitting ? "Sending..." : "RETRIEVE LOGIN DETAILS"}
                </button>
              </Form>
              </>
            )}
          </Formik>
        </>
    </>
  );
};

export default ForgotPassword;
