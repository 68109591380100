import React, { useState } from "react";
import { useSelector } from "react-redux";
import { decodeHtmlEntities } from "../../../../sharedComponent/AllCategories/constants";
import down from "../../../../../assets/images/v1/deals/down.svg";
const FilterDescriptionDropdown = ({
  isOpenProps = false,
  heading,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenProps);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
      <button
        className="w-full py-[16px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
          {decodeHtmlEntities(heading)}
        </h1>
        <img
          data-icon="arrow"
          src={down}
          alt="Toggle Dropdown"
          className={`w-[24px] h-[24px] transition-transform duration-300 ${
            isOpen ? "" : "rotate-180"
          }`}
        />
      </button>
      {isOpen && (
        <p className="filter-dropdown-container my-[12px] text-neutral-600 text-[16px] leading-[24px] font-normal">
          {description}
        </p>
      )}
    </div>
  );
};

export default FilterDescriptionDropdown;
