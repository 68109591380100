// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flag-img {
  width: 28px;
  height: 17px;
  object-fit: cover; /* Optional: if you want to ensure the flag fills the area */
}
`, "",{"version":3,"sources":["webpack://./src/components/sharedComponent/Flag/ShowFlag.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB,EAAE,4DAA4D;AACjF","sourcesContent":[".flag-img {\r\n  width: 28px;\r\n  height: 17px;\r\n  object-fit: cover; /* Optional: if you want to ensure the flag fills the area */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
