import React from "react";
import lock from "../../../../../assets/images/v1/deals/lock.svg"
import checkicon from "../../../../../assets/images/v1/deals/checkicon.svg"
import { Link } from "react-router-dom";
const SubscribeSection = () => {
  const gradientStyle = {
    background: "linear-gradient(296deg, #222 0%, #0355A5 101.24%)",
  };

  return (
    <div
      className="w-full mt-[24px] mb-[40px] gradient-subscribe phone:p-[16px] py-[32px] px-[40px] rounded-[16px]"
      style={gradientStyle}
    >
      <h1 className="font-bold text-[24px] leading-[33.6px] text-white">
        Subscribe today to gain instant access
      </h1>

      <div className="w-full mt-[24px] mb-[32px] phone:mb-[24px] grid phone:grid-cols-1 phone:gap-[16px] grid-cols-4 tablet:grid-cols-2 gap-[24px]">
        {[
          "Discover trustworthy suppliers with low or no minimum orders.",
          "Access ‘industry secret’ suppliers that you won’t find on Google.",
          "Quickly filter through 150,000+ suppliers with specific search criteria.",
          "And 8+ more features for premium members.",
        ].map((text, index) => (
          <FeatureItem key={index} text={text} />
        ))}
      </div>

      <Link
        to="/subscribe"
        className="phone:w-full w-fit flex items-center justify-center gap-[8px] text-white px-[32px] py-[9.5px] rounded-[8px] bg-primary-main hover:bg-primary-hover font-semibold text-[14px] leading-[21px] transition-all duration-300"
      >
        <img
          src={lock}
          className="w-[20px] h-[20px]"
          alt="lock icon"
        />
        Subscribe Now
      </Link>
    </div>
  );
};

const FeatureItem = ({ text }) => (
  <div className="w-full flex items-start gap-[12px]">
    <img
      src={checkicon}
      className="w-[24px] h-[24px] min-w-[24px]"
      alt="check"
    />
    <p className="text-[14px] leading-[21px] text-neutral-300 font-normal">
      {text}
    </p>
  </div>
);

export default SubscribeSection;
