import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  autofocus: false,
};

const autofocusSlice = createSlice({
  name: "autofocus",
  initialState,
  reducers: {
    triggerAutofocus: (state) => {
      state.autofocus = !state.autofocus; // Toggle state to trigger re-render
    },
  },
});

export const { triggerAutofocus } = autofocusSlice.actions;
export default autofocusSlice.reducer;
