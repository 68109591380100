export const allPaymentMethods = [
  {
    type: "wholesale",
    logoSrc: "../assets/images/homepage/wholesale.svg",
    logoAlt: "Wholesale Deals",
    text: "Card Payments",
  },
  {
    type: "paypal",
    logoSrc: "../assets/images/logos/paypal.png",
    logoAlt: "PayPal",
    text: "",
  },
  {
    type: "bank",
    logoSrc: "../assets/images/homepage/wholesale.svg",
    logoAlt: "Bank Transfer",
    text: "Bank Transfer",
  },
];

/**
 * Determines available payment methods based on subscription type and duration.
 * @param {string} type - The subscription type ID ('C', 'D', 'S').
 * @param {number} duration - The subscription duration in months.
 * @returns {Array} - Array of available payment method objects.
 */
export const getAvailableMethods = (type, duration) => {
  let availableMethods = [];

  switch (type) {
    case "D":
    case "C":
      if (duration === 12) {
        availableMethods = ["wholesale", "paypal", "bank"];
      } else if (duration === 1 || duration === 6) {
        availableMethods = ["wholesale", "paypal"];
      }
      break;

    case "S":
      if (duration === 12 || duration === 6) {
        availableMethods = ["wholesale", "paypal", "bank"];
      } else if (duration === 1) {
        availableMethods = ["wholesale", "paypal"];
      }
      break;

    default:
      availableMethods = ["paypal"];
  }

  return allPaymentMethods.filter((method) =>
    availableMethods.includes(method.type)
  );
};

export const subscriptionTypeMapping = {
  D: {
    id: "deals",
    title: "1. Deals Database",
    description: [
      "12,000+ Live Wholesale & Dropship Deals Tick",
      "Sell on eBay & Amazon at a Profit",
      "200-300% Average Mark-ups",
      "Authentic Designer Brands Only",
      "Exclusive Member Discounts",
    ],
  },
  S: {
    id: "suppliers",
    title: "2. Suppliers Database",
    description: [
      "UK Verified Wholesalers (40,000+)",
      "UK Manufacturers, Importers, Distributors",
      "Largest UK Dropshippers Database",
      "EU Verified Wholesalers (35,000+)",
      "Largest EU Dropshippers Database",
      "US Verified Wholesalers (45,000+)",
      "Largest US Dropshippers Database",
      "Worldwide Wholesalers (25,000+)",
    ],
  },
  C: {
    id: "combo",
    title: "3. Combo Database",
    description: [
      "All Suppliers & Deals Database Benefits",
      "490,000+ Wholesale & Dropship Sources",
      "100% Uptime Guarantee",
      "Newsletter Updates - Be the First To Know",
      "Multiple Updates Daily",
      "Fast Premium Support",
    ],
  },
};

export const extractSubscriptionId = (selectedOption) => {
  if (!selectedOption) return null;
  const parts = selectedOption.split("-");
  return parts.length === 2 ? parts[1] : null;
};

export const detectCardBrand = (cardNumber) => {
  const cleaned = cardNumber.replace(/\D/g, "");

  const cardPatterns = {
    VISA: /^4/,
    MASTER: /^(5[1-5]|2[2-7])/,
    AMEX: /^3[47]/,
    DISCOVER: /^(6011|65|64[4-9]|622)/,
  };

  if (cardPatterns.VISA.test(cleaned)) {
    return "VISA";
  } else if (cardPatterns.MASTER.test(cleaned)) {
    return "MASTER";
  } else if (cardPatterns.AMEX.test(cleaned)) {
    return "AMEX";
  } else if (cardPatterns.DISCOVER.test(cleaned)) {
    return "DISCOVER";
  } else {
    return "";
  }
};

export const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

const flagImages = {};

const importAll = (requireContext) => {
  requireContext.keys().forEach((key) => {
    const alphaCodeMatch = key.match(/\.\/([a-z]{2})\.svg$/);
    if (alphaCodeMatch && alphaCodeMatch[1]) {
      const alphaCode = alphaCodeMatch[1];
      flagImages[alphaCode] = requireContext(key);
    } else {
      // console.warn(`Filename ${key} does not match the expected pattern.`);
    }
  });
};

try {
  importAll(require.context("../assets/flags/sm", false, /\.svg$/));
} catch (error) {
  console.error("Error importing flag images:", error);
}

// console.log('Final Flag Images Mapping:', flagImages);

export default flagImages;

export const truncateTitle = (title, maxWords) => {
  if (!title) return "";
  const words = title.split(" ");
  if (words.length <= maxWords) return title;
  return words.slice(0, maxWords).join(" ");
};


// page scroll helper
export const saveSectionToScroll = (sectionId) => {
  sessionStorage.setItem("scrollToSection", sectionId);
};

export const restoreScrollPosition = () => {
  const sectionId = sessionStorage.getItem("scrollToSection");
  if (sectionId) {
    const element = document.getElementById(sectionId);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }
};
