import React, { useState, useEffect } from "react";
import StarRating from "../StarRating/StarRating";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getMyFavoriteCategory } from "../../../../../store/slices/favoriteSlice";
import Cookies from "js-cookie";
import { onLikeSubmit } from "../../../../../store/api/likeDeals";
import { getDeals } from "../../../../../store/slices/dealsSlice";
import { getCommonDeals } from "../../../../../store/slices/commonDealsSlice";

const LikeForm = ({ id, closeModal, dispatchCommonDeals }) => {
  const [rating, setRating] = useState("");
  const [error, setError] = useState(false);
  const token = Cookies.get("wd-team");
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.favorite.data);
  console.log(categories)

  useEffect(() => {
    // Fetch categories when the component is mounted
    if (categories && categories.length === 0) {
      if (token) {
        dispatch(getMyFavoriteCategory({ token }));
      }
    }
  }, []);

  const validationSchema = Yup.object().shape({
    category: Yup.string().test(
      "category-or-newCategory",
      "Please select a category or add a new one.",
      function (value) {
        const { newCategory } = this.parent;
        return value || newCategory; // Passes if category is selected or new category is provided
      }
    ),
    newCategory: Yup.string().test(
      "newCategory-or-category",
      "Please select a category or add a new one.",
      function (value) {
        const { category } = this.parent;
        return value || category; // Passes if new category is provided or category is selected
      }
    ),
    note: Yup.string()
      .max(2000, "Note can't be more than 2000 characters")
      .required("Note is required"),
  });

  const handleSubmit = (values) => {
    // Ensure the rating is not less than 1
    if (rating < 1) {
      setError(true);
      return;
    }

    // Format the payload as required
    const payload = {
      dealId: id, // Example Deal ID
      addToFavCat: values.category ? values.category : "", // Existing category ID
      addToFavNewCat: values.newCategory ? values.newCategory : "", // New category name
      addToFavRating: rating.toString(), // Rating value
      addToFavNote: values.note, // Note added to the favorite item
      addToFav_CompanyName: "", // Item name (can be dynamic or added later)
      addToFav_DealURL: "https://google.com", // Example URL of the deal
    };

    onLikeSubmit(payload).then((res) => {
      console.log("res", res);
      closeModal();
      // dispatch(getCommonDeals({ token }));
      dispatchCommonDeals();
    });
  };

  return (
    <div className="p-6 bg-white rounded-lg w-full max-w-xlg">
      <Formik
        initialValues={{ category: "", note: "", newCategory: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Category Selection */}
              <div className="grid">
                <div className="space-y-2">
                  <label htmlFor="category" className="block font-bold">
                    Please select a category for this favourite:
                  </label>
                  <Field
                    as="select"
                    name="category"
                    className="block w-full p-2 border border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                  >
                    <option value="">
                      Select a category for this favourite...
                    </option>
                    {categories.map((value, index) => (
                      <option
                        key={value.fldFavouriteCategoryId}
                        value={value.fldFavouriteCategoryName}
                      >
                        {value.fldFavouriteCategoryName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="category"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="newCategory" className="block font-bold">
                    Or add to a new category:
                  </label>
                  <Field
                    type="text"
                    name="newCategory"
                    className="block w-full p-2 border border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                    placeholder="Enter new category name"
                  />
                  <ErrorMessage
                    name="newCategory"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              {/* Notes Field */}
              <div className="space-y-2">
                <label htmlFor="note" className="block font-bold">
                  Notes (max. 2000 characters):
                </label>
                <Field
                  as="textarea"
                  name="note"
                  maxLength="2000"
                  className="block w-full p-2 border border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                  placeholder="Enter your note here"
                  rows="4"
                />
                <div className="text-sm text-gray-600">
                  {2000 - values.note.length} characters remaining
                </div>
                <ErrorMessage
                  name="note"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
            </div>

            {/* Star Rating */}
            <div className="space-y-2">
              <label className="block font-bold">Rating:</label>
              <StarRating rating={rating} setRating={setRating} />
              {rating < 1 && error && (
                <div className="text-red-500 text-sm">
                  Rating must be at least 1
                </div>
              )}
            </div>

            {/* Submit Button */}
            <div className="pt-4">
              <button
                type="submit"
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md cap"
              >
                + CONFIRM ADDITIONAL
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LikeForm;
