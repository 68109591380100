import React from 'react'

const RelatedSearchs = () => {
  return (
    <div className="w-full bg-white">
    <div className="w-full max-w-[1440px] mx-auto py-[40px] phone:px-[24px] px-[56px]">
      <h3 className="font-bold text-[32px] leading-[40px] phone:text-[20px] phone:leading-[27px] text-[#000]">
        Related Searches
      </h3>
      <div className="w-full mt-[40px] phone:mt-[24px] phone:grid-cols-2 tablet:grid-cols-2 tablet:gap-x-[130px] tablet:gap-y-[48px] grid grid-cols-4 gap-[48px] phone:gap-x-[16px] phone:gap-y-[40px]">
        <div className="w-full grid gap-[24px] phone:gap-[8px]">
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Sports &amp; Fitness Supplies
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            High quality t shirt wholesale
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Baseball
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Gym &amp; Fitness
          </a>
        </div>
        <div className="w-full grid gap-[24px] phone:gap-[8px]">
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Football Shirts &amp; Shoes
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Karcher wholesale
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Badminton
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Tennis Table
          </a>
        </div>
        <div className="w-full grid gap-[24px] phone:gap-[8px]">
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Trainers &amp; Sportswear
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Mountain bikes wholesale
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Boxing
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Sports Accesories
          </a>
        </div>
        <div className="w-full grid gap-[24px] phone:gap-[8px]">
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Cycling
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            JBL wholesale distributors
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Golf
          </a>
          <a
            href="#"
            className="text-neutral-500 phone:text-[14px] phone:leading-[21px] font-medium text-[18px] leading-[27px]"
          >
            Running
          </a>
        </div>
      </div>
    </div>
  </div>
  )
}

export default RelatedSearchs