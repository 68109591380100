import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from "react-redux"
import store from "./store/store"
import { ToastContainer} from 'react-toastify';
// At your entry point (main.js / main.tsx / index.js)
import { showGlobalToast } from './components/sharedComponent/Toast/toasts'; // Add `.js` extension explicitly
window.showGlobalToast = showGlobalToast;
const isDevelopment = process.env.REACT_APP_NODE_ENV;
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    {isDevelopment=="development" ? (
      <React.StrictMode>
        <App />
        <ToastContainer />
      </React.StrictMode>
    ) : (
      <>
        <App />
        <ToastContainer />
      </>
    )}
  </Provider>
);
