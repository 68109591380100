import React from "react";
import {
  BuyerDropDown,
  CurrenciesDropDown,
  SellerDropdown,
} from "../../../pages/Login";
import { Link } from "react-router-dom";
const BuyerSellerNav = () => {
  return (
    <div>
      <div className="w-full bg-[#FBD78B] phone:hidden block h-[40px] relative flex items-center">
        <div className="w-full max-w-[1440px] mx-auto px-[56px] flex items-center justify-between">
          <div className="w-fit relative flex items-center gap-[24px]">
            <BuyerDropDown />
            <SellerDropdown />
            <button className="help px-[6px] py-[3px] rounded-[6px] transition-all duration-300 font-normal text-[14px] leading-[21px] text-neutral-800">
              Help
            </button>
          </div>
          <div style={{display:"flex",flexDirection:"row"}} >
            <Link to="/alphabetical-keyword-index">
              <button className="help px-[6px] py-[3px] rounded-[6px] transition-all duration-300 font-normal text-[14px] leading-[21px] text-neutral-800">
                A-Z Index
              </button>
            </Link>
            <CurrenciesDropDown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerSellerNav;
