
// @description The CountdownTimer component shows a live countdown to the flash sale’s end time, which updates based on the sale’s remaining duration.
// @author Nidhi 
// @ data 2024-11-11

import React, { useEffect, useState } from "react";

const CountdownTimer = ({
  initialDays,
  initialHours,
  initialMinutes,
  initialSeconds,
}) => {
  const [time, setTime] = useState({
    days: initialDays,
    hours: initialHours,
    minutes: initialMinutes,
    seconds: initialSeconds,
  });

  useEffect(() => {
    setTime({
      days: initialDays,
      hours: initialHours,
      minutes: initialMinutes,
      seconds: initialSeconds,
    });
  }, [initialDays, initialHours, initialMinutes, initialSeconds]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        let { days, hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          seconds -= 1;
        } else if (minutes > 0) {
          minutes -= 1;
          seconds = 59;
        } else if (hours > 0) {
          hours -= 1;
          minutes = 59;
          seconds = 59;
        } else if (days > 0) {
          days -= 1;
          hours = 23;
          minutes = 59;
          seconds = 59;
        } else {
          clearInterval(timer);
        }

        return { days, hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (value) => String(value).padStart(2, "0");

  const renderTimeBlock = (label, value, daysLength) => (
    <div
      className="flex flex-col items-center gap-1"
      data-countdown={label.toLowerCase()}
    >
      <div className="flex gap-[2px]">
        <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
          <p
            className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
            style={{
              boxShadow:
                "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
            }}
            data-time-block="0"
          >
            {formatTime(value)[0]}
          </p>
        </div>
        <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
          <p
            className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
            style={{
              boxShadow:
                "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
            }}
            data-time-block="1"
          >
            {formatTime(value)[1]}
          </p>
        </div>
        {daysLength === 3 ? (
          <div className="rounded-[2px] flex items-center justify-center text-center bg-white p-[1px] w-[20px] h-[22px]">
            <p
              className="rounded-[2px] w-full bg-white/0 text-[15px] leading-[20px] text-[#222]"
              style={{
                boxShadow:
                  "0px 0.367px 1.056px 0px rgba(0, 0, 0, 0.25), 0px 2.156px 5.39px 0px rgba(0, 0, 0, 0.25)",
              }}
              data-time-block="1"
            >
              {formatTime(value)[2]}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      <p className="text-[8px] leading-[14px] text-white">{label}</p>
    </div>
  );
  const daysLength = String(time.days).length;
  return (
    <div className="w-fit h-[40px] ml-[37px] tablet:ml-[30px]">
      <div className="flex gap-[10px]">
        {renderTimeBlock("DAYS", time.days, daysLength)}
        {renderTimeBlock("HOURS", time.hours)}
        {renderTimeBlock("MINUTES", time.minutes)}
        {renderTimeBlock("SECONDS", time.seconds)}
      </div>
    </div>
  );
};

export default CountdownTimer;
