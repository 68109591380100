import React from "react";
import leftUnionIcon from "../../../../../assets/images/v1/icons/union-left.svg";
import rightUnionIcon from "../../../../../assets/images/v1/icons/union-right.svg";
import tabletUnionIcon from "../../../../../assets/images/v1/deals/union.svg";

const EmailCta = () => {
  return (
    <div className="w-full bg-white">
      <div className="w-full max-w-[1440px] mx-auto phone:px-[24px] phone:py-[40px] tablet:py-[72px] tablet:px-[56px] py-[64px] px-[120px]">
        <div className="w-full relative overflow-hidden py-[48px] px-[64px] flex flex-col tablet:gap-[40px] gap-[32px] justify-center rounded-[24px] phone:py-[32px] phone:px-[20px] phone:gap-[32px] bg-[#FBD78B]">
          {/* Background Union Images */}
          <img
            src={leftUnionIcon}
            className="absolute tablet:hidden -bottom-[57px] -left-[48px] w-[185px] h-[179px]"
            alt="union"
          />
          <img
            src={rightUnionIcon}
            className="absolute -right-[10px] tablet:hidden -top-[28px] w-[224px] h-[216px]"
            alt="union"
          />
          <img
            src={tabletUnionIcon}
            className="absolute -right-[50px] hidden tablet:block top-[50px] w-[224px] h-[264px]"
            alt="union"
          />

          {/* Text Content */}
          <div className="text-center max-w-[470px] z-[30] mx-auto">
            <h1 className="font-bold text-[32px] leading-[40px] text-[#101828]">
              Never Miss A Wholesale Deal
            </h1>
            <p className="text-[18px] font-normal phone:mt-[12px] phone:text-[16px] phone:leading-[24px] tablet:mt-[12px] mt-[20px] leading-[27px] text-neutral-600">
              Get the hottest wholesale product offers directly to your inbox
              with our weekly newsletter
            </p>
          </div>

          {/* Form */}
          <form
            id="formNewsletter"
            className="w-[486px] phone:w-full tablet:w-full mx-auto flex phone:flex-col phone:items-start items-start gap-[16px]"
          >
            <div className="phone:w-full grid gap-[6px] phone:gap-[16px] tablet:gap-[16px]">
              <input
                type="email"
                name="email"
                className="py-[12px] phone:w-full tablet:w-full w-[360px] min-h-[48px] px-[14px] text-[16px] leading-[24px] rounded-[8px]"
                placeholder="Enter your email"
                id="email"
                required
              />
              <p className="text-[14px] leading-[21px] phone:hidden text-[#667085] font-normal">
                Get the hottest wholesale product offers directly to your inbox
                with our weekly newsletter
              </p>
            </div>
            <button
              className="newsletter-button phone:w-full py-[12px] z-[30] min-w-fit text-[16px] hover:bg-primary-hover transition-all duration-200 leading-[24px] font-medium text-white px-[20px] rounded-[8px] bg-[#E66927] capitalize"
              jf-ext-button-ct="alert me!"
            >
              Alert Me!
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailCta;
