// src/store/slices/resendVerificationEmailSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RESEND_VERIFY_EMAIL } from "../api";

// Async thunk for resending the verification email
export const resendVerifyEmail = createAsyncThunk(
  "resendVerification/resendVerifyEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(RESEND_VERIFY_EMAIL, payload);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(
          error.response.data.message || "Resend verification email failed"
        );
      }
      return rejectWithValue(error.message || "Network Error");
    }
  }
);

// Slice for resending verification email
const resendVerificationEmailSlice = createSlice({
  name: "resendVerification",
  initialState: {
    isLoading: false,
    error: null,
    message: "",
  },
  reducers: {
    clearResendState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendVerifyEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.message = "";
      })
      .addCase(resendVerifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(resendVerifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { clearResendState } = resendVerificationEmailSlice.actions;
export default resendVerificationEmailSlice.reducer;
