import React from "react";

// store
import { useSelector } from "react-redux";

// components
import ShowFlag from "../../../../../sharedComponent/Flag/ShowFlag";

const ProductInfo = ({
  country,
  countryIcon,
  minOrder,
  availableUnits,
  grade,
  ean,
  messageText,
  messageIcon,
  phoneCallText,
  phoneCallIcon,
}) => {
  const countryList = useSelector((state) => state.country.data);
  return (
    <div className="w-full rounded-[12px] px-[20px] phone:px-[16px] bg-[#F8FAFC] grid pb-[16px]">
      {/* Country Information */}
      {country && (
        <div className="w-full flex items-start py-[16px] justify-between border-b border-b-neutral-200">
          <p className="text-[16px] leading-[24px] font-semibold text-neutral-500">
            Country
          </p>
          <div className="flex items-center gap-[8px] w-fit">
            <ShowFlag countryList={countryList} countryName={country} />{" "}
            {country}
          </div>
        </div>
      )}

      {/* Minimum Orders */}
      <div className="w-full flex items-start py-[16px] justify-between border-b border-b-neutral-200">
        <p className="text-[16px] leading-[24px] font-semibold text-neutral-500">
          Minimum Orders
        </p>
        <p className="text-[16px] leading-[24px] font-semibold text-[#000]">
          {minOrder} Units
        </p>
      </div>

      {/* Available Units */}
      <div className="w-full flex items-start py-[16px] justify-between border-b border-b-neutral-200">
        <p className="text-[16px] leading-[24px] font-semibold text-neutral-500">
          Available Units
        </p>
        <p className="text-[16px] leading-[24px] font-semibold text-[#000]">
          {availableUnits} Units
        </p>
      </div>

      {/* Grade */}
      <div className="w-full flex items-start py-[16px] justify-between border-b border-b-neutral-200">
        <p className="text-[16px] leading-[24px] font-semibold text-neutral-500">
          Grade
        </p>
        <p className="text-[16px] leading-[24px] font-semibold text-[#000]">
          {grade}
        </p>
      </div>

      {/* EAN */}
      <div className="w-full flex items-start py-[16px] justify-between">
        <p className="text-[16px] leading-[24px] font-semibold text-neutral-500">
          EAN
        </p>
        <p className="text-[16px] leading-[24px] font-semibold text-[#000]">
          {ean}
        </p>
      </div>

      {/* Buttons */}
      <div className="w-full mt-[8px] grid phone:grid-cols-1 phone:gap-[8px] grid-cols-2 gap-[16px]">
        <button className="w-full flex items-center justify-center gap-[8px] text-white px-[32px] py-[9.5px] rounded-[8px] bg-primary-main hover:bg-primary-hover transition-all duration-200 font-semibold text-[14px] leading-[21px]">
          <img
            src={messageIcon}
            alt="Message Icon"
            className="w-[20px] h-[20px]"
          />
          {messageText}
        </button>
        <button className="w-full flex items-center justify-center gap-[8px] text-white px-[32px] py-[9.5px] rounded-[8px] bg-primary-main hover:bg-primary-hover transition-all duration-200 font-semibold text-[14px] leading-[21px]">
          <img
            src={phoneCallIcon}
            alt="Phone Icon"
            className="w-[20px] h-[20px]"
          />
          {phoneCallText}
        </button>
      </div>
    </div>
  );
};

export default ProductInfo;
