import React from "react";
import filesearch from '../../../../../assets/images/v1/deals/filesearch.svg'
const SearchResultNotice = () => {
  return (
    <div className="w-full my-[24px] py-[16px] px-[24px] phone:p-[16px] flex items-center gap-[16px] bg-neutral-200 rounded-[12px]">
      <img
        src={filesearch}
        alt="File Search Icon"
        className="w-[20px] h-[20px]"
      />
      <p className="font-semibold desktop:text-[16px] desktop:leading-[24px] tracking-[0.2px] text-[14px] leading-[21px] text-neutral-600">
        The following results are broad match results, and contain at least one
        of the keywords you have searched.
      </p>
    </div>
  );
};

export default SearchResultNotice;
