import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_COUNTRIES_DEALS_LIST } from "../api";

// Async thunk that accepts a dynamic categoryId parameter
export const getCountriesDeals = createAsyncThunk(
  "countriesDeals/getCountriesDeals",
  async (categoryId, thunkAPI) => {
    try {
      const url = categoryId
        ? `${GET_COUNTRIES_DEALS_LIST}?categoryId=${categoryId}`
        : GET_COUNTRIES_DEALS_LIST;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || error.message
      );
    }
  }
);

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const countriesDealsSlice = createSlice({
  name: "countriesDeals",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesDeals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCountriesDeals.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCountriesDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default countriesDealsSlice.reducer;
