import React from "react";
import copy from "../../../assets/images/v1/icons/copy.svg";
import wholesalefooter from "../../../assets/images/v1/icons/wholesale-footer.svg";
import building from "../../../assets/images/v1/icons/building.svg";
import { Link } from "react-router-dom";
import { saveSectionToScroll } from "../../../utils/paymentsMethods";

const RightsFooter = () => {
  const currentYear = new Date().getFullYear();

  const handleViewAllClick = () => {
    saveSectionToScroll("footer");
  };

  return (
    <div className="w-screen bg-[#1F1F1F] border-t-[1.2px] border-[#040404]">
      <div className="w-full max-w-[1440px] mx-auto phone:py-[32px] phone:px-[24px] phone:gap-[48px] tablet:py-[32px] tablet:px-[137px] px-[120px] py-[32px] flex phone:flex-col phone:items-start tablet:flex-col tablet:items-start tablet:gap-[48px] items-center justify-between">
        <div className="phone:w-full grid gap-[8px]">
          <div className="phone:w-full flex phone:items-start items-start gap-[8px] ">
            <img
              src={copy}
              className="w-[16px] h-[16px] mt-1"
              alt="copyright"
            />
            <p className="text-base text-white text-opacity-[0.6] font-normal">
              2004 - {currentYear} WholesaleDeals.co.uk, All Rights Reserved
            </p>
          </div>
          <div className="phone:w-full flex phone:items-start items-start gap-[8px] ">
            <img
              src={building}
              className="w-[13px] h-[13px] mt-1"
              alt="building"
            />
            <p className="text-[13px]  text-white text-opacity-[0.6] font-normal">
              Tradegenius SL, Calle Villalba Hervas 2, 4-2 Santa Cruz de
              Tenerife, 38002 - Spain
            </p>
          </div>
        </div>
        <div className="flex phone:flex-col phone:w-full items-center gap-[56px] phone:gap-[24px] tablet:justify-between tablet:w-full">
          <div className="w-fit phone:w-full phone:justify-between flex items-center gap-[40px]">
            <Link
              to="/terms-and-conditions"
              onClick={handleViewAllClick}
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Terms
            </Link>
            <Link
              to="/privacy-policy"
              onClick={handleViewAllClick}
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Privacy Policy
            </Link>
            <Link
              to="/contact-us#contact-form"
              onClick={handleViewAllClick}
              className="text-[16px] leading-[24px] text-white text-opacity-[0.6] font-normal"
            >
              Contact Us
            </Link>
          </div>
          <Link to="/" onClick={handleViewAllClick} className="no-underline">
            <img
              src={wholesalefooter}
              className="w-[190px] h-[32px]"
              alt="wholesale-footer"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RightsFooter;
