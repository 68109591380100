import React, { useEffect, useState } from "react";
import FilterDropdown from "./FilterDropdown";
import CountryFilterDropdown from "../CountryFilterDropdown";
import { useDispatch, useSelector } from "react-redux";
import GradeDropDown from "../GradeDropDown";
import FilterDescriptionDropdown from "./FilterDescriptionDropdown";
import {
  getIdBySlug,
  getSlugFromUrl,
} from "../../constant";
import { useLocation } from "react-router-dom";
import ParentCategoryDropdown from "../../../SupplierCategory/Components/Filter/ParentCategoryDropdown";

const Filter = ({
  main,
  updateUrl,
  setSelectedCountries,
  selectedCountries,
  setSelectedGradeOptions,
  selectedGradeOptions,
}) => {
  const countries = useSelector((state) => state.country.data);
  const categories = useSelector((state) => state.categories.data);
  const deals = useSelector((state) => state.common_deals.data);

  const location = useLocation();
  const dispatch = useDispatch();
  // changes for filters api call

  const GetCurrentUrl = () => {
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

    return fullUrl;
  };
  // Get the current page, search query, and search mode from query params
  const slug = getSlugFromUrl(GetCurrentUrl());
  const id = getIdBySlug(categories, slug);
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const initialSearchTerm = queryParams.get("search") || "";
  const initialSearchMode = queryParams.get("searchMode") || "";
  const initialSortBy = queryParams.get("sortBy") || "";
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchMode, setSearchMode] = useState(initialSearchMode);
  const [sortBy, setSortBy] = useState(initialSortBy);

  // const [selectedCountries, setSelectedCountries] = useState(initialCountry);
  const gradeOptions = [
    { label: "New", value: "N" },
    { label: "Used", value: "U" },
    { label: "Returns / Mixed Stock", value: "MR" },
    { label: "Liquidation Stocklots", value: "O" },
    { label: "Refurbished", value: "R" },
  ];

  useEffect(() => {
    // dispatch(mostPopularRecentDeals());
    // dispatch(getScrollerDeals());
    // dispatch(
    //   getCommonDeals({
    //     dealId: id,
    //     page: `${currentPage}`,
    //     search: searchTerm,
    //     searchMode,
    //     sortBy,
    //     country: selectedCountries,
    //     product_condition: selectedGradeOptions,
    //   })
    // );
    updateUrl(1, selectedCountries, searchTerm, searchMode, sortBy);
  }, [
    dispatch,
    selectedGradeOptions,
    selectedCountries,
    searchMode,
    sortBy,
    searchTerm,
    selectedGradeOptions,
    id,
    currentPage,
  ]);

  return (
    <div className="w-full filter-panel phone:hidden tablet:hidden grid rounded-[16px] bg-white">
      <div className="w-full border-b border-neutral-200 py-[16px] px-[20px]">
        <h1 className="font-bold text-[18px] leading-[27px] text-neutral-900">
          Filter
        </h1>
      </div>

      <FilterDropdown categories={categories} deals={deals} main={main} />
      <ParentCategoryDropdown categories={categories} deals={deals} main={main} issupplier={false} />
      <CountryFilterDropdown
        countries={countries}
        selectedCountries={selectedCountries || []}
        setSelectedCountries={setSelectedCountries}
      />
      {/* Grade */}
      <GradeDropDown
        title={"Grade"}
        options={gradeOptions}
        selectedGradeOptions={selectedGradeOptions}
        setSelectedGradeOptions={setSelectedGradeOptions}
      />
      {deals.categoryDescription && (
        <FilterDescriptionDropdown
          isOpenProps={true}
          heading={deals.categoryTitle}
          description={deals.categoryDescription}
        />
      )}
    </div>
  );
};

export default Filter;
