import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ValidateToken, ResetUserPassword } from "../../../store/slices/passwordSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { eyeClose,eyeOpen } from "../../../assets/images/v1/single";

import * as Yup from "yup";

const ResetPassword = ({redirectoLogin}) => {
  const dispatch = useDispatch();
  const { token } = useParams();
  
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is Required")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        "Password should be at least 8 characters, include at least one letter, one number, and one special character"
      ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {

        const {confirmPassword }=values
        const value = {
          newPassword:confirmPassword,
          token, // passing the token directly
        };
        const response = await dispatch(ResetUserPassword(value));
        if (response.type === "auth/resetpassword/fulfilled") {
          resetForm(); // Reset the form on success
          toast.success("Password reset successfully");
          redirectoLogin()
        } else if (response.type === "auth/resetpassword/rejected") {
          resetForm();
          if (response.error.message === "Request failed with status code 404") {
            toast.error("Failed to reset password. Please try again.");
            redirectoLogin()
          }
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <form
      id="resetPasswordForm"
      onSubmit={formik.handleSubmit}
      action="#"
      className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md"
    >
      {/* New Password Field */}
      <div className="flex flex-col gap-2 mb-6">
        <label htmlFor="newPassword" className="font-medium">New Password</label>
        <div className="flex justify-between items-center px-4 py-[11.5px] border border-neutral-200 rounded-lg">
          <input
            className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal border-none outline-none"
            type={showNewPassword ? "text" : "password"}
            name="newPassword"
            id="newPassword"
            placeholder="New Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
          />
          <span
            className="cursor-pointer ml-2 text-gray-600"
            onClick={toggleShowNewPassword}
          >
            {showNewPassword ? <img src={eyeClose} className="text-[#F24444]"/> :<img src={eyeOpen} className="text-[#F24444]"/>}
          </span>
        </div>
        {formik.touched.newPassword && formik.errors.newPassword ? (
          <div className="text-red-500 text-sm">{formik.errors.newPassword}</div>
        ) : null}
      </div>

      {/* Confirm Password Field */}
      <div className="flex flex-col gap-2 mb-6">
        <label htmlFor="confirmPassword" className="font-medium">Confirm Password</label>
        <div className="flex justify-between items-center px-4 py-[11.5px] border border-neutral-200 rounded-lg">
          <input
            className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal border-none outline-none"
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          <span
            className="cursor-pointer ml-2 text-gray-600"
            onClick={toggleShowConfirmPassword}
          >
            {showConfirmPassword ? <img src={eyeClose} className="text-[#F24444]"/> :<img src={eyeOpen} className="text-[#F24444]"/>}
          </span>
        </div>
        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <div className="text-red-500 text-sm">{formik.errors.confirmPassword}</div>
        ) : null}
      </div>

      <button
        type="submit"
        className="py-[11.5px] flex justify-center text-sm text-white font-semibold bg-[#F24444] rounded-lg w-full tracking-[0.2px]"
      >
        RESET PASSWORD
      </button>
    </form>
  );
};

export default ResetPassword;
