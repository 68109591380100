import Cookies from "js-cookie";
import { API_STARTING } from "../api";

export const onHideClick = async (payload) => {
  try {
    const url = `${API_STARTING}/user/hide-deal`;

    const token = Cookies.get("wd-team");

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error(`Failed to hide deal, status: ${response.status}`);
    }

  } catch (error) {
  }
};

export const onShowClick = async (payload) => {
  try {
    const url = `${API_STARTING}/user/show-deal`;

    const token = Cookies.get("wd-team");

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Failed to show deal, status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};
