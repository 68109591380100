// src/components/SellerDropdown.js
import React, { useState } from 'react';
import chevronDown from "../../../assets/images/v1/icons/chevron-down.svg"; // Update the path as needed
import chevronUp from "../../../assets/icons/chevron-up.png"; // Ensure you have this image for rotation

const SellerDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className="relative select-dropdown-container">
      <button
        className="forseller px-[6px] py-[3px] rounded-[6px] transition-all duration-300 flex items-center gap-[6px]"
        onClick={toggleDropdown}
      >
        <p className="font-normal text-[14px] leading-[21px] text-neutral-800">
          For sellers
        </p>
        <img
          src={isOpen ? chevronUp : chevronDown}
          className={`tablet:w-[16px] tablet:h-[16px] transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
          width="16px"
          height="16px"
          alt="Chevron"
        />
      </button>
      {isOpen && (
        <div className="w-[136px] flex flex-col absolute mt-[7px] left-0 p-[4px] bg-white forseller-modal select-dropdown">
          <button className="supplier-benefit transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left pl-2 hover:bg-[#FAF1DC]">
            Supplier Benefits
          </button>
          <button className="get-listed transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left pl-2 hover:bg-[#FAF1DC]">
            Get Listed
          </button>
        </div>
      )}
    </div>
  );
};

export default SellerDropdown;
