import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {FAVORITE_SUPPLIERS} from "../api";

export const getFavouriteSuppliers = createAsyncThunk(
    "/getFavouriteSuppliers",
    async ({ token }, { rejectWithValue }) => { // Destructure slug and token here
      try {
        const response = await axios.get(`${FAVORITE_SUPPLIERS}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching stats:", error);
        return rejectWithValue(
          error.response?.data || "Something went wrong while fetching stats."
        );
      }
    }
  );
  

    const initialState = {
        data:{},
        isLoading: false,
        error: null,
    }
    const favoriteSuppliersSlice = createSlice({
        name: "favorite_suppliers",
        initialState,
        reducers:{},
        extraReducers: (builder) => {
            builder
            .addCase(getFavouriteSuppliers.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getFavouriteSuppliers.fulfilled, (state, action) => {
                // Handle the case when the request is successful
                state.isLoading = false;
                state.data = action.payload.data; // Store the fetched data
              })
              .addCase(getFavouriteSuppliers.rejected, (state, action) => {
                // Handle the case when the request fails
                state.isLoading = false;
                state.error = action.payload || "Failed to load deals.";
              });
        }
    })
    export default favoriteSuppliersSlice.reducer;
