import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { VERIFY_WEBSITE } from "../api";
import logout from "../../_helperfunctions/Logout";
export const verifyData = createAsyncThunk(
  "/verifyData",
  async (data, { rejectWithValue }) => {
    const { supplierId } = data;
    try {
      const response = await axios.post(
        VERIFY_WEBSITE,
        { supplierId },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const verifyWebsiteSlice = createSlice({
  name: "verifyWebsite",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(verifyData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(verifyData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyData.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
        state.error = action.payload;
      });
  },
});
export default verifyWebsiteSlice.reducer;
