import React from "react";

const SimpleSpinner = ({ customClass }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      position: "relative",
      zIndex: 50,
    }}
  >
    <div
      className={`w-8 h-8 border-4 border-t-4 border-t-orange-500 border-orange-200 rounded-full animate-spin ${customClass}`}
    ></div>
  </div>
);

export default SimpleSpinner;
