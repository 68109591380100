
// customSourcingSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch custom sourcing details by id
export const fetchCustomSourcingReport = createAsyncThunk(
  'customSourcingReport/fetchCustomSourcingReport',
  async ({ token, id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://gazaniainfotech.ngrok.dev/api/user/get-coustomSourcing-details?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        return response.data.data; // <-- This should include countryNames if provided by API
      } else {
        return thunkAPI.rejectWithValue(response.data.message);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || error.message
      );
    }
  }
);


// Create the Redux slice
const customSourcingReportSlice = createSlice({
  name: 'customSourcingReport',
  initialState: {
    report: null, // Will store the API response data
    loading: false,
    error: null,
  },
  reducers: {
    // You can add additional synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomSourcingReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomSourcingReport.fulfilled, (state, action) => {
        state.loading = false;
        state.sourcingDetails = action.payload;
      })
      .addCase(fetchCustomSourcingReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      });
  },
});

export default customSourcingReportSlice.reducer;

