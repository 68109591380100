import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETSUPPLIERPROFILE, UPDATESUPPLIERPROFILE } from "../api";
import logout from "../../shared/logout";
/*
export const getSupplierProfile = createAsyncThunk(
  "/getsupplierbyid",
  async (data) => {
    const response = await axios.get(`${GETSUPPLIERPROFILE}/${data.userId}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
    return response.data;
  }
);
*/
export const getSupplierProfile = createAsyncThunk(
  "/getsupplierbyid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GETSUPPLIERPROFILE}/${data.userId}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      if (!response.data.success) {
        console.log("response.data.message", response.data.message);
        return rejectWithValue(
          response.data.message || "Supplier profile not found"
        );
      }

      return response.data;
    } catch (error) {
      console.log("error.message", error.response.data.message);
      if (error.response.data.message === "Forbidden: Invalid Token") {
        logout();
      }
      return rejectWithValue(
        error.message || "An error occurred while fetching the supplier profile"
      );
    }
  }
);

export const UpdateSupplierUser = createAsyncThunk(
  "/updatesupplierbyid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPDATESUPPLIERPROFILE, data.formData, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "multipart/form-data", // Make sure to set the correct content type
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        console.log("error.response", error.response);
        if (error.response.data.message === "Forbidden: Invalid Token") {
          logout();
        }
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierProfile.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getSupplierProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSupplierProfile.rejected, (state, action) => {
        state.isLoading = false;
        console.log(action.payload);
        // state.error = action.payload.error;
      })
      .addCase(UpdateSupplierUser.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(UpdateSupplierUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateSupplierUser.rejected, (state, action) => {
        state.isLoading = false;
        console.log(action.payload);
        console.log("action", action);
        // state.error = action.payload.error;
      });
  },
});
export default supplierSlice.reducer;
