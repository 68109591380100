import React from "react";

// components
import ProductDescription from "./ProductDescription";
import PaymentOptions from "./PaymentOption";
import DeliveryOptions from "./DeliveryOption";

// constants
import { deliveryOptions, paymentOptionsData } from "../constant";

const ProductDescriptionWrapper = ({ data }) => {
  return (
    <div className="w-full grid divide-y-[1px] divide-neutral-200 border-t border-neutral-200">
      <ProductDescription
        title="Product Description"
        description={data.fldDealDescription}
      />{" "}
      {/* // Inside your render method or functional component */}
      <PaymentOptions options={paymentOptionsData} />
      <DeliveryOptions options={deliveryOptions} />
    </div>
  );
};

export default ProductDescriptionWrapper;
