import { useState, useEffect } from "react";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);

    listener(); // Check initially
    media.addEventListener("change", listener); // Add event listener

    return () => media.removeEventListener("change", listener); // Cleanup
  }, [query]);

  return matches;
};
export default useMediaQuery;
