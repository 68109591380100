import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "./components/Breadcrumb/Breadcrumb";
import SearchFilterComponent from "./components/SearchFilterComponent/SearchFilterComponent";
import CardContainer from "./components/Card/CardContainer";
import Pagination from "./components/Pagination/Pagination";
import TopOffers from "./components/TopOffers/TopOffers";

import { getCommonDeals } from "../../../store/slices/commonDealsSlice";
import { getScrollerDeals } from "../../../store/slices/scrollDealsSlice";
import { mostPopularRecentDeals } from "../../../store/slices/wholesalesuppliersSlice";
import {
  GetCurrentUrl,
  getIdBySlug,
  getSlugFromUrl,
  joinStringArray,
} from "./constant";
import MobileTabletFilterComponent from "./components/MobileFilterComponent/MobileFilterComponent";
import Filter from "./components/Filter/Filter";
import SubscribeCard from "./components/SubscribeCard/SubscribeCard";
import NoDealsFound from "./components/NoDealsFound/NoDealsFound";
import { getToken } from "../../../hooks/cookies";
import { getFavoriteCategoryList } from "../../../store/slices/favoriteCategoriesSlice";
import SubscribeSection from "./components/SubscribeSection/SubscribeSection";
import { fetchAuthStatus } from "../../../store/slices/authSlice";
import { useUser } from "../../../hooks";

const Deals = ({ main }) => {
  const location = useLocation();
  const { getUserToken } = useUser();
  const [auth, setAuth] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const categories = useSelector((state) => state.categories.data);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const slug = getSlugFromUrl(GetCurrentUrl(location));
  const id = getIdBySlug(categories, slug);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.scroller_deals);
  const globalSearch = useSelector((state) => state.globalSearch);
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const token = getToken();
  const initialSearchMode = queryParams.get("searchMode") || "";
  const initialSortBy = queryParams.get("sortBy") || "";
  const initialSearchTerm = queryParams.get("search")
    ? queryParams.get("search").split(",")
    : [];
  const initialSearchValues = queryParams.get("search")
    ? queryParams.get("search").split(",")
    : [];
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || []);
  const [searchMode, setSearchMode] = useState(initialSearchMode);
  const accountdata = useSelector((item) => item.user);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [searchValue, setSearchValue] = useState(""); // Single input value
  const [searchValues, setSearchValues] = useState(initialSearchValues); // Array of search terms
  const searchInputRef = useRef(null);
  const { deals, isLoading, totalDeals } = useSelector(
    (state) => state.common_deals.data
  );
  const initialCountry = queryParams.get("country")
    ? queryParams.get("country").split(",").map(Number)
    : [];
  const initialGrade = queryParams.get("product_condition")
    ? queryParams.get("product_condition").split(",")
    : [];
  const [selectedGradeOptions, setSelectedGradeOptions] =
    useState(initialGrade);
  const [selectedCountries, setSelectedCountries] = useState(initialCountry);
  const itemsPerPage = 10;
  const favCategories = useSelector((state) => state.favorite_categories.data);
  // Update the query string in the URL when the page, search term, or search mode changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateUrl(page, selectedCountries, searchTerm, searchMode, sortBy);
  };

  useEffect(() => {
    if (token) {
      dispatch(getFavoriteCategoryList({ token }));
    }
  }, []);

  useEffect(() => {
    if (!accountdata.isLoading && accountdata.data) {
      const data = accountdata.data.user;
      if (data.fldPremium == "Y") {
        setIsPremium(true);
      } else {
        setIsPremium(false);
      }
    }
  }, [accountdata.data, accountdata.isLoading]);

  useEffect(() => {
    getItems();
  }, [isAuthenticated]);

  const getItems = async () => {
    const token = getUserToken();
    dispatch(fetchAuthStatus(token));
    if (isAuthenticated) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  };

  const handleSearch = (newSearchTerm, newSearchMode, newSortBy) => {
    setSearchTerm(newSearchTerm);
    setSearchMode(newSearchMode); // Update the search mode in state
    setSortBy(newSortBy);
    setCurrentPage(1); // Reset to page 1 when searching
    updateUrl(1, selectedCountries, newSearchTerm, newSearchMode, newSortBy);
  };

  const updateUrl = (page, country, search, mode, sort) => {
    const searchParams = new URLSearchParams();
    let newUrl;
    if (page !== 1 || page > 1) searchParams.set("page", page);
    if (search && typeof search === "string") {
      // If search is a string, just set it directly
      searchParams.set("search", search);
    } else if (Array.isArray(search) && search.length) {
      // If search is an array, join it
      searchParams.set("search", search.join(","));
    }
    if (mode) searchParams.set("searchMode", mode);
    if (sort) searchParams.set("sortBy", sort);

    // Convert the country array into a comma-separated string
    if (country && country.length) {
      searchParams.set("country", country.join(","));
    }

    if (searchParams.size > 0) {
      newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, "", newUrl);
    }
  };

  useEffect(() => {
    if (globalSearch.searchValues && globalSearch.searchValues.length) {
      setSearchValues(globalSearch.searchValues);
      handleSearch(globalSearch.searchValues, searchMode, sortBy);
    }
  }, [globalSearch]);

  useEffect(() => {
    if (main) {
      dispatch(
        getCommonDeals({
          dealId: "",
          page: `${currentPage}`,
          search: searchTerm,
          searchMode,
          sortBy,
          country: selectedCountries,
          product_condition: selectedGradeOptions,
          token,
        })
      );
    } else if (!main) {
      if (id) {
        dispatch(
          getCommonDeals({
            dealId: id,
            page: `${currentPage}`,
            search: searchTerm,
            searchMode,
            sortBy,
            country: selectedCountries,
            product_condition: selectedGradeOptions,
            token,
          })
        );
      }
    }

    dispatch(mostPopularRecentDeals());
    dispatch(getScrollerDeals());
  }, [
    currentPage,
    searchTerm,
    searchMode,
    sortBy,
    selectedGradeOptions,
    selectedCountries,
    id,
  ]);
  const resetFilters = () => {
    setSearchValue("");
    setSearchValues([]);
    setSearchTerm([]);
    setSearchMode("any");
    setSortBy("");
    handleSearch([], "any", ""); // Reset search with default values
  };
  const removeSearchAndSearchModeFromURL = () => {
    // Get the current URL search parameters
    // Get the current URL search parameters
    const queryParams = new URLSearchParams(location.search);

    // Remove 'search' and 'searchMode' from the parameters
    queryParams.delete("search");
    queryParams.delete("searchMode");

    // Create the new URL with the remaining query parameters
    const newSearchString = queryParams.toString();
    const newUrl = `${location.pathname}${
      newSearchString ? `?${newSearchString}` : ""
    }`;

    // Update the URL without reloading the page
    // navigate(newUrl, { replace: true });
  };
  return (
    <div className="w-full max-w-[1440px] mx-auto px-[56px] phone:px-[16px] flex items-start">
      <div className="w-[314px] min-w-[314px] py-[24px]  desktop:grid gap-[24px] phone:hidden tablet:hidden">
        <Filter
          main={main}
          updateUrl={updateUrl}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          selectedGradeOptions={selectedGradeOptions}
          setSelectedGradeOptions={setSelectedGradeOptions}
        />
        {auth && !isPremium && (
          <>
            <SubscribeCard classNames={"bg-primary-main"} />
            <SubscribeCard classNames={"bg-additional-blue"} />
          </>
        )}
        {!auth && (
          <>
            <SubscribeCard classNames={"bg-primary-main"} />
            <SubscribeCard classNames={"bg-additional-blue"} />
          </>
        )}
      </div>
      <div className="w-full desktop:pl-[48px] phone:pl-[10px] pt-[24px] phone:pt-[32px] pb-[72px] phone:pb-[32px] tablet:pb-[56px]">
        {/* Breadcrumb */}
        <Breadcrumb categories={categories} id={id} main={main} />
        <MobileTabletFilterComponent
          handlePageChange={handlePageChange}
          auth={auth}
          isPremium={isPremium}
        />
        {/* Breadcrumb end */}
        <SearchFilterComponent
          onSearch={handleSearch}
          setSearchTerm={setSearchTerm}
          searchInputRef={searchInputRef}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          searchMode={searchMode}
          setSearchMode={setSearchMode}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        {data && data.length ? <TopOffers searchTerm={searchTerm} /> : ""}
        <CardContainer
          deals={deals}
          searchTerm={searchTerm}
          isLoading={isLoading}
        />
        {deals && deals.length != 0 ? (
          totalDeals > 10 && (
            <Pagination
              totalItems={totalDeals}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              setCurrentPage={setCurrentPage}
            />
          )
        ) : searchTerm && searchTerm.length ? (
          <>
            <NoDealsFound
              searchInputRef={searchInputRef}
              setSearchTerm={setSearchTerm}
              keyword={joinStringArray(searchTerm)}
              removeSearchAndSearchModeFromURL={
                removeSearchAndSearchModeFromURL
              }
              resetFilters={resetFilters}
            />
            {auth && !isPremium && <SubscribeSection />}
            {!auth && <SubscribeSection />}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Deals;
