import React from "react";

// styles
import "./TagsSuperchargeSection.css";

const TagsSuperchargeWrapper = ({
  tags = [],
  superchargeTitle = "Ready to Supercharge Your Business?",
  superchargeFeatures = [],
  superchargeButtonText = "Try it Now!",
  superchargeButtonLink = "#",
  discount = "-35%",
  discountTitle = "For one-year REGISTRATION and RENEWAL",
  discountSubtitle = "Promotions cannot be combined",
}) => {
  return (
    <div className="w-full">
      {/* Tags Section */}
      <div className="w-full py-[40px] phone:py-[24px] grid gap-[20px] tablet:border-t-[1px] tablet:border-neutral-200">
        <h1 className="font-bold text-[24px] leading-[33.6px] phone:text-[20px] phone:leading-[27px] text-[#000]">
          Tags
        </h1>
        <div className="w-full flex phone:gap-[12px] gap-[16px] flex-wrap">
          {tags.map((tag, index) => (
            <button
              key={index}
              className="py-[8px] min-w-fit px-[16px] rounded-[8px] border border-neutral-300 text-[16px] leading-[24px] font-normal text-neutral-600"
            >
              {tag}
            </button>
          ))}
        </div>
      </div>

      {/* Supercharge Section */}
      <div className="w-full h-fit">
        <div className="w-full gradient-subscribe phone:p-[16px] p-[40px] rounded-t-[16px]">
          <h1 className="font-bold phone:text-[20px] phone:leading-[27px] text-[24px] leading-[33.6px] text-white">
            {superchargeTitle}
          </h1>
          <div className="w-full mt-[24px] mb-[32px] grid phone:grid-cols-1 phone:gap-[16px] grid-cols-4 tablet:grid-cols-2 gap-[40px]">
            {superchargeFeatures.map((feature, index) => (
              <div key={index} className="w-full flex items-start gap-[12px]">
                <img
                  src="../assets/images/v1/deals/checkicon.svg"
                  className="w-[24px] h-[24px] min-w-[24px]"
                  alt="check"
                />
                <p className="text-[14px] leading-[21px] text-neutral-300 font-medium">
                  {feature}
                </p>
              </div>
            ))}
          </div>
          <a
            href={superchargeButtonLink}
            className="phone:w-full w-fit hover:bg-primary-hover transition-all duration-200 flex items-center justify-center gap-[8px] text-white px-[32px] py-[9.5px] rounded-[8px] bg-primary-main font-semibold text-[14px] leading-[21px]"
          >
            <img
              src="../assets/images/v1/deals/lock.svg"
              className="w-[20px] h-[20px]"
              alt="lock"
            />
            {superchargeButtonText}
          </a>
        </div>

        {/* Discount Section */}
        <div className="w-full py-[16px] phone:p-[16px] px-[40px] rounded-b-[16px] flex phone:flex-col phone:gap-[8px] gap-[16px] bg-additional-yellow">
          <p className="phone:py-[7.5px] phone:px-[18.5px] py-[3.5px] px-[13.5px] bg-primary-main rounded-full phone:text-[20px] phone:leading-[27px] text-[24px] leading-[33.6px] font-bold text-white w-fit">
            {discount}
          </p>
          <div className="grid">
            <h3 className="text-[16px] leading-[24px] font-bold text-neutral-800">
              {discountTitle}
            </h3>
            <p className="text-neutral-800 font-semibold text-[12px] leading-[18px]">
              {discountSubtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagsSuperchargeWrapper;
