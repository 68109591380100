// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale-none {
  --tw-grayscale: none;
  filter: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/deals/single-deals/SingleDealsWrapper.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B;;yCAEuC;AACzC;AACA;EACE,oBAAoB;EACpB,YAAY;AACd","sourcesContent":[".grayscale {\r\n  --tw-grayscale: grayscale(100%);\r\n  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)\r\n    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)\r\n    var(--tw-sepia) var(--tw-drop-shadow);\r\n}\r\n.grayscale-none {\r\n  --tw-grayscale: none;\r\n  filter: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
