import { useEffect, FC, Fragment, useState } from "react";
import { useAuth, useRedirection } from "../hooks";

const AuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false);

  const { getUserInfo } = useAuth();
  const { redirectLogin } = useRedirection();

  useEffect(() => {
    checkCookie(); // Invoke the async function
  }, [component]); // Dependencies
  const checkCookie = async () => {
    try {
      // Fetch user info
      const userInfo = await getUserInfo();
      if (!userInfo?.userId) {
        redirectLogin();
        setStatus(false);
      } else {
        setStatus(true);
      }
    } catch (error) {
      redirectLogin(); // Handle errors by redirecting to login
    }
  };

  return status ? <Fragment>{component}</Fragment> : <></>; // Render the component when authentication is successful
};

export default AuthGuard;
