
// @description this slice is created for stats data
// @author Nidhi 
// @ data 2024-11-9

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_STATS } from "../api";

export const getStats = createAsyncThunk(
  "homeStats/getStats",
  async (data, { rejectWithValue }) => {
    try {
      // const response = await axios.get(GET_STATS);
      const response = await axios.get(`${GET_STATS}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data.data; // Extracting the nested data object directly
    } catch (error) {
      console.error("Error fetching stats:", error);
      return rejectWithValue(
        error.response?.data || "Something went wrong while fetching stats."
      );
    }
  }
);

const initialState = {
  data: {}, // Stats data will be stored here as an object
  isLoading: false,
  error: null,
};

const statsSlice = createSlice({
  name: "homeStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStats.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        console.log("Fetching stats...");
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        console.log("Stats fetched successfully:", action.payload);
      })
      .addCase(getStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to load stats.";
        console.error("Failed to fetch stats:", state.error);
      });
  },
});

export default statsSlice.reducer;
