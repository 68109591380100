import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_SUPPLIER_ORDER } from "../api";
import { getToken } from "../../hooks/cookies";

export const fetchSupplierOrderDetails = createAsyncThunk(
  "/fetchSupplierOrderDetails",
  async (orderId, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await axios.get(`${GET_SUPPLIER_ORDER}/${orderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      

      return response.data.data; // Adjusted to extract the nested "data"
    } catch (error) {
     
      return rejectWithValue(
        error.response?.data || "Failed to fetch supplier order details"
      );
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};

const supplierOrderDetailSlice = createSlice({
  name: "supplierOrderDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplierOrderDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSupplierOrderDetails.fulfilled, (state, action) => {
        state.isLoading = false;
       
        state.data = action.payload;
      })
      .addCase(fetchSupplierOrderDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default supplierOrderDetailSlice.reducer;
