import React from "react";

// store
import { useSelector } from "react-redux";

// images
import amazon from "../../../../../../assets/images/v1/single/payment/amazon.png";
import ebay from "../../../../../../assets/images/v1/single/payment/ebay.svg";

const DealImageComponent = ({ retailerID }) => {
  const retailer = useSelector((state) => state.common.data);

  // Get retailer data for the given ID
  const retailerData = retailer.find(
    (item) => item.fldRetailerId === retailerID
  );

  // Determine the alt text and image based on retailer
  const dealImageAlt = retailerData ? retailerData.fldRetailerName : "";
  const dealImage = retailerData
    ? retailerData.fldRetailerName.toLowerCase() === "amazon"
      ? amazon
      : retailerData.fldRetailerName.toLowerCase() === "ebay"
      ? ebay
      : null
    : null;

  return (
    <div>
      {dealImage ? (
        <img src={dealImage} alt={dealImageAlt} />
      ) : (
        <p>Image not available</p>
      )}
    </div>
  );
};

export default DealImageComponent;
