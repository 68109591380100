//for local
// const API_STARTING = "http://localhost:8000/api";
export const API_STARTING = process.env.REACT_APP_API_STARTING;
export const API_STARTING_FORIMAGE =
  process.env.REACT_APP_API_STARTING_FORIMAGE;
//for prod

//const API_STARTING="";

export const LOGIN = `${API_STARTING}/user/login`;
export const REGISTER = `${API_STARTING}/user/register`;
export const RESEND_VERIFY_EMAIL = `${API_STARTING}/user/resend-verify-email`;
export const GETUSERBYID = `${API_STARTING}/user/get-user-by-id`;
export const SENDEMAILTOUSER = `${API_STARTING}/user/forgot-password`;
export const VALIDATETOKEN = `${API_STARTING}/user/reset-password`;
export const RESETPASSWORD = `${API_STARTING}/user/reset-password`;
export const GETCOUNTRYLIST = `${API_STARTING}/common/get-countries`;
export const GET_COUNTRIES_SUPPLIER_LIST = `${API_STARTING}/common/get-countries-suppliers`;
export const GET_COUNTRIES_DEALS_LIST = `${API_STARTING}/common/get-countries-deals`;
export const GETLANGUAGELIST = `${API_STARTING}/common/get-languages`;
export const UPDATEUSERBYID = `${API_STARTING}/user/profile`;
export const UPDATEUSERNAME = `${API_STARTING}/user/change-username`;
export const UPDATEUSERPASSWORD = `${API_STARTING}/user/change-password`;
export const GETBUYERPROFILE = `${API_STARTING}/user/buyer-profile`;
export const UPDATEBUYERPROFILE = `${API_STARTING}/user/buyer-profile`;
export const GETALLCATEGORIES = `${API_STARTING}/common/get-categories`;
export const GETCURRENCIES = `${API_STARTING}/common/get-currencies`;
export const GETSUPPLIERPROFILE = `${API_STARTING}/user/supplier-profile`;
export const UPDATESUPPLIERPROFILE = `${API_STARTING}/user/supplier-profile`;
export const GETDEALS = `${API_STARTING}/user/deals`;
export const ELASTICSEARCH = `${API_STARTING}/user/brands/search`;
export const ADD_DEALS = `${API_STARTING}/user/add-deal`;
export const GETCOMMONDEALS = `${API_STARTING}/common/fetch-deals`;
export const GETRETAILERSLIST = `${API_STARTING}/common/get-retailers`;
export const MOST_POPULAR_RECENT_DEALS = `${API_STARTING}/common/most-popular-recent-deals`;
export const VALIDATEUSERTOKEN = `${API_STARTING}/user/validate-token`;
export const RECENTLY_ADDED_DEALS = `${API_STARTING}/common/recently-added-deals`;
export const SCROLLER_DEALS = `${API_STARTING}/common/scroller-deals`;
export const CHECK_USERNAME = `${API_STARTING}/user/check-username`;
export const GET_MANAGE_DEALSBYID = `${API_STARTING}/user/get-deal-by-id`;
export const UPDATE_DEALS = `${API_STARTING}/user/edit-deal`;
export const GET_TESTIMONIALS = `${API_STARTING}/common/testimonials`;
export const FAVORITE_CATEGORIES = `${API_STARTING}/user/get-favorite-categories`;
export const LATEST_SUPPLIERS = `${API_STARTING}/common/latest-suppliers`;
export const FAV_CATEGORIES = `${API_STARTING}/user/category-add-favourite`;
export const LIKE_CATEGORY = `${API_STARTING}/user/category-add-favourite`;
export const DISLIKE_CATEGORY = `${API_STARTING}/user/remove-category-favorite`;
export const GET_ALL_TESTIMONAILS = `${API_STARTING}/common/all-testimonials`;
export const REPORT_DATA = `${API_STARTING}/user/report-supplier`;
export const GET_SINGLE_DEALS = `${API_STARTING}/common/get-deal-detail`;
export const GET_STATS = `${API_STARTING}/common/get-stats`;
export const GET_FLASHSALE = `${API_STARTING}/common/get-flashsale`;
export const GETSUPPLIERS_CATEGORY = `${API_STARTING}/common/fetch-suppliers`;
export const FAVORITE_SUPPLIERS = `${API_STARTING}/user/get-favorite-suppliers`;
export const FAVORITE_DEALS = `${API_STARTING}/user/get-favorite-deals`;
export const HIDDEN_SUPPLIERS = `${API_STARTING}/user/get-hidden-suppliers`;
export const ADD_TO_CART = `${API_STARTING}/user/add-to-cart`;
export const FETCH_CART_DEALS = `${API_STARTING}/user/fetch-cart-deals`;
export const HIDDEN_DEALS = `${API_STARTING}/user/get-hidden-deals`;
export const GET_SUPPLIERS_DETAILS = `${API_STARTING}/common/get-suppliers-details`;
export const REMOVE_FROM_CART = `${API_STARTING}/user/remove-from-cart`;
export const GET_SUPPLIER_BEST_DEALS = `${API_STARTING}/common/get-suppliers-best-deals`;
export const GET_SUPPLIER_CATEGORIES_BEST_DEALS = `${API_STARTING}/common/get-best-deals-of-supplier-categories`;
export const PLACEORDER = `${API_STARTING}/user/placeOrder`;
export const ALPHABETIC_SEARCH = `${API_STARTING}/common/fetch-keywords`;
export const SUBCRIBE_NEWSLETTER = `${API_STARTING}/common/subcribe-newsletter`;
export const GET_BUYER_ORDER = `${API_STARTING}/user/buyerOrders`;
export const GET_SUPPLIER_ORDER = `${API_STARTING}/user/supplierOrders`;
export const CHANGE_ORDER_STATUS = `${API_STARTING}/user/change-order-status`;
export const GET_ALL_SUBSCRIBER_PLAN = `${API_STARTING}/common/fetch-subscriptions`;
export const SUBSCRIBER_PAYSTRAX = `${API_STARTING}/user/payby-paystrax`;
export const SUBSCRIBER_B2B = `${API_STARTING}/user/payby-b2b`;
export const SUBSCRIBER_PAYPAL = `${API_STARTING}/user/payby-pp`;
export const CONTACT_US = `${API_STARTING}/common/contact-us`;
export const COVERSATION_LIST = `${API_STARTING}/user/message-list`;
export const SEND_MESSAGE = `${API_STARTING}/user/send-message`;
export const GET_MESSAGE = `${API_STARTING}/user/get-messages`;
export const OTO_APPLICABLE = `${API_STARTING}/user/is-oto-applicable`;
export const OTO_PURCHASE = `${API_STARTING}/user/oto-purchase`;
export const SAVE_COUSTOMSOURCING = `${API_STARTING}/user/save-coustomSourcing`;
export const GET_COUSTOMSOURCING = `${API_STARTING}/user/get-coustomSourcing`;
export const PURCHASE_HISTORY = `${API_STARTING}/user/purchase-history`;
export const GET_PACKAGE = `${API_STARTING}/user/get-user-package`;
export const AFFILIATE_DASHBOARD = `${API_STARTING}/user/affiliate-dashboard`;
export const AFFILIATE_REFERRAL = `${API_STARTING}/common/handle-refferral`;
export const GET_SUPPLIER_DATA = `${API_STARTING}/user/get-latest-supplier`;
export const RELATED_TOP_DEALS = `${API_STARTING}/common/related-top-deal`;
export const MOST_POPULAR_DEALS = `${API_STARTING}/common/most-popular-deals`;
export const GET_FAVORITE_CAT = `${API_STARTING}/user/get-favorite-cat`;
export const VERIFY_WEBSITE = `${API_STARTING}/user/visit-supplier-website`;
export const VERIFY_EMAIL = `${API_STARTING}/user/verify-email`;
export const GET_COUSTOMSOURCING_DETAILS = `${API_STARTING}/user/get-coustomSourcing-details`;
export const CANCEL_PAYSTRAX_SUBSCRIPTION = `${API_STARTING}/user/cancel-paystrax-subscription`;
export const FETCH_SUPPLIER_DEALS = `${API_STARTING}/common/fetch-supplier-deals?supplierProfileID=`;
export const MARK_READ = `${API_STARTING}/user/mark-read`;
export const GET_ORDERS_MESSAGES = `${API_STARTING}/user/order-messages`;
