import { useEffect, useState } from "react";
import down from "../../../../../assets/images/v1/deals/down.svg";
import { decodeHtmlEntities } from "../../../../sharedComponent/AllCategories/constants";
import { useNavigate } from "react-router-dom";
import { findCategoryByName } from "../../constant";
import { routes } from "../../../../../routes/route";

const FilterDropdown = ({ categories, deals, main }) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const onCategoryClick = (categorySlug) => {
    navigate(`/${routes.dealsSlug}/${categorySlug}`);
  };
  const [categoryFilterData, setCategoryFilterData] = useState(
    findCategoryByName(categories, deals.categoryTitle)
  );
  useEffect(() => {
    setCategoryFilterData(
      findCategoryByName(categories, deals.categoryTitle, main)
    );
  }, [categories, deals.categoryTitle]);
  return categoryFilterData &&
    categoryFilterData.children &&
    categoryFilterData.children.length > 0 ? (
    <div className="filter-dropdown w-full py-[8px] px-[20px]">
      <button
        id="shoes-filter"
        className="w-full py-[16px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
          {categoryFilterData.name
            ? decodeHtmlEntities(categoryFilterData.name)
            : "No Name Available"}
        </h1>
        <img
          data-icon="arrow"
          src={down}
          alt="Toggle Dropdown"
          className={`w-[24px] h-[24px] transition-transform duration-300 ${
            isOpen ? "" : "rotate-180"
          }`}
        />
      </button>

      {isOpen && (
        <div className="w-full filter-dropdown-container mt-[8px]">
          {categoryFilterData.children.map((subCategory) => (
            <button
              key={subCategory.id}
              onClick={() => onCategoryClick(subCategory.slug)}
              className={`w-full text-left text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px] hover:text-primary-main ${
                categoryFilterData.childrenName == subCategory.name
                  ? "text-primary-main"
                  : ""
              }`}
            >
              {subCategory.name
                ? decodeHtmlEntities(subCategory.name)
                : "Unnamed Subcategory"}
            </button>
          ))}
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

export default FilterDropdown;
