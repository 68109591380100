import { AuthGuard } from "../guards";
import { Route } from "react-router-dom";
import React from "react";
import { routes, routesforAuth } from "./route";
import DealWrapper from "../components/pages/deals/DealWrapper";
import DealMain from "../components/pages/deals/DealMain";
import SingleDeals from "../components/pages/deals/single-deals/SingleDealsWrapper";
import SingleDealsWrapper from "../components/pages/deals/single-deals/SingleDealsWrapper";

const BuyerProfile = React.lazy(() =>
  import("../components/pages/user/Buyer/Profile/BuyerProfileWrapper")
);
const ManageDeals = React.lazy(() =>
  import("../components/pages/user/Common/Deals/ManageDeals/ManageDealsWrapper")
);
const SupplierDashboard = React.lazy(() =>
  import("../components/pages/user/Common/DashBoard/SupplierDashboard")
);
const SupplierProfile = React.lazy(() =>
  import("../components/pages/user/Seller/Profile/SupplierProfileWrapper")
);
const AccountProfile = React.lazy(() =>
  import("../components/pages/user/Common/account-profile/AccountProfile")
);
const AffiliateEarnings = React.lazy(() =>
  import("../components/pages/user/Common/AffiliateEarnings/AffiliateEarnings")
);
const ManageBills = React.lazy(() =>
  import("../components/pages/user/Common/ManageBills/ManageBills")
);
const Messages = React.lazy(() =>
  import("../components/pages/user/Common/Messages/Messages")
);
const ManageServices = React.lazy(() =>
  import("../components/pages/user/Common/ManageServices/ManageServices")
);
const Orders = React.lazy(() =>
  import("../components/pages/user/Common/Orders/OrderList/OrdersWrapper")
);
const OrderMessages = React.lazy(() =>
  import(
    "../components/pages/user/Common/Orders/OrderMessages/OrderMessagesWrapper"
  )
);
const OrderDetails = React.lazy(() =>
  import(
    "../components/pages/user/Common/Orders/OrderDetails/OrderDetailsWrapper"
  )
);
const AddNewDeals = React.lazy(() =>
  import("../components/pages/user/Common/Deals/AddDeals/AddNewDealsWrapper")
);
const Homepage = React.lazy(() =>
  import("../components/pages/Homepage/HomepageWrapper")
);
const SingleDealsPremium = React.lazy(() =>
  import(
    "../components/pages/user/Common/Single-Deals-Premium/SingleDealsPremiumWrapper"
  )
);
const SingleDealsNotPremiumSold = React.lazy(() =>
  import(
    "../components/pages/user/Common/Single-Deals-Not-Premium-Sold/SingleDealsNotPremiumSoldWrapper"
  )
);
const EditDeals = React.lazy(() =>
  import("../components/pages/user/Common/Deals/EditDeals/EditDealsWrapper")
);

const MyFavourites = React.lazy(() =>
  import("../components/pages/user/Common/MyFavourites/MyFavouritesWrapper")
);

const SingleSupplierWrapper = React.lazy(() =>
  import("../components/pages/Homepage/single-supplier/SingleSupplierWrapper")
);

const CommonAuthRoutes = [
  <Route
    key="/usersupplier-dashboard"
    path="/user/supplier-dashboard"
    element={<AuthGuard component={<SupplierDashboard />} />}
  />,
  <Route
    key="slash"
    path="/"
    element={<AuthGuard component={<Homepage />} />}
  />,
  <Route
    key="/useraccount-profile"
    path="/user/account-profile"
    element={<AuthGuard component={<AccountProfile />} />}
  />,
  <Route
    key="/userbuyer-profile"
    path="/user/buyer-profile"
    element={<AuthGuard component={<BuyerProfile />} />}
  />,
  <Route
    key="/usersupplier-profile"
    path="/user/supplier-profile"
    element={<AuthGuard component={<SupplierProfile />} />}
  />,
  <Route
    key="/usermanage-deals"
    path="/user/manage-deals"
    element={<AuthGuard component={<ManageDeals />} />}
  />,

  <Route
    key="affiliate-earnings"
    path="/affiliate-earnings"
    element={<AuthGuard component={<AffiliateEarnings />} />}
  />,

  <Route
    key="/usermanage-bills"
    path="/user/manage-bills"
    element={<AuthGuard component={<ManageBills />} />}
  />,

  <Route
    key="/usermanage-services"
    path="/user/manage-services"
    element={<AuthGuard component={<ManageServices />} />}
  />,
  <Route
    key="/usermessages"
    path="/user/messages"
    element={<AuthGuard component={<Messages />} />}
  />,
  <Route
    key="/userorders"
    path="/user/orders"
    element={<AuthGuard component={<Orders />} />}
  />,
  <Route
    key="/userorders-messages/:id"
    path="/user/orders-messages/:id"
    element={<AuthGuard component={<OrderMessages />} />}
  />,
  <Route
    key="/user/orders-details/:id"
    path="/user/orders-details/:id"
    element={<AuthGuard component={<OrderDetails />} />}
  />,
  <Route
    key="/user/add-deals"
    path="/user/add-deals"
    element={<AuthGuard component={<AddNewDeals />} />}
  />,
  <Route
    key="/user/single-deals-premium/:id"
    path="/user/single-deals-premium/:id"
    element={<AuthGuard component={<SingleDealsPremium />} />}
  />,
  <Route
    key="/user/single-deals-not-premium-sold/:id"
    path="/user/single-deals-not-premium-sold/:id"
    element={<AuthGuard component={<SingleDealsNotPremiumSold />} />}
  />,
  <Route
    key={`${routes.dealsSlug}`}
    path={`/${routes.dealsSlug}/:slug`}
    element={<AuthGuard component={<DealWrapper />} />}
  />,
  <Route
    key={`${routes.deals}`}
    path={`/${routes.deals}`}
    element={<AuthGuard component={<DealMain />} />}
  />,
  <Route
    key="/user/edit-deals/:id"
    path="/user/edit-deals/:id"
    element={<AuthGuard component={<EditDeals />} />}
  />,
  <Route
    key={`${routes.single_deals}`}
    path={`/${routes.single_deals}`}
    element={<AuthGuard component={<SingleDealsWrapper />} />}
  />,

  <Route
    key={`${routesforAuth[0].myFavourites}`}
    path={`${routesforAuth[0].myFavourites}`}
    element={<AuthGuard component={<MyFavourites />} />}
  />,
  <Route
    key={`${routes.single_supplier}`}
    path={`${routes.single_supplier}`}
    element={<SingleSupplierWrapper />}
  />,
];

export default CommonAuthRoutes;
