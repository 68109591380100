import React from "react";
import RightsFooter from "./RightsFooter";
import DetailFooter from "./DetailFooter";

const Footer = () => {
  return (
    <footer className="w-full bg-[#222] test">
      <DetailFooter />
      <RightsFooter />
    </footer>
  );
};

export default Footer;
