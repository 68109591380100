import { useNavigate } from "react-router-dom";
import { routes } from "../routes/route";

const useRedirection = () => {
  const navigate = useNavigate();

  const redirectAdminDashboard = () => {
    navigate("/user/admin-dashboard");
  };
  const redirectUserDashboard = () => {
    navigate("/user/supplier-dashboard");
  };
  const redirectLogin = () => {
    navigate("/");
  };
  const redirectToLoginPage = () => {
    navigate("/userlogin");
  };
  const redirectPasswordReset = (token) => {
    if (token) {
      navigate(`/reset-password/${token}`);
    }
  };
  const redirectAccountProfile = () => {
    navigate("/user/account-profile");
  };
  const redirectToAddDeals = () => {
    navigate("/user/add-deals");
  };
  const redirectToDeals = () => {
    navigate(`/${routes.deals}`);
  };
  const redirectoSearchDeals = (data) => {
    navigate(`/${routes.dealsSlug}/${data}`);
  };
  const redirectoDealSearch = (data) => {
    navigate(`/${routes.deals}?search=${data}`);
  };
  const redirectoSupplierSearch = (data) => {
    navigate(`/${routes.deals}?search=${data}`);
  };
  const redirectoEditDeals = (id) => {
    navigate(`/user/edit-deals/${id}`);
  };
  const redirectToSupplierProfile = () => {
    navigate("/user/supplier-profile");
  };
  const redirectToSubscribe = () => {
    navigate("/subscribe");
  };
  const redirectToTour = () => {
    navigate("/tour-step-1");
  };
  const redirectoManageDeals = () => {
    navigate("/user/manage-deals");
  };
  // const redirectToDealsBasketEmpty = () => {
  //   navigate("/my-deals-basket-empty");
  // };
  // const redirectToDealsBasketNoLogin = () => {
  //   navigate("/my-deals-basket-no-empty");
  // };
  // const redirectToDealsBasketFilled = () => {
  //   navigate("/my-deals-basket-filled");
  // };
  const redirectTotestimonials = () => {
    navigate("/all-testimonials");
  };
  const redirectToSingleDeal = (data) => {
    navigate(`${routes.single_deals_root}${data}`);
  };
  const redirectToCart = () => {
    navigate("/user/my-deals-basket");
  };
  const redirectToFav = () => {
    navigate("/user/my-favourites");
  };
  const redirectToSingleSupplier = (data) => {
    navigate(`${routes.single_supplier_root}${data}`);
  };

  const redirectToHome = () => {
    navigate("/");
  };
  const redirectToAllSuppliers=()=>{
    navigate("/supplier-category")
  }
  const redirectToAlphabeticSearch=()=>{
    navigate("/alphabetical-keyword-index")
  }

  return {
    redirectAdminDashboard,
    redirectUserDashboard,
    redirectLogin,
    redirectPasswordReset,
    redirectAccountProfile,
    redirectToAddDeals,
    redirectToLoginPage,
    redirectToDeals,
    redirectoSearchDeals,
    redirectoEditDeals,
    redirectToSupplierProfile,
    redirectToSubscribe,
    redirectToTour,
    redirectoManageDeals,
    redirectToSingleDeal,
    redirectToSingleSupplier,
    // redirectToDealsBasketEmpty,
    // redirectToDealsBasketNoLogin,
    // redirectToDealsBasketFilled,
    redirectoDealSearch,
    redirectToCart,
    redirectTotestimonials,
    redirectToHome,
    redirectToFav,
    redirectToAllSuppliers,
    redirectToAlphabeticSearch
  };
};
export default useRedirection;
