import { useEffect, useState } from "react";
import AllCategories from "../../AllCategories/index";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { routes } from "../../../../routes/route";
import menu from "../../../../assets/images/v1/icons/menu.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  selectBusinessType,
  setSelectedGradeOption,
} from "../../../../store/slices/businessTypeSlice";
const NavComponent = () => {
  const dispatch = useDispatch();
  const businessType = useSelector(selectBusinessType);
  const location = useLocation();
  const tabs = [
    { label: "Deals", route: "/deals" },
    { label: "Wholesale Suppliers", route: "/supplier-category" }, // Updated route for All Suppliers
    { label: "Liquidators", route: "/supplier-category" },
    { label: "Dropshippers", route: "/supplier-category" },
  ];

  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    if (tab.label === "Wholesale Suppliers") {
      dispatch(setSelectedGradeOption(["fldSupplierTypeWholeSaleSuppliers"]));
    } else if (tab.label === "Liquidators") {
      dispatch(setSelectedGradeOption(["fldSupplierTypeLiquidator"]));
    } else if (tab.label === "Dropshippers") {
      dispatch(setSelectedGradeOption(["dropshipper"]));
    } else if (tab.label === "Deals") {
      dispatch(setSelectedGradeOption(["deals"]));
    }

    setActiveTab(tab.label);
    navigate(tab.route);
  };

  useEffect(() => {
    const path = location.pathname;
  
    if (
      path === `/${routes.deals}` ||
      path.includes(routes.dealsSlug) ||
      path.includes("deal")
    ) {
      dispatch(setSelectedGradeOption(["deals"]));
      setActiveTab("Deals");
    } else if (path.startsWith(`/${routes.supplier_category_slug}`)) {
      // This condition handles all routes that start with /supplier-category
      // Including /supplier-category/{categorySlug}
      setActiveTab("Wholesale Suppliers");
    } else if (businessType === "dropshipper" && path !== "/") {
      setActiveTab("Dropshippers");
    } else if (businessType === "fldSupplierTypeLiquidator" && path !== "/") {
      setActiveTab("Liquidators");
    } else {
      setActiveTab("");
    }
  }, [location.pathname, businessType, dispatch]);

  return (
    <nav className="w-full bg-white border-y border-neutral-200 test-AllCategoriesNav">
      <div className="relative w-full hide-scrollbar max-w-[1440px] mx-auto phone:overflow-x-scroll phone:px-[24px] px-[56px] flex items-center">
        <div className="relative phone:hidden tablet:hidden">
          <AllCategories />
          {/* Side Navigation Desktop */}
        </div>
        <div className="flex items-center w-[800px]">
          <button
            id="toggle-button-list-category-tablet"
            className="hidden tablet:block"
          >
            <img src={menu} alt="menu" />
          </button>
          {tabs.map((tab) => (
            <Link
              key={tab.label}
              className={`py-[12px] min-w-fit px-[24px] text-[14px] leading-[21px] font-normal border-b-4 ${
                activeTab === tab.label
                  ? "text-primary-main border-primary-main"
                  : "text-neutral-500 border-b-transparent"
              } hover:border-primary-main`}
              onClick={() => handleTabClick(tab)}
              to={tab.route}
            >
              {tab.label}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default NavComponent;
