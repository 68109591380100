import { useEffect } from "react";
import Cookies from "js-cookie";

const AnalyticsIframe = () => {
  useEffect(() => {
    // 1. Check if analytics cookies are accepted
    const analyticsConsent =
      Cookies.get("analytics_cookies") === "true" ||
      Cookies.get("analytics_cookies") === true;

    if (analyticsConsent) {
      // 2. Make sure we haven't already inserted the iframe
      if (!document.getElementById("gtm-iframe")) {
        // 3. Create the iframe
        const iframeEl = document.createElement("iframe");
        iframeEl.id = "gtm-iframe";
        iframeEl.src = "https://www.googletagmanager.com/ns.html?id=GTM-5SQ2W4";
        iframeEl.height = 0;
        iframeEl.width = 0;
        iframeEl.style.display = "none";
        iframeEl.style.visibility = "hidden";

        // 4. Insert the iframe into the <head> before the first <meta> tag
        const firstMeta = document.head.querySelector("meta");
        if (firstMeta) {
          document.head.insertBefore(iframeEl, firstMeta);
        } else {
          document.head.appendChild(iframeEl);
        }
      }
    }
  }, []);

  return null; 
};

export default AnalyticsIframe;
