// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import userSlice from "./slices/userSlice";
import passwordSlice from "./slices/passwordSlice";
import countryDropdownSlice from "./slices/countryDropdownSlice";
import languageDropdownSlice from "./slices/languageDropdownSlice";
import buyerSlice from "./slices/buyerSlice";
import categoriesDropdownSlice from "./slices/categoriesDropdownSlice";
import currenciesDropdownSlice from "./slices/currenciesDropdownSlice";
import supplierSlice from "./slices/supplierSlice";
import dealsReducer from "./slices/dealsSlice";
import elasticSearchReducer from "./slices/elasticSearchSlice";
import commonDropDownReducer from "./slices/commonDropDownSlice";
import addDealsReducer from "./slices/addDealsSlice";
import commonDealsReducer from "./slices/commonDealsSlice";
import wholesalesuppliersReducer from "./slices/wholesalesuppliersSlice";
import scrollerDealsSlice from "./slices/scrollDealsSlice";
import editDealsSlice from "./slices/editDealsSlice";
import favoriteSlice from "./slices/favoriteSlice";
import commonhomepageSlice from "./slices/commonhomepageSlice";
import latestSuppliersSlice from "./slices/latestSuppliersSlice";
import favoriteCategoriesSlice from "./slices/favoriteCategoriesSlice";
import alltestimonialsSlice from "./slices/alltestimonialsSlice";
import reportsSlice from "./slices/reportsSlice";
import singleDealSlice from "./slices/singleDealSlice";
import todoSlice from "./slices/todosSlice";
import currencySlice from "./slices/currencySlice";
import statsSlice from "./slices/statsSlice";
import flashsaleSlice from "./slices/flashsaleSlice";
import favoriteSuppliersSlice from './slices/favoriteSuppliersSlice';
import supplierCategorySlice from "./slices/supplierCategorySlice";
import favoriteDealsSlice from './slices/favoriteDealsSlice';
const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    password: passwordSlice,
    country: countryDropdownSlice,
    language: languageDropdownSlice,
    buyer: buyerSlice,
    categories: categoriesDropdownSlice,
    currencies: currenciesDropdownSlice,
    supplier: supplierSlice,
    deals: dealsReducer,
    elastic: elasticSearchReducer,
    common: commonDropDownReducer,
    addeals: addDealsReducer,
    common_deals: commonDealsReducer,
    scroller_deals: scrollerDealsSlice,
    wholesalesuppliers: wholesalesuppliersReducer,
    editdeals: editDealsSlice,
    favorite: favoriteSlice,
    favorite_categories: favoriteCategoriesSlice,
    editdeals: editDealsSlice,
    commonhomepage: commonhomepageSlice,
    latestsuppliers: latestSuppliersSlice,
    alltestimonials: alltestimonialsSlice,
    reports: reportsSlice,
    single_deal: singleDealSlice,
    todos: todoSlice, 
    currency: currencySlice,
    home_stats: statsSlice,
    home_flashsale: flashsaleSlice,
    favoriteSuppliers: favoriteSuppliersSlice,
    supplierCategory:supplierCategorySlice,
    favoriteDeals: favoriteDealsSlice,
  },
});

export default store;
