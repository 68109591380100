
import React, { useState } from "react";
import ManageCookiePopup from "./index";
import ManageCookieModel from "./ManageCookieModel";
import { hasCookieConsent } from "../../../hooks/cookies"; // adjust path as needed

const CookieConsentManager = () => {
  // If the cookie_consent flag is already set, do not show the popup
  const [showCookiePopup, setShowCookiePopup] = useState(!hasCookieConsent());
  const [showCookieModel, setShowCookieModel] = useState(false);

  const handleOpenModal = () => setShowCookieModel(true);
  const handleCloseModal = () => setShowCookieModel(false);
  const handleClosePopup = () => setShowCookiePopup(false);

  return (
    <>
      {showCookiePopup && (
        <ManageCookiePopup
          onManageClick={handleOpenModal}
          onClose={handleClosePopup}
        />
      )}
      {showCookieModel && <ManageCookieModel onClose={handleCloseModal} />}
    </>
  );
};

export default CookieConsentManager;
