import Cookies from "js-cookie";

// Function to create a cookie
export const createCookie = (jwt, remember_me) => {
  if ((typeof(remember_me)=='boolean' && remember_me) || remember_me=="true") {
    const expireTime = new Date();
    expireTime.setTime(expireTime.getTime() + 24 * 60 * 60 * 1000); // 24 hours from now
    Cookies.set("remember_me", true, {
      expires: expireTime,
    });
    Cookies.set("wd-team", jwt, {
      expires: expireTime,
    });
  } else{
    Cookies.set("remember_me", false, {
      expires: 59 / 1440
    });
    Cookies.set("wd-team", jwt, {
      expires: 59 / 1440
    });


  }
};

// Function to delete the cookie
export const deleteCookie = () => {
  // Get the current value of the cookie (optional)
  const token = Cookies.get("wd-team");
  const token1= Cookies.get("username");
  const token2= Cookies.get("remember_me");
  // Remove the cookie if it exists
  if (token) {
    Cookies.remove("wd-team");
  }
  if (token1) {
    Cookies.remove("username");
  }
  if (token2) {
    Cookies.remove("remember_me");
  }
};
//function to checkif cookie is already present
export const getCookieWhenPageLoads = () => {
  const cookietoken = Cookies.get("wdteam-browserCookie");
  if (cookietoken) return true;
  return false;
};

export const getToken = () => {
  const token = Cookies.get("wd-team");
  return token;
};
//function to checkif cookie is already present
export const acceptCookieWhenPageLoads = (cookiedata) => {
  const expireTime = new Date();
  expireTime.setTime(expireTime.getTime() + 60 * 60 * 1000); // 1 hour from now
  const cookietoken = Cookies.set(
    "wdteam-browserCookie",
    JSON.stringify(cookiedata),
    {
      expires: expireTime,
    }
  );
  if (cookietoken) return true;
  return false;
};
