import React from "react";
import "./ShowFlag.css";

const ShowFlag = ({ flag, className, countryName, countryList }) => {
  function makeFlagSmall(str) {
    return str.toLowerCase();
  }

  let flagSrc;
  try {
    // Dynamically require the image if it's inside the src folder
    if (countryName) {
      // console.log("ShowFlag countryName", countryName);
      // console.log("ShowFlag countryList", countryList);
      let found = countryList.find(
        (country) => country.fldCountryName === countryName
      );
      flagSrc = require(`../../../assets/flags/sm/${makeFlagSmall(
        found.fldAlphacode
      )}.svg`);
    } else {
      flagSrc = require(`../../../assets/flags/sm/${makeFlagSmall(flag)}.svg`);
    }
  } catch (error) {
    flagSrc = ""; // Provide a fallback in case the image is missing
  }

  return (
    <>
      {/* Use alt for better accessibility */}
      {flagSrc ? (
        <img
          src={flagSrc}
          className={`flag-img ${className}`}
          alt={`${flag} flag`}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ShowFlag;
