import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // For accessing query parameters
import left from '../../../../../assets/images/v1/deals/left.svg'
const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  setCurrentPage,
}) => {
  const [goToPage, setGoToPage] = useState("");
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [visiblePages, setVisiblePages] = useState([]);
  const location = useLocation(); // Get the location to access query parameters

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 1200,
      behavior: "smooth",
    });
  };

  const handlePageClick = (page) => {
    onPageChange(page);
    scrollToTop();
  };

  const handleGoToPage = (e) => {
    e.preventDefault();
    const page = parseInt(goToPage, 10);
    if (!isNaN(page) && page > 0 && page <= totalPages) {
      onPageChange(page);
      scrollToTop();
    }
    setGoToPage("");
  };

  const updateVisiblePages = (page) => {
    if (totalPages <= 5) {
      setVisiblePages(Array.from({ length: totalPages }, (_, i) => i + 1));
    } else if (page > 3 && page < totalPages - 2) {
      setVisiblePages([page - 2, page - 1, page, page + 1, page + 2]);
    } else if (page <= 3) {
      setVisiblePages([1, 2, 3, 4, 5]);
    } else {
      setVisiblePages([
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ]);
    }
  };

  useEffect(() => {
    updateVisiblePages(currentPage);
  }, [currentPage, totalPages]);

  // Update currentPage if the search query contains a page parameter
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get("page"), 10);
    if (!isNaN(page) && page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  }, [location.search, setCurrentPage, totalPages]);

  const handleEllipsisClick = (direction) => {
    const firstVisiblePage = visiblePages[0];
    const lastVisiblePage = visiblePages[visiblePages.length - 1];

    if (direction === "left" && firstVisiblePage > 5) {
      setVisiblePages([
        firstVisiblePage - 5,
        firstVisiblePage - 4,
        firstVisiblePage - 3,
        firstVisiblePage - 2,
        firstVisiblePage - 1,
      ]);
    } else if (direction === "right" && lastVisiblePage < totalPages - 1) {
      setVisiblePages([
        lastVisiblePage + 1,
        lastVisiblePage + 2,
        lastVisiblePage + 3,
        lastVisiblePage + 4,
        lastVisiblePage + 5,
      ]);
    }
  };

  return (
    <div className="w-full mt-6 phone:mt-4 flex phone:flex-col tablet:flex-col phone:gap-6 tablet:gap-6 items-center justify-between">
      <p className="text-[16px] font-semibold text-neutral-900">
        Showing {itemsPerPage * (currentPage - 1) + 1} -{" "}
        {Math.min(itemsPerPage * currentPage, totalItems)} of {totalItems}{" "}
        <span className="text-primary-main">wholesale deals</span>
      </p>

      <div className="flex phone:flex-col items-center phone:gap-4 gap-6">
        <div className="flex items-center gap-2">
          {/* Previous Button */}
          <button
            onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
          >
            <img
              src={left}
              className="w-6 h-6"
              alt="Previous"
            />
          </button>

          {/* Page 1 always visible */}
          <button
            onClick={() => handlePageClick(1)}
            className={`w-10 h-10 flex items-center justify-center rounded-md font-semibold text-[16px] leading-6 ${
              currentPage === 1
                ? "bg-primary-surface text-primary-main"
                : "hover:bg-primary-surface/50 hover:text-primary-main transition-colors duration-100 text-black"
            }`}
          >
            1
          </button>

          {/* Left Ellipsis */}
          {visiblePages[0] > 2 && (
            <button
              onClick={() => handleEllipsisClick("left")}
              className="w-10 h-10 flex items-center justify-center rounded-md text-lg font-semibold text-black"
            >
              ...
            </button>
          )}

          {/* Page Numbers */}
          {visiblePages.map(
            (page) =>
              page !== 1 &&
              page !== totalPages && (
                <button
                  key={page}
                  onClick={() => handlePageClick(page)}
                  className={`w-10 h-10 flex items-center justify-center rounded-md font-semibold text-[16px] leading-6 ${
                    page === currentPage
                      ? "bg-primary-surface text-primary-main"
                      : "hover:bg-primary-surface/50 hover:text-primary-main transition-colors duration-100 text-black"
                  }`}
                >
                  {page}
                </button>
              )
          )}

          {/* Right Ellipsis */}
          {visiblePages[visiblePages.length - 1] < totalPages - 1 && (
            <button
              onClick={() => handleEllipsisClick("right")}
              className="w-10 h-10 flex items-center justify-center rounded-md text-lg font-semibold text-black"
            >
              ...
            </button>
          )}

          {/* Last Page always visible */}
          <button
            onClick={() => handlePageClick(totalPages)}
            className={`w-10 h-10 flex items-center justify-center rounded-md font-semibold text-[16px] leading-6 ${
              currentPage === totalPages
                ? "bg-primary-surface text-primary-main"
                : "hover:bg-primary-surface/50 hover:text-primary-main transition-colors duration-100 text-black"
            }`}
          >
            {totalPages}
          </button>

          {/* Next Button */}
          <button
            onClick={() =>
              handlePageClick(Math.min(currentPage + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            <img
              src={left}
              className="w-6 h-6 rotate-180"
              alt="Next"
            />
          </button>
        </div>
        {/* Go to Page Input */}
        <div className="flex items-center gap-2">
          <p className="text-neutral-900 text-[16px] font-semibold">Go to</p>
          <form onSubmit={handleGoToPage}>
            <input
              type="text"
              className="h-10 rounded-md bg-white w-20 text-center px-2 font-bold border border-neutral-200"
              value={goToPage}
              onChange={(e) => setGoToPage(e.target.value)}
            />
          </form>
          <p className="text-neutral-900 text-[16px] font-semibold">Page</p>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
