const StatisticCard = ({ title, value, description, iconSrc }) => {
  return (
    <div className="w-full py-[24px] px-[20px] phone:p-[16px] rounded-[12px] bg-white flex items-center justify-between gap-[12px]">
      <div>
        <p className="text-neutral-800 font-medium text-[16px] phone:text-[14px] phone:leading-[21px] leading-[24px]">
          {title}
        </p>
        <h1 className="text-secondary-hover tablet:text-[28px] tablet:leading-[39.2px] text-[40px] leading-[48px] font-bold phone:text-[24px] phone:leading-[33.6px] phone:my-[4px] my-[6px]">
          {value}
        </h1>
        <p className="text-neutral-600 font-normal text-[14px] leading-[21px] phone:text-[12px] phone:leading-[18px]">
          {description}
        </p>
      </div>
      <img src={iconSrc} className="w-[40px] h-[40px]" alt={title} />
    </div>
  );
};

export default StatisticCard;
