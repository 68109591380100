import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_SUPPLIER_CATEGORIES_BEST_DEALS } from "../api";

export const getSupplierCategoriesBestDeals = createAsyncThunk(
  "/getSupplierCategoriesBestDeals",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_SUPPLIER_CATEGORIES_BEST_DEALS}/${data.page}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Something went wrong while fetching the supplier's best deals."
      );
    }
  }
);

const initialState = {
  data: [],       
  isLoading: false,
  error: null,
};

const getSupplierCategoriesBestDealsSlice = createSlice({
  name: "getSupplierCategoriesBestDeals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierCategoriesBestDeals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSupplierCategoriesBestDeals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getSupplierCategoriesBestDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.payload || "Failed to load the supplier's best deals.";
      });
  },
});

export default getSupplierCategoriesBestDealsSlice.reducer;
