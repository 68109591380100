import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../hooks/cookies"; // Ensure this is correct
import { CHANGE_ORDER_STATUS } from "../api";  // Ensure this URL is correct

// Thunk for canceling an order
export const cancelOrder = createAsyncThunk(
  "orderActions/cancelOrder",
  async ({ orderId, userType }, { rejectWithValue }) => {
    try {
      const token = getToken();
      const status = "cancelled"; // Status set to "cancelled"
      console.log("Cancelling order with ID:", orderId);
      const apiUrl = `${CHANGE_ORDER_STATUS}/${orderId}`;
      if ((userType === "seller" || userType === "buyer") && orderId) {
        const response = await axios.post(
          apiUrl,
          { status },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("API Response:", response.data);
        return response.data;
      } else {
        return rejectWithValue("You can only cancel your own orders.");
      }
    } catch (error) {
      console.error("Error Details:", error.response?.data || error.message);
      return rejectWithValue(
        error.response?.data || "Failed to cancel the order"
      );
    }
  }
);

// Thunk for updating order status via seller dropdown
export const updateOrderStatus = createAsyncThunk(
  "orderActions/updateOrderStatus",
  async ({ orderId, status, userType }, { rejectWithValue }) => {
    try {
      const token = getToken();
      console.log("Updating order status for order ID:", orderId, "with status:", status);
      const apiUrl = `${CHANGE_ORDER_STATUS}/${orderId}`;
      if ((userType === "seller" || userType === "buyer") && orderId) {
        const response = await axios.post(
          apiUrl,
          { status },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("API Response:", response.data);
        return response.data;
      } else {
        return rejectWithValue("You can only update your own orders.");
      }
    } catch (error) {
      console.error("Error Details:", error.response?.data || error.message);
      return rejectWithValue(
        error.response?.data || "Failed to update the order status"
      );
    }
  }
);

const initialState = {
  cancel: {
    success: false,
    isLoading: false,
    error: null,
  },
  update: {
    success: false,
    isLoading: false,
    error: null,
  },
};

const orderActionsSlice = createSlice({
  name: "orderActions",
  initialState,
  reducers: {
    resetCancelState: (state) => {
      state.cancel.success = false;
      state.cancel.error = null;
      state.cancel.isLoading = false;
    },
    resetUpdateState: (state) => {
      state.update.success = false;
      state.update.error = null;
      state.update.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    // Cancel Order Cases
    builder
      .addCase(cancelOrder.pending, (state) => {
        state.cancel.isLoading = true;
        state.cancel.error = null;
        state.cancel.success = false;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.cancel.isLoading = false;
        state.cancel.success = true;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.cancel.isLoading = false;
        state.cancel.error =
          action.payload || "Failed to cancel the order";
      })
      // Update Order Status Cases
      .addCase(updateOrderStatus.pending, (state) => {
        state.update.isLoading = true;
        state.update.error = null;
        state.update.success = false;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.update.isLoading = false;
        state.update.success = true;
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.update.isLoading = false;
        state.update.error =
          action.payload || "Failed to update the order status";
      });
  },
});

export const { resetCancelState, resetUpdateState } = orderActionsSlice.actions;
export default orderActionsSlice.reducer;
