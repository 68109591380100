import axios from "axios";
import Cookies from "js-cookie"; // Assuming you're storing the token in cookies
import { VALIDATEUSERTOKEN } from "../api";

export const validateUserToken = async () => {
  try {
    const token = Cookies.get("wd-team");

    if (!token) {
      return false
    }

    const response = await axios.post(VALIDATEUSERTOKEN, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Token validation failed");
    }
  } catch (error) {

    return false;
  }
};

export const checkUserToken = async () => {
  const isValid = await validateUserToken();
  return isValid.success;
};
export const getUserIdUsingGetApi = async () => {
  const userdata = await validateUserToken();
  return userdata.data;
};
