import {AuthGuard} from "../guards"
import { Route } from 'react-router-dom';
import React from 'react'
const AdminDashboard=React.lazy(()=>import("../components/pages/admin/admin-dashboard/AdminDashboard"))
const AdminAuthRoutes = [
     <Route
      key="admin-dashboard"
      path="/admin-dashboard"
      element={<AuthGuard component={<AdminDashboard />} />}
    />,
]
export default AdminAuthRoutes
