import React, { useEffect, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import flashsale from "../../../assets/images/v1/homepage/flashsale-desktop.png";
import CountdownTimer from "../Navigations/components/CountdownTimer";
import { useDispatch, useSelector } from "react-redux";
import { getFlashsale } from "../../../store/slices/flashsaleSlice";
import discounttablet from "../../../assets/images/v1/homepage/discount-tablet.png";
import { timeRemainingFromNow } from "../constant"; // Adjust this path
import { Link } from "react-router-dom";
const MobileHeaderDown = ({ data }) => {
  const dispatch = useDispatch();
  const homeFlashsale = useSelector((state) => state.home_flashsale.data);
  const [timerObject, setTimerObject] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const isMobile = useMediaQuery("(max-width: 768px)"); // Define mobile breakpoint

  useEffect(() => {
    dispatch(getFlashsale());
  }, [dispatch]);

  useEffect(() => {
    if (homeFlashsale && homeFlashsale.fldSaleEndDate) {
      setTimerObject(timeRemainingFromNow(homeFlashsale.fldSaleEndDate));
    }
  }, [homeFlashsale]);

  return (
    <>
      {isMobile && (
        <div className="w-full phone:block">
          <div className="flex flex-col sm:flex-row">
            <div className="w-full px-[24px] pb-[8px] sm:pb-[16px] pt-[16px] flex justify-between sm:justify-start sm:gap-6 items-center bg-[#E04C3F]">
              <div className="w-[95px] h-[16px]">
                <img src={flashsale} className="w-full" alt="flashsale" />
              </div>
              <div className="w-[84px] h-[40px]">
                <img src={discounttablet} className="w-full" alt="discount" />
              </div>
            </div>
            <div className="w-full px-[24px] pb-[16px] pt-[8px] phone:pt-0 sm:pt-[16px] flex justify-between sm:justify-start sm:gap-6 bg-[#E04C3F]">
              <div className="w-fit h-[40px] ml-[37px] tablet:ml-[30px] phone:ml-0">
                <CountdownTimer
                  initialDays={timerObject.days}
                  initialHours={timerObject.hours}
                  initialMinutes={timerObject.minutes}
                  initialSeconds={timerObject.seconds}
                />
              </div>
              <div className="flex flex-col items-center gap-1 px-[4px]">
                <div className="flex flex-col items-center gap-1 px-[4px] packages-banner-wrapper">
                  <div className="flex gap-[2px]">
                    <div>
                      <p
                        className="text-white text-[32px] packages-value font-bold"
                        data-time-block={0}
                      >
                        30
                      </p>
                    </div>
                  </div>
                  <p className="text-[8px] leading-[14px] text-white whitespace-nowrap">
                    REMAINING PACKAGES
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-[24px] py-[16px] bg-[#0355A5] phone:flex phone:text-center">
            <Link
              to="/subscribe"
              className="py-[8px] no-underline px-[24px] text-[16px] font-semibold leading-[24px] text-white bg-[#E66927] rounded-[8px] w-full"
            >
              Join Now
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileHeaderDown;
