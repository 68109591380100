import React from "react";
import global from "../../../assets/images/v1/icons/global.svg"
import like from "../../../assets/images/v1/icons/like.svg"
import chart from "../../../assets/images/v1/icons/chart.svg"
import hand from "../../../assets/images/v1/icons/hand.svg"
const CounterSection = () => {
  return (
    <div className="w-full bg-[#FAFAFA]">
      <div className="w-full max-w-[1440px] mx-auto py-[20px]">
        <div className="px-[56px] phone:px-[24px] grid phone:grid-cols-1 phone:gap-[16px] tablet:grid-cols-2 grid-cols-4 tablet:gap-[10px] gap-[24px] max-w-[1440px] mx-auto">
          <div className="w-full flex items-center gap-[12px]">
            <img
              className="min-w-[26px] w-[26px] h-[26px] phone:min-w-[22px] phone:w-[22px]"
              src={global}
              alt="global"
            />
            <p className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] text-neutral-700 font-normal">
              Over 61,000 verified suppliers from 150 countries
            </p>
          </div>
          <div className="w-full flex items-center gap-[12px]">
            <img
              className="min-w-[26px] w-[26px] h-[26px] phone:min-w-[22px] phone:w-[22px]"
              src={like}
              alt="like"
            />
            <p className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] text-neutral-700 tablet:max-w-[290px] font-normal">
              450,000+ resellers trust Wholesale Deals
            </p>
          </div>
          <div className="w-full flex items-center gap-[12px]">
            <img
              className="min-w-[26px] w-[26px] h-[26px] phone:min-w-[22px] phone:w-[22px]"
              src={hand}
              alt="hand"
            />
            <p className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] text-neutral-700 font-normal">
              Contact suppliers directly without commissions
            </p>
          </div>
          <div className="w-full flex items-center gap-[12px]">
            <img
              className="min-w-[26px] w-[26px] h-[26px] phone:min-w-[22px] phone:w-[22px]"
              src={chart}
              alt="chart"
            />
            <p className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] text-neutral-700 font-normal">
              High margin deals with prices up to 95% below retail
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
