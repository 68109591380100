import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

// store
import { useDispatch, useSelector } from "react-redux";

// custom hooks
import { useUser } from "../../../../hooks";

// style
import "./SingleDealsWrapper.css";

// slice
import { getSingleDeal } from "../../../../store/slices/singleDealSlice";
import { getStats } from "../../../../store/slices/statsSlice";
import { getAllTestiMonials } from "../../../../store/slices/alltestimonialsSlice";
import { convertToTestimonials, getCategoryById } from "../constant";
import { mostPopularRecentDeals } from "../../../../store/slices/wholesalesuppliersSlice";

// constants
import { deserializeStats } from "./constant";

// components
import ProductDescriptionWrapper from "./ProductDescriptionWrapper/ProductDescriptionWrapper";
import TagsSuperchargeWrapper from "./TagsSuperchargeWrapper/TagsSuperchargeWrapper";
import MostPopularOffers from "../components/MostPopularOffers/MostPopularOffers";
import RelatedSearchs from "../../common/RelatedSearchs";
import StatisticsSection from "./components/StatisticsSection/StatisticsSection";
import TestimonialsSection from "./components/TestimonialsSection/TestimonialsSection";
import EmailCta from "../components/EmailCTA.jsx/EmailCta";
import Footer from "../../../sharedComponent/Footer";
import DealsDetalsWrapper from "./DealsDetalsWrapper/DealsDetalsWrapper";
import SimpleSpinner from "../../../../assets/spinner/SimpleSpinner";
import CounterSection from "../../../sharedComponent/CounterSection";
import NavComponent from "../../../sharedComponent/AllCategories/components/AllCategoriesNav";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

const SingleDealsWrapper = () => {
  const { slug } = useParams();
  const { getUserToken } = useUser();
  const token = getUserToken();
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.home_stats);
  const mostPopularOffers = useSelector(
    (state) => state.wholesalesuppliers.data
  );
  useEffect(() => {
    dispatch(getSingleDeal({ slug, token }));
    dispatch(mostPopularRecentDeals());
    dispatch(getStats({token}));
    dispatch(getAllTestiMonials());
  }, []);
  const { data, isLoading } = useSelector((state) => state.single_deal);
  const testimonialData = useSelector((state) => state.alltestimonials.data);
  const statsData = deserializeStats(stats.data);
  const testimonials = convertToTestimonials(testimonialData, 6);
  const categories = useSelector((state) => state.categories.data);
  const category = getCategoryById(categories, data.fldDealCategoryId1);

  useEffect(() => {
    // console.log("category", category);
  }, [category]);
  return (
    <>
      {isLoading ? (
        <div className="h-[100vh] flex justify-center">
          <SimpleSpinner customClass="m-[25vh]" />
        </div>
      ) : (
        <>
          <CounterSection />
          <NavComponent />
          <div className="bg-[#F8FAFC]">
            <div className="w-full max-w-[1440px] mx-auto pt-[24px] mb-[24px] phone:px-[24px] px-[56px] flex items-center gap-[8px]">
              <Breadcrumb
                categories={categories}
                id={data.fldDealCategoryId1}
                singleDeals={data}
              />
            </div>
            <div className="w-full max-w-[1440px] mx-auto px-[56px] phone:px-[16px] ">
              <div className="w-full bg-white phone:p-[20px] tablet:p-[24px] p-[40px] phone:mb-[40px] tablet:mb-[56px] desktop:mb-[72px]">
                {data && (
                  <>
                    <DealsDetalsWrapper
                      singleData={data}
                      soldOut={data.fldDealStatus === "E" ? true : false}
                    />
                    <ProductDescriptionWrapper data={data} />
                    <TagsSuperchargeWrapper
                      tags={[
                        "#sports",
                        "#sneakers",
                        "#sport",
                        "#Trainers",
                        "#branded",
                        "#footwear",
                      ]}
                      superchargeFeatures={[
                        "Discover trustworthy suppliers with low or no minimum orders.",
                        "Access ‘industry secret’ suppliers that you won’t find on Google.",
                        "Quickly filter through 150,000+ suppliers with specific search criteria",
                        "And 8+ more features for premium members.",
                      ]}
                      superchargeButtonLink="https://wholesaledeals.vercel.app/wholesale/subscribe.html"
                    />
                  </>
                )}
              </div>
            </div>
            <MostPopularOffers data={mostPopularOffers} />
            <RelatedSearchs />
            <StatisticsSection
              title="Statistics"
              iconSrc="../assets/images/v1/market/thropy.svg"
              stats={statsData}
            />
            <TestimonialsSection testimonials={testimonials} />
            <EmailCta />
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default SingleDealsWrapper;
