import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { COVERSATION_LIST } from "../api";
import logout from "../../_helperfunctions/Logout";
export const getConverSationList = createAsyncThunk(
  "order/getConverSationList",
  async (data, { rejectWithValue }) => {
    try {
      if (data.search) {
        const response = await axios.get(
          `${COVERSATION_LIST}?search=${data.search}`,
          {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        );
        return response.data;
      } else {
        const response = await axios.get(COVERSATION_LIST, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const conversationListSlice = createSlice({
  name: "conversationList",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConverSationList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConverSationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getConverSationList.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default conversationListSlice.reducer;
