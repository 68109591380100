import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { MOST_POPULAR_RECENT_DEALS } from "../api";
export const mostPopularRecentDeals = createAsyncThunk(
  "/mostPopularRecentDeals",
  async (tokens) => {
    if (tokens.token) {
      const response = await axios.get(MOST_POPULAR_RECENT_DEALS, {
        headers: {
          Authorization: `Bearer ${tokens.token}`,
        },
      });
      return response.data;
    } else {
      const response = await axios.get(MOST_POPULAR_RECENT_DEALS);
      return response.data;
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const mostPopularRecentSlice = createSlice({
  name: "mostPopularRecent",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(mostPopularRecentDeals.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(mostPopularRecentDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(mostPopularRecentDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
      });
  },
});
export default mostPopularRecentSlice.reducer;
