import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETDEALS ,API_STARTING,GET_MANAGE_DEALSBYID} from "../api";
import logout from "../../shared/logout";
export const getDeals = createAsyncThunk(
  "/getDeals",

  async (data, { rejectWithValue }) => {
    try {
      if (data.dealId === "") {
        const response = await axios.get(
          `${API_STARTING}/user/deals`,
          {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        );
        return response.data; // Ensure this is the expected format
      } else if (data.dealId !== "") {
        console.log(`Fetching deals for dealId: ${data.dealId}`); // Debugging line
        const response = await axios.get(`${GETDEALS}?page=${data.dealId}`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });

        return response.data; // Ensure this is the expected format
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the deal profile."
      );
    }
  }
);
const initialState = {
  data: {},
  isLoading: true,
  error: null,
  filteredData: [], // Filtered data based on search input
  currentPage: 1, // Current page number
  rowsPerPage: 10, // Number of rows per page
};

const dealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      state.filteredData = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeals.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      })
      
  },
});
export const { setData,setCurrentPage } = dealsSlice.actions;
export default dealsSlice.reducer;
