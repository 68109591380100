import React from "react";

const PaymentOption = ({ icon, title, description }) => {
  return (
    <div className="w-full flex gap-[16px] items-start">
      <img src={icon} alt={title} className="w-[24px] h-[24px] min-w-[24px]" />
      <div className="grid gap-[8px]">
        <h3 className="text-neutral-900 phone:text-[16px] phone:leading-[24px] font-bold text-[18px] leading-[27px]">
          {title}
        </h3>
        <p className="font-normal text-[16px] phone:text-[14px] phone:leading-[21px] leading-[24px] text-[#716F70]">
          {description}
        </p>
      </div>
    </div>
  );
};

const PaymentOptions = ({ options }) => {
  return (
    <div className="w-full py-[40px] phone:py-[24px] grid gap-[20px]">
      <h1 className="font-bold phone:text-[20px] phone:leading-[27px] text-[24px] leading-[33.6px] text-[#000]">
        Payment Options
      </h1>
      <div className="w-full grid grid-cols-3 phone:grid-cols-1 gap-[40px] phone:gap-[16px]">
        {options.map((option, index) => (
          <PaymentOption
            key={index}
            icon={option.icon}
            title={option.title}
            description={option.description}
          />
        ))}
      </div>
    </div>
  );
};

export default PaymentOptions;
