import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_BUYER_ORDER } from "../api";
import { getToken } from "../../hooks/cookies";
// Async thunk to fetch buyer orders
export const fetchBuyerOrders = createAsyncThunk(
  "buyerOrdersDetail/fetchBuyerOrders",
  async (orderId, { rejectWithValue }) => {
    try {
      const token = getToken();
      const response = await axios.get(`${GET_BUYER_ORDER}/${orderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const buyerOrdersDetailSlice = createSlice({
  name: "buyerOrdersDetail",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuyerOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuyerOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchBuyerOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch buyer orders.";
      });
  },
});

export default buyerOrdersDetailSlice.reducer;
