import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_TESTIMONIALS } from "../api";
export const getTestiMonials = createAsyncThunk(
  "/getTestiMonials",
  async () => {
    const response = await axios.get(GET_TESTIMONIALS);
    return response.data;
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const commonhomepageSlice = createSlice({
  name: "commonhomepage",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTestiMonials.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getTestiMonials.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTestiMonials.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload;
      });
  },
});
export default commonhomepageSlice.reducer;
