import React, { useEffect, useState } from "react";
import BuyerSellerNav from "./components/BuyerSellerNav";
import TopNav from "./components/TopNav";
import MainNav from "./components/MainNav";
import { useLocation } from "react-router-dom";
import { useRedirection } from "../../../hooks";
import { useSelector, useDispatch } from "react-redux";
import { fetchAuthStatus } from "../../../store/slices/authSlice";
import { useUser } from "../../../hooks";
const MobileHeader = React.lazy(() =>
  import("../MobileHeaderMenu/MobileHeader")
);
const MobileHeaderDown = React.lazy(() =>
  import("../MobileHeaderMenu/MobileHeaderDown")
);

const Navigation = () => {
  const dispatch = useDispatch();
  const { getUserToken } = useUser();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const [isPremium, setIsPremium] = useState(false);
  const [auth, setAuth] = useState(false);
  const accountdata = useSelector((item) => item.user);
  const { redirectToLoginPage } = useRedirection();
  const isAuthRoute =
    location.pathname.includes("/userlogin") ||
    location.pathname.includes("/reset-password");
  const ShowRegisterForm = () => {
    redirectToLoginPage();
  };
  const ShowLoginForm = () => {
    redirectToLoginPage();
  };
  useEffect(() => {
    getItems();
  }, [isAuthenticated]);

  const getItems = async () => {
    const token = getUserToken();
    dispatch(fetchAuthStatus(token));
    if (isAuthenticated) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  };

  useEffect(() => {
    if (!accountdata.isLoading && accountdata.data) {
      const data = accountdata.data.user;
      if (data.fldPremium == "Y") {
        setIsPremium(true);
      } else {
        setIsPremium(false);
      }
    }
  }, [accountdata.data, accountdata.isLoading]);
  return (
    <>
      {" "}
      {auth && !isPremium && <TopNav />}
      {!auth && <TopNav />}
      <BuyerSellerNav />
      {!isAuthRoute && <MainNav ShowRegisterForm={ShowRegisterForm} />}
      {!isAuthRoute && (
        <MobileHeader
          ShowRegisterForm={ShowRegisterForm}
          ShowLoginForm={ShowLoginForm}
        />
      )}
      {!isAuthRoute && <MobileHeaderDown />}
    </>
  );
};

export default Navigation;
