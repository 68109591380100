import React, { useState } from "react";

// custom hooks
import { useMediaQuery } from "../../../../../../hooks";

// auths
import { getToken } from "../../../../../../hooks/cookies";

// apis
import {
  onHideClick,
  onShowClick,
} from "../../../../../../store/api/hideShowDeals";
import { onUnLikeClick } from "../../../../../../store/api/likeDeals";

// Images
import eyeoff from "../../../../../../assets/images/v1/deals/eyeoff.svg";
import eye from "../../../../../../assets/images/v1/deals/eye.svg";
import heart from "../../../../../../assets/images/v1/icons/heart-rounded.svg";
import liked from "../../../../../../assets/images/v1/deals/heart-rounded-liked.svg";

// components
import Modal from "../../../components/Modal/Modal";
import LikeForm from "../../../components/LikeForm/LikeForm";
import LoginForm from "../../../components/LoginForm/LoginForm";

// store
import { useDispatch, useSelector } from "react-redux";
import { getSingleDeal } from "../../../../../../store/slices/singleDealSlice";
import {
  getCurrencyCode,
  getCurrencySymbol,
} from "../../../../../../store/slices/currencySlice";
import { getConvertedRate } from "../../../../../sharedComponent/Calculation/CurrencyCalculation";

const PriceHideFav = ({
  price,
  vat,
  rrp,
  soldOut,
  id,
  like,
  slug,
  hide,
  fldDealCurrencyId,
}) => {
  const currencySymbol = useSelector(getCurrencySymbol);
  const currencyCode = useSelector(getCurrencyCode);
  const [isHidden, setIsHidden] = useState(hide);
  const [isLiked, setIsLiked] = useState(like);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isModalOpen, setIsModalOpen] = useState(false); // Define mobile breakpoint
  const isToken = getToken();
  const dispatch = useDispatch();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const hideCard = () => {
    onHideClick({ dealId: id }).then(() => {
      dispatch(getSingleDeal({ slug, token: isToken }));
    });
  };
  const showCard = () => {
    onShowClick({ dealId: id }).then(() => {
      dispatch(getSingleDeal({ slug, token: isToken }));
    });
  };
  const unLike = () => {
    onUnLikeClick(id).then(() => {
      setIsLiked(false);
      dispatch(getSingleDeal({ slug, token: isToken }));
    });
  };
  const findCurrency = (currency) => {
    return currencySymbol;
  };
  return (
    <div className={`w-full grid gap-2 ${soldOut ? "grayscale" : ""}`}>
      <div className="flex items-center justify-between w-full phone:flex-col phone:items-start">
        <div className="grid gap-2">
          <div className="flex items-end gap-2">
            {price && (
              <h3 className="text-[32px] phone:text-[24px] phone:leading-[33px] leading-[40px] font-bold text-secondary-main">
                {findCurrency(fldDealCurrencyId)}
                {getConvertedRate(price, currencyCode)}
              </h3>
            )}
            <p className="text-[14px] leading-[21px] font-normal text-neutral-500">
              {vat}
            </p>
          </div>
          <p className="text-[16px] leading-[24px] text-neutral-500 text-sm desktop:text-base">
            RRP:{" "}
            <span className="font-bold">
              {" "}
              {findCurrency(fldDealCurrencyId)}
              {getConvertedRate(rrp, currencyCode)}
            </span>{" "}
            inc. VAT
          </p>
        </div>
        {/* Desktop Hide and Like Buttons */}
        <div className="w-fit phone:hidden flex items-center gap-4">
          {!isMobile ? (
            <div className="flex items-center gap-4">
              {isHidden ? (
                <button
                  className="relative"
                  onClick={isToken ? showCard : openModal}
                >
                  <img src={eye} alt="Hide" className="w-6 h-6" />
                </button>
              ) : (
                <button
                  className="relative"
                  onClick={isToken ? hideCard : openModal}
                >
                  <img src={eyeoff} alt="Hide" className="w-6 h-6" />
                </button>
              )}
              {isLiked ? (
                <button className="relative" onClick={unLike}>
                  <img src={liked} alt="Like" className="w-6 h-6" />
                </button>
              ) : (
                <button className="relative" onClick={openModal}>
                  <img src={heart} alt="Like" className="w-6 h-6" />
                </button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {/* Mobile Hide and Like Buttons */}
        <div className="w-fit hidden phone:flex mt-4 items-center gap-2.5">
          {isHidden ? (
            <button
              onClick={isToken ? showCard : openModal}
              className="show-hide-btn flex items-center gap-1"
            >
              <img src={eye} className="w-6 h-6" alt="eye-toggle" />
              <p className="text-neutral-500 font-semibold text-[12px] leading-[18px]">
                Show Result
              </p>
            </button>
          ) : (
            <button
              onClick={isToken ? hideCard : openModal}
              className="show-hide-btn flex items-center gap-1"
            >
              <img src={eyeoff} className="w-6 h-6" alt="eye-toggle" />
              <p className="text-neutral-500 font-semibold text-[12px] leading-[18px]">
                Hide Result
              </p>
            </button>
          )}

          <svg
            width="1"
            height="20"
            viewBox="0 0 1 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="0.5" y1="0" x2="0.5" y2="20" stroke="#E2E8F0" />
          </svg>

          {isLiked ? (
            <button
              onClick={unLike}
              className="like-btn flex items-center gap-1"
            >
              <img src={liked} alt="Like" className="w-6 h-6" />
              <p className="text-neutral-500 font-semibold text-[12px] leading-[18px]">
                Added to Wishlist
              </p>
            </button>
          ) : (
            <button
              onClick={openModal}
              className="like-btn flex items-center gap-1"
            >
              <img src={heart} alt="Like" className="w-6 h-6" />
              <p className="text-neutral-500 font-semibold text-[12px] leading-[18px]">
                Add to Wishlist
              </p>
            </button>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title=""
        customWidth={isToken ? "desktop:40vw" : ""}
      >
        {isToken ? (
          <LikeForm
            id={id}
            closeModal={closeModal}
            dispatchCommonDeals={() => {
              dispatch(getSingleDeal({ slug, token: isToken }));
            }}
            // dispatchCommonDeals
          />
        ) : (
          <LoginForm setIsModalOpen={setIsModalOpen} />
        )}
      </Modal>
    </div>
  );
};

export default PriceHideFav;
