import React from "react";
import arrowrightup from "../../../assets/images/v1/icons/arrow-up-right-orange.svg";
import tour from "../../../assets/images/v1/icons/tour.png";
import stories from "../../../assets/images/v1/icons/stories.png";
import join from "../../../assets/images/v1/icons/join.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { saveSectionToScroll } from "../../../utils/paymentsMethods";

const DetailFooter = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleViewAllClick = () => {
    saveSectionToScroll("footer");
  };

  return (
    <div>
      <div className="w-full max-w-[1440px] mx-auto phone:py-[72px] phone:px-[24px] phone:grid-cols-1 py-[80px] tablet:px-[137px] px-[120px] grid tablet:grid-cols-1 grid-cols-3 gap-[32px]">
        <div className="w-full">
          <img src={tour} className="w-[72px] h-[72px]" alt="" suitcase="" />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Take a Tour
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            A Better Way to Find Wholesalers, Dropshippers, Stocklots, and
            Wholesale Products
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            {" "}
            Whether you're an established retailer or just starting out,
            Wholesale Deals offers a smarter approach to sourcing. Get better
            products at lower prices and see exactly how much you can earn in
            just one glance — no surprises or time wasted.{" "}
            <Link
              to="/tour-step-1"
              onClick={handleViewAllClick}
              className="font-medium text-[#E66927] underline "
            >
              Take a quick tour
            </Link>{" "}
            and see for yourself how we can transform your wholesale sourcing
            experience.
          </p>
          <Link
            to="/tour-step-1"
            onClick={handleViewAllClick}
            className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px] underline "
          >
            Take a Tour
            <img
              src={arrowrightup}
              className="w-[20px] h-[20px]"
              alt="arrow-up-right"
            />
          </Link>
        </div>
        <div className="w-full">
          <img src={stories} className="w-[72px] h-[72px]" alt="conversation" />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Our Success Stories
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            Discover how Wholesale Deals has helped hundreds of thousands of
            traders, companies, and individuals find quality wholesalers and
            profitable products since our launch in 2004.
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            Your feedback has driven our improvements, including our innovative
            deal tracker service. Check out our{" "}
            <Link to="/all-testimonials" onClick={handleViewAllClick} className="text-[#E66927] underline ">
              testimonial page
            </Link>{" "}
            to see real-life successes, and if you're already a member, we’d
            love to{" "}
            <Link
              to="/contact-us#contact-form"
              onClick={handleViewAllClick}
              className="text-[#E66927] underline "
            >
              drop us a line
            </Link>{" "}
            — your opinion always matters.
          </p>
          <Link
            to="/all-testimonials"
            onClick={handleViewAllClick}
            className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px] underline "
          >
            View Testimonials
            <img
              src={arrowrightup}
              className="w-[20px] h-[20px]"
              alt="arrow-up-right"
            />
          </Link>
        </div>
        <div className="w-full">
          <img src={join} className="w-[72px] h-[72px]" alt="notes" />
          <h3 className="font-bold text-[24px] leading-[33.6px] text-white mt-[24px]">
            Join Us
          </h3>
          <p className="text-[16px] leading-[24px] text-white font-medium my-[12px]">
            Whether you're new to Wholesale Deals or returning, now's the
            perfect time to save with our latest membership offers.
          </p>
          <p className="text-[16px] leading-[24px] font-normal text-white text-opacity-[0.56]">
            <Link
              to="/subscribe"
              onClick={handleViewAllClick}
              className="text-[16px] leading-[24px] font-normal text-[#E66927] underline  mt-[12px]"
            >
              Upgrade to premium
            </Link>{" "}
            for full access and unlimited custom sourcing support. Register Now
            and start benefiting from our extensive resources and proven
            expertise.
          </p>
          {!isAuthenticated && (
            <Link
              to="/login?tab=register"
              onClick={handleViewAllClick}
              className="flex items-center gap-[6px] text-[16px] leading-[24px] font-medium text-[#E66927] mt-[24px] underline"
            >
              Register Now
              <img
                src={arrowrightup}
                className="w-[20px] h-[20px]"
                alt="arrow-up-right"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailFooter;
