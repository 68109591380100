// @description  Fetches flash sale data and calculates remaining sale time.Displays live countdown for the flash sale’s end time.
// @author Nidhi
// @ data 2024-11-11

import { useEffect } from "react";
import brand from "../../../../assets/images/v1/brand/logo.svg";
import flashsale from "../../../../assets/images/v1/homepage/flashsale-desktop.png";
import discount from "../../../../assets/images/v1/homepage/discount.png";
import flashtablet from "../../../../assets/images/v1/homepage/flash-tablet.png";
import discounttablet from "../../../../assets/images/v1/homepage/discount-tablet.png";
import hamburgermenu from "../../../../assets/images/v1/homepage/hamburger-menu.svg";
import search from "../../../../assets/images/v1/homepage/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { getFlashsale } from "../../../../store/slices/flashsaleSlice";
import MobileHeaderDown from "../../MobileHeaderMenu/MobileHeaderDown";
import { timeRemainingFromNow } from "../../constant";
import CountdownTimer from "./CountdownTimer";

const TopNav = () => {
  const customStyle = {
    backgroundImage: "url('../../../assets/images/v1/homepage/wrapper.svg')",
    backgroundSize: "cover",
    width: "100%",
    backgroundPosition: "right 25% top",
  };

  const dispatch = useDispatch();
  const homeFlashsale = useSelector((state) => state.home_flashsale.data);
  let timerObject = timeRemainingFromNow(homeFlashsale.fldSaleEndDate);
  useEffect(() => {
    dispatch(getFlashsale());
  }, [dispatch]);

  useEffect(() => {
    timerObject = timeRemainingFromNow(homeFlashsale.fldSaleEndDate);
  }, [homeFlashsale]);

  return (
    <div>
      <div className="block w-full phone:hidden" style={customStyle}>
        <div className="w-full phone:hidden px-[56px] py-[14px] tablet:py-[16px] flex items-center max-w-[1440px] mx-auto">
          <div className="block tablet:hidden phone:hidden">
            <img
              src={flashsale}
              className="w-[235px] h-[40px]"
              alt="flashsale"
            />
          </div>
          <div className="hidden">
            <img
              src={flashtablet}
              className="w-[105px] h-[48px]"
              alt="flashsale"
            />
          </div>
          <div className="w-[466px] h-[52px] tablet:w-[121px] tablet:h-[52px] ml-[32px] tablet:ml-[0]">
            <div className="block tablet:hidden">
              <img src={discount} className="w-full" alt="discount" />
            </div>
            <div className="hidden tablet:block">
              <img src={discounttablet} className="w-full" alt="discount" />
            </div>
          </div>
          {/* timer */}
          <CountdownTimer
            initialDays={timerObject.days}
            initialHours={timerObject.hours}
            initialMinutes={timerObject.minutes}
            initialSeconds={timerObject.seconds}
          />
          {/* timer end */}
          <div className="bg-white/20 w-[1px] h-[36px] mx-5" />
          <div className="flex flex-col items-center gap-1 mr-[32px] packages-banner-wrapper">
            <div className="flex gap-[2px]">
              <div>
                <p
                  className="text-white text-[32px] packages-value font-bold"
                  data-time-block={0}
                >
                  30
                </p>
              </div>
            </div>
            <p className="text-[8px] leading-[14px] text-white whitespace-nowrap">
              REMAINING PACKAGES
            </p>
          </div>
          <a
            href="https://wholesaledeals.vercel.app/wholesale/subscribe.html"
            className="w-fit no-underline rounded-[8px] border-none bg-[#E66927] hover:bg-[#f98344] transition-all duration-200 py-[8px] px-[24px] text-white font-semibold text-[16px] leading-[24px] ml-auto whitespace-nowrap"
          >
            Join Now
          </a>
          <MobileHeaderDown data={homeFlashsale} />
        </div>
      </div>
      {/* Mobile Device */}
    </div>
  );
};

export default TopNav;
