import React, { useEffect } from "react";
import RightsFooter from "./RightsFooter";
import DetailFooter from "./DetailFooter";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.scrollToSection) {
      const element = document.getElementById(location.state.scrollToSection);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  return (
    <footer id="footer" className="w-screen bg-[#222] p-0">
      <DetailFooter />
      <RightsFooter />
    </footer>
  );
};

export default Footer;
