import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SENDEMAILTOUSER, VALIDATETOKEN, RESETPASSWORD } from "../api";

// Thunks
export const SendEmailToUser = createAsyncThunk(
  "auth/sendemail",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(SENDEMAILTOUSER, email);
      return response.data; // Assuming response data contains the necessary information
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to send email");
    }
  }
);

export const ValidateToken = createAsyncThunk(
  "auth/validatetoken",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${VALIDATETOKEN}/${token}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Invalid token");
    }
  }
);

export const ResetUserPassword = createAsyncThunk(
  "auth/resetpassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(RESETPASSWORD, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to reset password");
    }
  }
);

// Slice
const passwordSlice = createSlice({
  name: "password",
  initialState: {
    success: false,
    message: "",
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // SendEmailToUser Cases
      .addCase(SendEmailToUser.pending, (state) => {
        state.success = false;
        state.isLoading = true;
        state.error = null; // Clear previous error
      })
      .addCase(SendEmailToUser.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.success = action.payload.success;
        state.isLoading = false;
      })
      .addCase(SendEmailToUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to send email. Please try again.";
      })

      // ValidateToken Cases
      .addCase(ValidateToken.pending, (state) => {
        state.success = false;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(ValidateToken.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.success = action.payload.success;
        state.isLoading = false;
      })
      .addCase(ValidateToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Invalid token. Please try again.";
      })

      // ResetUserPassword Cases
      .addCase(ResetUserPassword.pending, (state) => {
        state.success = false;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(ResetUserPassword.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.success = action.payload.success;
        state.isLoading = false;
      })
      .addCase(ResetUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to reset password. Please try again.";
      });
  },
});

export default passwordSlice.reducer;
