import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_COUNTRIES_SUPPLIER_LIST } from "../api";

// Async thunk that accepts a dynamic categoryId parameter
export const getCountriesSuppliers = createAsyncThunk(
  "countriesSuppliers/getCountriesSuppliers",
  async (categoryId, thunkAPI) => {
    try {
      const url = categoryId
        ? `${GET_COUNTRIES_SUPPLIER_LIST}?categoryId=${categoryId}`
        : GET_COUNTRIES_SUPPLIER_LIST;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || error.message
      );
    }
  }
);

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const countriesSuppliersSlice = createSlice({
  name: "countriesSuppliers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCountriesSuppliers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCountriesSuppliers.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCountriesSuppliers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default countriesSuppliersSlice.reducer;
