import React from "react";
import { BuyerDropDown, CurrenciesDropDown, SellerDropdown } from "../../../pages/Login";

const BuyerSellerNav = () => {
  return (
    <div>
      <div className="w-full bg-[#FBD78B] phone:hidden block h-[40px] relative flex items-center">
        <div className="w-full max-w-[1440px] mx-auto px-[56px] flex items-center justify-between">
          <div className="w-fit relative flex items-center gap-[24px]">
            <BuyerDropDown />
            <SellerDropdown />
            <button className="help px-[6px] py-[3px] rounded-[6px] transition-all duration-300 font-normal text-[14px] leading-[21px] text-neutral-800">
              Help
            </button>
          </div>
          <CurrenciesDropDown />
          {/* <div className="relative select-dropdown-container">
      <button className="open-language flex px-2 items-center gap-[6px] py-[3px] rounded-[6px] transition-all duration-300">
        <p className="font-normal text-[14px] leading-[21px] text-neutral-800">
          GBP
        </p>
        <img
          src="../assets/images/v1/icons/chevron-down.svg"
          width="16px"
          height="16px"
          alt="icons"
        />
      </button>
      <div className="w-[80px] flex flex-col absolute text-left mt-[7px] right-[0px] p-[4px] bg-white hidden language-modal select-dropdown">
        <button className="buyer-benefit transition-all text-left pl-2 duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800">
          IDR
        </button>
        <button className="buyer-register transition-all text-left pl-2 duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800">
          MYR
        </button>
        <button className="buyer-register transition-all text-left pl-2 duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800">
          SGD
        </button>
      </div>
    </div> */}
        </div>
      </div>
    </div>
  );
};

export default BuyerSellerNav;
