import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import logout from "../../_helperfunctions/Logout";
import { VERIFY_EMAIL } from "../api";

// Async thunk for email verification
export const verifyEmail = createAsyncThunk(
  "verifyEmail/verify",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${VERIFY_EMAIL}?token=${token}`); // ✅ Fixed backticks for template literals
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: "Unknown error occurred" });
    }
  }
);

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const verifyEmailSlice = createSlice({
  name: "verifyEmail",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = {
          success: action.payload.success || false,  // ✅ Ensure success is always a boolean
          message: action.payload.message || "Email successfully verified!",
        };
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || { message: "Verification failed" };
        if (action.payload && action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default verifyEmailSlice.reducer; // ✅ Fixed export reducer
