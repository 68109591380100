import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETSUPPLIERS_CATEGORY } from "../api";
export const getCommonSupplierCategory = createAsyncThunk(
  "/getCommonSupplierCategory",
  async (data, { rejectWithValue }) => {
    try {
      const queryString = data.params.toString() ? `?${data.params.toString()}` : "";
      const response = data.token
        ? await axios.get(`${GETSUPPLIERS_CATEGORY}${queryString}`, {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          })
        : await axios.get(`${GETSUPPLIERS_CATEGORY}${queryString}`);
      if (!response.data) {
        return rejectWithValue("No deals found.");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the deal profile."
      );
    }
  }
);

const initialState = {
  data: {},
  isLoading: true,
  currentPage: 1,
  itemsPerPage: 10,
  totalPage: 0,
  error: null,
};

const supplierCategorySlice = createSlice({
  name: "supplierCategory",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetData: (state) => {
      state.data = {};
      state.currentPage = 1;
      state.totalPage = 0;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommonSupplierCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCommonSupplierCategory.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCommonSupplierCategory.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setPage, setError } = supplierCategorySlice.actions;
export default supplierCategorySlice.reducer;
