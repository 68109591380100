import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETCURRENCIES } from "../api";
export const getCurrenciesList = createAsyncThunk(
  "/getcurrentcieslist",
  async () => {
    const response = await axios.get(GETCURRENCIES);
    return response.data;
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const currenciesDropdownSlice = createSlice({
  name: "currencies",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCurrenciesList.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCurrenciesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrenciesList.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
      });
  },
});
export default currenciesDropdownSlice.reducer;
