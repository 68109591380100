import React, { useEffect, useState } from "react";
import { findCategoryPath } from "../../constant";
import OfferHeaderComponent from "../OfferHeaderComponent/OfferHeaderComponent";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../../../hooks/cookies";
import { getCategoriesList } from "../../../../../store/slices/categoriesDropdownSlice";
import { routes } from "../../../../../routes/route";
import { useMediaQuery } from "../../../../../hooks";
const Breadcrumb = ({ id, main, singleDeals }) => {
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([]);
  const isMobile = useMediaQuery("(max-width:768px)");
  const token = getToken();
  const categories = useSelector((state) => state.categories.data);
  const { deals, totalDeals } = useSelector((state) => state.common_deals.data);
  const categoryPath = findCategoryPath(categories, id);
  const singleCategoryPath = categoryPath.length
    ? categoryPath[categoryPath.length - 1]
    : null;
  const dispatch = useDispatch();
  useEffect(() => {
    // Find the category path using the id
    // Map the category path to breadcrumb links
    const links = categoryPath.map((category, index) => ({
      label: category.name.replace(/&amp;/g, "&"), // Handle HTML entity
      href: `/${routes.dealsSlug}/${category.slug}`, // Generate dynamic URL (example structure)
      isActive: index === categoryPath.length - 1, // Last item is the active one
    }));

    setBreadcrumbLinks(links);
  }, [id, categories]);

  // useEffect(() => {
  //   console.log("singleCategoryPath", singleCategoryPath);
  //   if (token) {
  //     dispatch(getCategoriesList({ token }));
  //   }
  // }, []);

  useEffect(() => {
    // console.log("breadcrumbLinks", breadcrumbLinks);
  }, [breadcrumbLinks]);
  return (
    <>
      <div
        className={`w-full  items-center gap-[4px] ${
          isMobile ? "block" : "flex"
        }`}
      >
        {breadcrumbLinks.length > 0 ? (
          breadcrumbLinks.map((link, index) => (
            <React.Fragment key={index}>
              <a
                href={link.href}
                className={`text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal 
                ${
                  singleDeals
                    ? "text-neutral-500"
                    : link.isActive
                    ? "text-neutral-900 font-medium"
                    : "text-neutral-500"
                }`}
              >
                {link.label}
              </a>
              {singleDeals ? (
                <span className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal text-neutral-500">
                  /
                </span>
              ) : (
                index < breadcrumbLinks.length - 1 && (
                  <span className="text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] font-normal text-neutral-500">
                    /
                  </span>
                )
              )}
            </React.Fragment>
          ))
        ) : main ? (
          <span className="text-neutral-500"></span>
        ) : (
          <span className="text-neutral-500">No breadcrumb available</span>
        )}
        {singleDeals ? singleDeals.fldDealTitle : ""}
      </div>
      {!singleDeals && (
        <OfferHeaderComponent
          main={main}
          breadcrumbs={breadcrumbLinks}
          categoryPath={singleCategoryPath}
          id={id}
          count={totalDeals}
        />
      )}
    </>
  );
};

export default Breadcrumb;
