import babynormal from "./product/baby-normal.svg";
import baby from "./product/baby.svg";
import basket from "./product/basket.svg";
import car from "./product/car.svg";
import chevronright from "./product/chevron-right.svg";
import children from "./product/children.svg";
import computer from "./product/computer.svg";
import computing from "./product/computing.svg";
import consumerelectronics from "./product/consumer-electronics.svg";
import device from "./product/device.svg";
import food from "./product/food.svg";
import headphone from "./product/headphone.png";
import health from "./product/health.svg";
import homegarden from "./product/home-garden.svg";
import chevronDown from "../icons/chevron-down.svg";
import shirt from "./product/shirt.svg";
import leisure from "./product/leisure.svg";
import mobile from "./product/mobile-phones.svg";
import jwellery from "./product/jewelry.svg";
import office from "./product/office.svg";
import police from "./product/police.svg";
import sports from "./product/sports.svg";
import surplus from "./product/surplus.svg";
import toys from "./product/toys.svg";

export {
  mobile,
  babynormal,
  baby,
  basket,
  car,
  chevronright,
  children,
  computer,
  computing,
  consumerelectronics,
  device,
  food,
  headphone,
  health,
  homegarden,
  chevronDown,
  shirt,
  leisure,
  jwellery,
  office,
  police,
  sports,
  surplus,
  toys,
};
