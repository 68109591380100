import React from "react";

// constants
import { decodeHtmlEntities, getIcons } from "../constants";

export default function IconButton({
  tabName,
  iconOnly = false,
  classesForName,
}) {
  return (
    <div className="flex items-center space-x-2">
      {iconOnly ? (
        <span className={`${classesForName}`}>{getIcons(tabName)}</span>
      ) : (
        <>
          <span>{getIcons(tabName)}</span>
          <span className={`${classesForName}`}>
            {decodeHtmlEntities(tabName)}
          </span>
        </>
      )}
    </div>
  );
}
