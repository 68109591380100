import React, { useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigationType,
} from "react-router-dom";
import { AdminAuthRoutes, UnAuthRoutes, UserAuthRoutes } from "./routes";
import ErrorPage from "./components/pages/Error/ErrorPage";
import Spinner from "./assets/spinner/Spinner";
import Navigation from "./components/sharedComponent/Navigations";
import { useDispatch } from "react-redux";
import { getCountryList } from "./store/slices/countryDropdownSlice";
import { getLanguageList } from "./store/slices/languageDropdownSlice";
import { getCurrenciesList } from "./store/slices/currenciesDropdownSlice";
import { getRetailersList } from "./store/slices/commonDropDownSlice";
import { getCategoriesList } from "./store/slices/categoriesDropdownSlice";
import { getTestiMonials } from "./store/slices/commonhomepageSlice";
import { getAllTestiMonials } from "./store/slices/alltestimonialsSlice";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import {
  checkUserToken,
  getUserIdUsingGetApi,
} from "./store/api/validateToken";
import logout from "./_helperfunctions/Logout";
import "swiper/css";
import "swiper/css/pagination";
import { getUser } from "./store/slices/userSlice";
// import { getStats } from "./store/slices/statsSlice";
import ScrollToTop from "./components/sharedComponent/ScrollToTop/ScrollToTop";
import CookieConsentManager from "./components/sharedComponent/ManageCookies/CookieConsentManager";
import AnalyticsNoscript from "./components/sharedComponent/ManageCookies/AnalyticsNoscript";
//import OnExitPopup from "./components/sharedComponent/Modal/OnExitPopup";
//import ExitPopup from "./components/sharedComponent/Modal/ExitPopup";

const CustomScrollRestoration = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    const sectionId = sessionStorage.getItem("scrollToSection");
    if (navigationType === "POP" && sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
          // Clear the saved section after scrolling
          sessionStorage.removeItem("scrollToSection");
        }, 100);
      }
      return; // Exit so that we don't also use the saved scrollY
    }

    // Otherwise, for non-section scroll restoration, use scrollY if available.
    const key = `scrollPosition_${location.pathname}`;
    if (navigationType === "POP") {
      const savedValue = sessionStorage.getItem(key);
      if (savedValue) {
        // If savedValue is numeric, scroll to that position.
        const y = parseInt(savedValue, 10);
        if (!isNaN(y)) {
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    } else if (navigationType === "PUSH" || navigationType === "REPLACE") {
      sessionStorage.setItem(key, window.scrollY);
    }
  }, [location, navigationType]);

  return null;
};

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = Cookies.get("wd-team");

  const validateToken = async () => {
    const isTokenValid = await checkUserToken();
    if (!isTokenValid) {
      logout();
    }
  };

  useEffect(() => {
    if (token) {
      let userId;
      validateToken();
      getUserIdUsingGetApi()
        .then((res) => {
          userId = res.userId;
          if (userId) {
            dispatch(getUser({ userId, token }));
          }
        })
        .catch((error) => console.error(error));
    }
    // dispatch(getStats({ token }));
    dispatch(getCountryList());
    dispatch(getLanguageList());
    dispatch(getCurrenciesList());
    dispatch(getRetailersList());
    dispatch(getCategoriesList({ token }));
    dispatch(getTestiMonials());
    dispatch(getAllTestiMonials());
  }, [dispatch, token]);
  return (
    <Suspense fallback={<Spinner />}>
      {!location.pathname.includes("subscribe") &&
        !location.pathname.includes("for-sellers") &&
        !location.pathname.includes("for-buyers") &&
        !location.pathname.includes("join") &&
        !location.pathname.includes("/:referralType/:userId.html") && (
          <Navigation />
        )}
      <Routes>
        {/* Protected Admin Routes */}
        {AdminAuthRoutes}

        {/* Public Routes */}
        {UnAuthRoutes}

        {/* Protected User Routes */}
        {UserAuthRoutes}

        {/* Fallback Error Page */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {/* <OnExitPopup /> */}
      {/* <ExitPopup /> */}
    </Suspense>
  );
}

function InnerAppWrapper() {
  const location = useLocation();

  return (
    <>
      <CustomScrollRestoration />
      {!location.pathname.includes("/user/manage-services") && <ScrollToTop />}
      <App />
      <AnalyticsNoscript />
      <CookieConsentManager />
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <InnerAppWrapper />
    </Router>
  );
}

export default AppWrapper;
