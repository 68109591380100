import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETALLCATEGORIES } from "../api";
export const getCategoriesList = createAsyncThunk(
  "/getCategoriesList",
  async (data) => {
    let response = null;
    if (data.token) {
      response = await axios.get(GETALLCATEGORIES, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data;
    } else {
      response = await axios.get(GETALLCATEGORIES);
      return response.data;
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const categoriesDropdownSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesList.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCategoriesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategoriesList.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload;
      });
  },
});
export default categoriesDropdownSlice.reducer;
