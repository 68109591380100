import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { FAVORITE_DEALS } from "../api";
import logout from "../../shared/logout";
// Async thunk for fetching favorite deals
export const getFavoriteDeals = createAsyncThunk(
  "/getFavoriteDeals",
  async (data, { rejectWithValue }) => { // Destructure slug and token here
    try {
      if (data.dealId === "") {
        const response = await axios.get(`${ FAVORITE_DEALS }`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        return response.data;
      }
      else if(data.dealId !== ""){
        const response = await axios.get(`${FAVORITE_DEALS}?page=${data.dealId}`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });

        return response.data; // Ensure this is the expected format
      }
      } catch (error) {
       
        return rejectWithValue(
          error.response?.data || "Something went wrong while fetching stats."
        );
      }
    }
  );

// Define the initial state with `data` as an array
const initialState = {
  data: [], // Make sure data is an array
  isLoading: false,
  error: null,
};

const favoriteDealsSlice = createSlice({
  name: "favoriteDeals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFavoriteDeals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFavoriteDeals.fulfilled, (state, action) => {
        state.isLoading = false;
       
        state.data = action.payload.data; // Assign the fetched array directly
        
      })
      .addCase(getFavoriteDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to load deals.";
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default favoriteDealsSlice.reducer;
