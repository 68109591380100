export function getSlugFromUrl() {
  const urlPath = window.location.pathname; // Get the current path
  const segments = urlPath.split("/").filter(Boolean); // Split by '/' and filter empty segments
  return segments[segments.length - 1]; // Return the last segment as slug
}

export function timeRemainingFromNow(fldSaleEndDate) {
  const endDate = new Date(fldSaleEndDate);
  const now = new Date();

  if (isNaN(endDate.getTime())) {
    // If endDate is invalid, return all zero values
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }
  
  const diff = endDate - now;

  if (diff <= 0) {
    return "Expired";
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  //return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  return { days, hours, minutes, seconds };
}
