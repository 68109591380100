import React from "react";
import Card from "../Card/Card"; // Assuming Card is in the same directory or adjust the path accordingly
import { formatDate, getLowestPriceObject, trimArray } from "../../constant";
import medal from "../../../../../assets/images/v1/icons/medal.svg";
import chevronOrange from "../../../../../assets/images/v1/icons/chevron-orange.svg";
import { getProductingleImage } from "../../../../sharedComponent/ImageMaster/ImageMaster";
import { calculateProfitAndOrderUnits } from "../../../../sharedComponent/Calculation/Calculation";
import { useRedirection } from "../../../../../hooks";
const MostPopularOffers = ({ data }) => {
  const deals = data.length && trimArray(data, 4);
  const { redirectToDeals} = useRedirection();
  return (
    <div className="w-full bg-white phone:hidden">
      <div className="w-full max-w-[1440px] mx-auto pt-[72px] px-[56px] pb-[40px] tablet:pb-[68px] phone:py-[48px] phone:px-[24px]">
        <div className="w-full flex phone:flex-col phone:gap-[24px] phone:items-start items-center justify-between">
          <div className="w-fit flex items-center phone:items-start tablet:items-start gap-[12px]">
            <img
              src={medal}
              className="w-[23px] h-[32px] phone:w-[18px] phone:h-[24px]"
              alt="medal"
            />
            <h3 className="text-[32px] phone:text-[20px] phone:leading-[27px] tablet:text-[32px] tablet:leading-[40px] tablet:max-w-[430px] font-bold leading-[40px] text-neutral-900">
              Most Popular Offers
            </h3>
          </div>
          {/* <button
            className="w-fit flex phone:ml-[30px] items-center gap-[6px]"
            jf-ext-button-ct="explore all products"
            onClick={redirectToDeals}
          >
            <p className="text-[16px] leading-[24px] font-medium text-primary-main">
              Explore All Products
            </p>
            <img
              src={chevronOrange}
              className="w-[16px] h-[16px]"
              alt="chevron"
            />
          </button> */}
        </div>

        <div className="w-full phone:hidden grid tablet:grid-cols-2 grid-cols-4 gap-[20px] mt-[48px]">
          {deals && deals.length
            ? deals.map((deal, index) => (
                <Card
                  key={index}
                  image={
                    getProductingleImage(deal.fldDealImage1) 
                  }
                  inVat={getLowestPriceObject(deal.prices).fldIncVAT}
                  title={deal.fldDealTitle || "title na"}
                  price={getLowestPriceObject(deal.prices).fldPrice || "NA"}
                  markup={deal.fldDealMarkup || "markup na"}
                  rrp={deal.fldDealRRP || "deal.rrp"}
                  grossProfit={calculateProfitAndOrderUnits(deal)}
                  featuredDate={
                    formatDate(deal.fldDealCreationDate) || "deal.featuredOn"
                  }
                  units={deal.fldMinimumOrder}
                  prices={deal.prices}
                  fldDealCurrencyId={deal.fldDealCurrencyId}
                  comparison={
                    deal.samples && deal.samples.length && deal.samples
                  }
                  slug={deal.fldDealSlug}
                />
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default MostPopularOffers;
