import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { FAVORITE_DEALS } from "../api";

export const getFavoriteDeals = createAsyncThunk(
    "favoriteDeals/getFavoriteDeals",
    async (_, { rejectedWithValue }) =>{
        try {
            const response = await axios.get(FAVORITE_DEALS);
         return response.data.data;
        } catch (error){
            console.error("Error fetching favoriteDeals:", error);
            return rejectedWithValue(
                error.response?.data || "Something went wrong while fetching favoriteDeals."
            );
        }
    }
);

const initialState = {
    data: {},
    isLoading: false,
    error: null,
}

const favoriteDealsSlice = createSlice({
    name: "favorite_Deals",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
        .addCase(getFavoriteDeals.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(getFavoriteDeals.fulfilled, (state, action) => {
            // Handle the case when the request is successful
            state.isLoading = false;
            state.data = action.payload.data; // Store the fetched data
          })
          .addCase(getFavoriteDeals.rejected, (state, action) => {
            // Handle the case when the request fails
            state.isLoading = false;
            state.error = action.payload || "Failed to load deals.";
          });
    }
})
export default favoriteDealsSlice.reducer;
