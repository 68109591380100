export function calculateProfitAndOrderUnits(deal) {
    let profit;
    let minOrderUnits;
    if (deal.fldDealProfitMinOrderUnits < 100) {
      const multiplier = Math.ceil(100 / deal.fldDealProfitPerOrderUnits);
      minOrderUnits = getMultiPlier(multiplier); // Replace with your actual multiplier logic if needed
      profit = (minOrderUnits * deal.fldDealProfitPerOrderUnits).toFixed(2);
      return profit
    } else {
      profit = deal.fldDealProfitMinOrderUnits;
      minOrderUnits = deal.fldDealMinOrderUnits;
      return profit
    }
  }
  
  // Mock-up function for getMultiplier, replace this with actual implementation
  function getMultiPlier(no) {
    if (no <= 5) {
        return no;
    } 
    else if (no > 5 && no <= 8) {
        return 8;
    } 
    else if (no === 9) {
        return 10;
    } 
    else if (no >= 10 && no <= 36) {
        const mod = no % 12;

        if (mod === 0) {
            return no;
        } else {
            const mod1 = no % 5;

            if (mod1 === 0) {
                return no;
            } else {
                const nearestGreater12No = (no - mod) + 12;
                const nearestGreater5No = (no - mod1) + 5;

                return nearestGreater5No <= nearestGreater12No 
                    ? nearestGreater5No 
                    : nearestGreater12No;
            }
        }
    } 
    else {
        const mod = no % 24;

        if (mod === 0) {
            return no;
        } else {
            const mod1 = no % 5;

            if (mod1 === 0) {
                return no;
            } else {
                const nearestGreater24No = (no - mod) + 24;
                const nearestGreater5No = (no - mod1) + 5;

                return nearestGreater5No <= nearestGreater24No 
                    ? nearestGreater5No 
                    : nearestGreater24No;
            }
        }
    }
}
