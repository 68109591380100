import { routes } from "../../../routes/route";

export const getCategoryById = (categories, categoryId) => {
  let result = { parent: null, inner: null };

  // Helper function to recursively search through categories
  const searchCategories = (items, parent = null) => {
    for (let item of items) {
      if (item.id === categoryId) {
        result.inner = item; // Found the inner category
        result.parent = parent; // Set the parent if available
        return result;
      }

      // If children exist, search recursively
      if (item.children && item.children.length > 0) {
        const found = searchCategories(item.children, item); // Pass the current item as parent
        if (found && found.inner) {
          return found; // Return early if we found the category
        }
      }
    }
  };

  // Start the search
  searchCategories(categories);

  return result;
};

// Recursive function to find the category path
export const findCategoryPath = (categories, id) => {
  for (const category of categories) {
    // Base case: if the current category matches the id
    if (category.id == id) {
      return [category];
    }

    // Recursive case: check if this category has children
    if (category.children && category.children.length > 0) {
      const path = findCategoryPath(category.children, id);
      if (path.length > 0) {
        return [category, ...path]; // Prepend the current category to the path
      }
    }
  }
  return [];
};

export function trimArray(arr, count) {
  // Ensure that count is not greater than the array length
  if (count >= arr.length) {
    return arr;
  }

  // Trim the array to the specified count
  return arr.slice(0, count);
}

export function formatDate(inputDate) {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getLowestPriceObject(prices) {
  // Convert fldPrice to a float for accurate comparison and find the lowest price object
  if (prices.length === 1) {
    return prices[0];
  }
  if (prices.length > 0) {
    return prices.reduce((lowest, current) => {
      return parseFloat(current.fldPrice) < parseFloat(lowest.fldPrice)
        ? current
        : lowest;
    });
  } else {
    return 0;
  }
}

export const getIdBySlug = (categories, slug) => {
  // Recursive function to search through categories and their children
  const findId = (items) => {
    for (let item of items) {
      if (item.slug === slug) {
        return item.id;
      }
      if (item.children.length > 0) {
        const childId = findId(item.children);
        if (childId) {
          return childId;
        }
      }
    }
    return null; // Return null if no matching slug is found
  };

  return findId(categories);
};
export const getSupplierIdBySlug = (categories, slug) => {
  // Recursive function to search through categories and their children
  const findId = (items) => {
    for (let item of items) {
      if (item.slug === slug) {
        return item.id;
      }
      if (item.children.length > 0) {
        const childId = findId(item.children);
        if (childId) {
          return childId;
        }
      }
    }
    return null; // Return null if no matching slug is found
  };

  return findId(categories);
};

export const getSlugFromUrl = (url) => {
  try {
    // Create a URL object to parse the input URL
    const parsedUrl = new URL(url);
    // Split the pathname into segments and remove empty strings
    const pathSegments = parsedUrl.pathname.split("/").filter(Boolean);

    // Assuming the slug is the segment after "deals"
    const dealsIndex = pathSegments.indexOf(`${routes.dealsSlug}`);
    if (dealsIndex !== -1 && pathSegments.length > dealsIndex + 1) {
      return pathSegments[dealsIndex + 1]; // Return the segment after "deals" as the slug
    }

    return null; // Return null if no slug is found
  } catch (error) {
    // console.error("Invalid URL:", error);
    return null; // Return null in case of an invalid URL
  }
};
export const getSupplierSlugFromUrl = (url) => {
  try {
    // Create a URL object to parse the input URL
    const parsedUrl = new URL(url);
    // Split the pathname into segments and remove empty strings
    const pathSegments = parsedUrl.pathname.split("/").filter(Boolean);

    // Assuming the slug is the segment after "deals"
    const dealsIndex = pathSegments.indexOf(`${routes.supplier_category_slug}`);
    if (dealsIndex !== -1 && pathSegments.length > dealsIndex + 1) {
      return pathSegments[dealsIndex + 1]; // Return the segment after "deals" as the slug
    }

    return null; // Return null if no slug is found
  } catch (error) {
    // console.error("Invalid URL:", error);
    return null; // Return null in case of an invalid URL
  }
};

export function findCategoryByName(categories, name, main) {
  function searchCategory(categories, name, main, parent = null) {
    for (let category of categories) {
      // Ensure category.name is defined before comparing
      if (category.name && category.name === name) {
        // If category has no children, return the parent’s children if available
        if (category.children && category.children.length === 0 && parent) {
          return {
            name: parent.name,
            childrenName: category.name,
            children: parent.children,
          };
        }
        return category;
      }

      // If the main flag is true and we're at the top level, return all categories
      if (main && !parent) {
        return { name: "All Deals", children: categories };
      }

      // If the category has children, search through them recursively
      if (category.children && category.children.length > 0) {
        // console.log("category.children", category.children);
        const found = searchCategory(category.children, name, main, category);
        // console.log("found", found);
        if (found) {
          return found;
        }
      }
    }

    return null; // If no matching category is found
  }

  return searchCategory(categories, name, main);
}

export function joinStringArray(arr, separator = " ") {
  if (arr && arr.length) {
    return arr.join(separator);
  } else {
    return "";
  }
}

export const GetCurrentUrl = (location) => {
  const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
  return fullUrl;
};

// added calculation of gross profit need to check before implementation
function calculateProfitAndOrderUnits(deal) {
  let profit;
  let minOrderUnits;
  // console.log(
  //   "deal.fldDealProfitMinOrderUnits",
  //   deal.fldDealProfitMinOrderUnits
  // );

  if (deal.fldDealProfitMinOrderUnits < 100) {
    const multiplier = Math.ceil(100 / deal.fldDealProfitPerOrderUnits);
    minOrderUnits = getMultiplier(multiplier); // Replace with your actual multiplier logic if needed
    profit = (minOrderUnits * deal.fldDealProfitPerOrderUnits).toFixed(2);
  } else {
    profit = deal.fldDealProfitMinOrderUnits;
    minOrderUnits = deal.fldDealMinOrderUnits;
  }
  // console.log("profit", profit);
  // console.log("minOrderUnits", minOrderUnits);
  return null;
}

// Mock-up function for getMultiplier, replace this with actual implementation
function getMultiplier(multiplier) {
  return multiplier; // Adjust this as needed
}

export function trimText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
}

