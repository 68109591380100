import React from "react";

// components
import ShowFlag from "../../../../../sharedComponent/Flag/ShowFlag";

// store
import { useSelector } from "react-redux";
import { useRedirection } from "../../../../../../hooks";

const CompanyProfile = ({
  companyName,
  companyDescription,
  isVerified,
  country,
  countryIcon,
  city,
  postalCode,
  street,
  website,
  contactName,
  position,
  surname,
  phoneNumber,
}) => {
  const countryList = useSelector((state) => state.country.data);
  const { redirectToDeals } = useRedirection();
  return (
    <>
      <div className="w-full grid gap-[4px]">
        <h4 className="text-[18px] leading-[27px] font-semibold text-[#000]">
          {companyName}
        </h4>
        <p className="text-[14px] leading-[21px] font-normal text-neutral-500">
          {companyDescription}
        </p>
      </div>
      <div className="w-full mt-[16px] mb-[24px] flex items-center gap-[8px]">
        {isVerified && (
          <>
            <img
              src="../assets/images/v1/single/verified.svg"
              className="w-[20px] h-[20px]"
              alt="verified"
            />
            <p className="text-[14px] leading-[21px] font-medium text-additional-blue">
              Verified Seller
            </p>
          </>
        )}
      </div>
      <div className="w-full grid gap-[16px] relative">
        <p className="text-[12px] leading-[18px] text-neutral-500 z-20 font-medium py-[2px] px-[10px] rounded-full bg-neutral-200 w-fit">
          Address
        </p>
        <div className="w-full border-t border-t border-neutral-200 absolute top-[12px] left-0"></div>
        {country && (
          <div className="flex items-start justify-between w-full">
            <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
              Country:
            </p>
            <div className="flex items-center gap-[8px] w-fit">
              {/* <img
              src={countryIcon}
              className="w-[20px] h-[15px]"
              alt="country icon"
            />
            <p className="text-[14px] leading-[21px] font-semibold text-[#000]">
              {country}
            </p> */}
              <ShowFlag countryList={countryList} countryName={country} />{" "}
              {country}
            </div>
          </div>
        )}
        <div className="flex items-start justify-between w-full">
          <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
            City:
          </p>
          <p className="text-[14px] leading-[21px] font-semibold text-[#000]">
            {city}
          </p>
        </div>
        <div className="flex items-start justify-between w-full">
          <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
            Postal Code:
          </p>
          <p className="text-[14px] leading-[21px] font-semibold text-[#000]">
            {postalCode}
          </p>
        </div>
        <div className="flex items-start justify-between w-full">
          <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
            Street:
          </p>
          <p className="text-right text-[14px] leading-[21px] font-semibold text-[#000]">
            {street}
          </p>
        </div>
        <div className="flex items-start justify-between w-full">
          <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
            Website:
          </p>
          <p className="text-[14px] leading-[21px] font-semibold text-[#000]">
            {website}
          </p>
        </div>
      </div>

      <div className="w-full grid gap-[16px] mt-[24px] relative">
        <p className="text-[12px] leading-[18px] text-neutral-500 z-20 font-medium py-[2px] px-[10px] rounded-full bg-neutral-200 w-fit">
          Contact
        </p>
        <div className="w-full border-t border-t border-neutral-200 absolute top-[12px] left-0"></div>
        <div className="flex items-start justify-between w-full">
          <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
            Contact Name:
          </p>
          <p className="text-[14px] leading-[21px] font-semibold text-[#000]">
            {contactName}
          </p>
        </div>
        <div className="flex items-start justify-between w-full">
          <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
            Position:
          </p>
          <p className="text-[14px] leading-[21px] font-semibold text-[#000]">
            {position}
          </p>
        </div>
        <div className="flex items-start justify-between w-full">
          <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
            Surname:
          </p>
          <p className="text-[14px] leading-[21px] font-semibold text-[#000]">
            {surname}
          </p>
        </div>
        <div className="flex items-start justify-between w-full">
          <p className="text-[14px] leading-[21px] font-medium text-neutral-500">
            Phone Number:
          </p>
          <p className="text-[14px] text-right leading-[21px] font-semibold text-[#000]">
            {phoneNumber}
          </p>
        </div>
      </div>

      <div className="w-full grid gap-[8px] mt-[24px]">
        <a
          href="./single-supplier-login.html"
          className="w-full text-center rounded-[8px] bg-primary-surface py-[9.5px] text-primary-main hover:bg-primary-surface/50 transition-all duration-200 text-[14px] leading-[21px] font-semibold"
        >
          View Profile
        </a>
        <button
          onClick={() => redirectToDeals()}
          className="w-full text-center rounded-[8px] bg-primary-surface py-[9.5px] text-primary-main text-[14px] leading-[21px] hover:bg-primary-surface/50 transition-all duration-200 font-semibold"
        >
          View All Deals
        </button>
      </div>
    </>
  );
};

export default CompanyProfile;
