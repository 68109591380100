import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { FETCH_SUPPLIER_DEALS } from '../api';
import { getToken } from '../../hooks/cookies'; // ✅ Added this line

export const fetchSupplierDeals = createAsyncThunk(
  'deals/fetchSupplierDeals',
  async (supplierProfileID, thunkAPI) => {
    try {
      const token = getToken(); // ✅ Get token
      const response = await axios.get(`${FETCH_SUPPLIER_DEALS}${supplierProfileID}`, {
        headers: { Authorization: `Bearer ${token}` }, // ✅ Pass token in headers
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const supplierDealsSlice = createSlice({
  name: 'deals',
  initialState: {
    supplier: {},
    supplierCategories: [],
    deals: [],
    currentPage: 1,
    totalPages: 0,
    totalDeals: 0,
    itemsPerPage: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplierDeals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierDeals.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.supplier = action.payload.data.supplier;
          state.deals = action.payload.data.deals;
          state.supplierCategories = action.payload.data.supplierCategories;
          state.currentPage = action.payload.data.currentPage;
          state.totalPages = action.payload.data.totalPages;
          state.totalDeals = action.payload.data.totalDeals;
          state.itemsPerPage = action.payload.data.itemsPerPage;
        }
      })
      .addCase(fetchSupplierDeals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || 'Something went wrong';
        
      });
  },
});

export default supplierDealsSlice.reducer;
