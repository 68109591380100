import React from "react";

// components
import SoldOutTag from "../SoldOutTag/SoldOurTag";

const DealDetailHeading = ({ date, title, soldOut }) => {
  return (
    <>
      {soldOut ? <SoldOutTag /> : ""}
      <div className="w-full grid gap-[4px]">
        <p className="text-[16px] phone:text-[14px] phone:leading-[21px] leading-[24px] font-normal text-neutral-600">
          Deal First Featured On: {date}
        </p>
        <h3 className="text-[24px] phone:text-[18px] phone:leading-[24px] leading-[33.6px] font-bold text-[#333]">
          {title}
        </h3>
      </div>
    </>
  );
};

export default DealDetailHeading;
