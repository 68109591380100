import category from "./menu/category.svg";
import cookies from "./menu/cookies.svg";
import deals from "./menu/deals.svg";
import dropship from "./menu/dropship.svg";
import fingerprint from "./menu/fingerprint.svg";
import help from "./menu/help.svg";
import home from "./menu/home.svg";
import info from "./menu/info.svg";
import liquid from "./menu/liquid.svg";
import privacy from "./menu/privacy.svg";
import supplier from "./menu/supplier.svg";
import terms from "./menu/terms.svg";
import amazon from "./amazon.svg"
import close from "./close.svg"
import count from "./count.svg"
import discounttablet from "./discount-tablet.png"
import discounttablet2 from "./discount-tablet.svg"
import discount from "./discount.png"
import discount2 from "./discount.svg"
import ebay from "./ebay.svg"
import flashtablet from "./flash-tablet.svg"
import flashtablet2 from "./flash-tablet.png"
import flashsaledesktop from "./flashsale-desktop.png"
import flashsaledesktop2 from "./flashsale-desktop.svg"
import hamburgermenu from "./hamburger-menu.svg"
import hamburger from "./hamburger.svg"
import harddisk from "./harddisk.png"
import packmobile from "./pack-mobile.svg"
import search from "./search.svg"
import seoimage1 from "./seo-image1.svg"
import seoimage2 from "./seo-image2.svg"
import shoes from "./shoes.png"
import shoes1 from "./shoes1.png"
import shoes2 from "./shoes2.png"
import tablettopbanner from "./tablet-topbanner.svg"
import tickertablet from "./ticker-tablet.svg"
import wrapper from "./wrapper.svg"
export {
  category,
  cookies,
  deals,
  dropship,
  fingerprint,
  help,
  home,
  info,
  liquid,
  privacy,
  supplier,
  terms,
  amazon,
  close,
  count,
  discount,
  discount2,
  discounttablet,
  discounttablet2,
  ebay,
  flashtablet,
  flashtablet2,
  flashsaledesktop,
  flashsaledesktop2,
  hamburger,
  harddisk,
  packmobile,
  search,
  seoimage1,
  seoimage2,
  shoes,
  shoes1,
  shoes2,
  tablettopbanner,
  tickertablet,
  wrapper,
  hamburgermenu
};
