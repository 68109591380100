import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_ORDERS_MESSAGES } from "../api";
import logout from "../../_helperfunctions/Logout";
export const getOrderMessages = createAsyncThunk(
  "order/getOrderMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ORDERS_MESSAGES}/${data.orderId}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const orderMessagesSlice = createSlice({
  name: "orderMessages",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderMessages.pending, (state) => {
        state.isLoading = true;
        state.data = []; // <-- clear previous messages
      })
      .addCase(getOrderMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getOrderMessages.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default orderMessagesSlice.reducer;
