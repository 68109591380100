import React, { useEffect, useState } from "react";

// styles
import "./ProductCard.css";

// image constant
import { getProductingleImage } from "../../../../../sharedComponent/ImageMaster/ImageMaster";

const ProductCard = ({
  markupPercentage,
  mainImage,
  badgeSrc,
  thumbnails,
  soldOut,
}) => {
  const [selectedImage, setSelectedImage] = useState(mainImage);

  useEffect(() => {
    setSelectedImage(mainImage);
  }, [mainImage]);
  return (
    <>
      {soldOut && (
        <p
          class="text-[16px] leading-[24px] font-bold text-white top-7 left-[190px] py-[4px] px-[10px] rounded-[8px] bg-secondary-main grayscale-none relative w-[90px]"
          style={{ zIndex: 1 }}
        >
          Sold Out
        </p>
      )}
      <div
        className={`w-full phone:p-[28px] p-[14px] bg-[#FAFAFA] relative  ${
          soldOut ? "grayscale -top-8" : "top-0"
        }`}
      >
        <div className="w-[68px] h-[69px] absolute left-[16px] top-0 py-[8px] px-[6px]">
          <img
            src={badgeSrc}
            className="w-[68px] h-[69px] absolute top-0"
            alt="badge"
          />
          <p className="text-[12px] leading-[18px] pt-[4px] font-semibold text-white text-center absolute z-10 top-0 left-[12px]">
            %Markup
          </p>
          <h1 className="font-bold absolute z-10 top-[16px] pt-[5px] right-0 text-secondary-main text-[16px] leading-[24px] text-center">
            {markupPercentage}%
          </h1>
        </div>
        <div className="previewImageContainer w-full mx-auto phone:max-w-[224px] phone:h-[224px] h-[300px]">
          <img
            src={getProductingleImage(selectedImage)}
            alt=""
            className="w-full h-full"
          />
        </div>
      </div>
      <div
        className={`w-full mt-[16px] grid grid-cols-4 phone:gap-[8px] gap-[16px]`}
      >
        {thumbnails.map((thumbnail, index) => (
          <button
            key={index}
            onClick={() => setSelectedImage(thumbnail.src)}
            className={`imgThumbnailContainer w-full p-[7px] bg-[#FAFAFA] flex items-center justify-center ${
              selectedImage === thumbnail.src ? "custom-background-border" : ""
            } ${soldOut ? "grayscale" : ""}`}
          >
            <img
              src={getProductingleImage(thumbnail.src)}
              className="w-[56px] h-[56px]"
              alt="thumbnail"
            />
          </button>
        ))}
      </div>
    </>
  );
};

export default ProductCard;
