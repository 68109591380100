import React, { useEffect, useRef, useState } from "react";
import chevronDown from "../../../assets/images/v1/icons/chevron-down.svg";
import chevronUp from "../../../assets/icons/chevron-up.png";
import { useRedirection } from "../../../hooks";
import { useNavigate } from "react-router-dom";

const SellerDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { redirectoForSellers } = useRedirection(); // updated function name
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const RedirectToJoin = () => {
    setIsOpen(false);
    redirectoForSellers(); // call new redirection function for sellers
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const redirectToGetListed = () => {
    navigate("/contact-us#contact-form", {
      state: {
        queryType: "Listing My Company",
        subject: "Request to get listed on Wholesale Deals as a supplier",
        source: "getListed",
      },
    });
    setIsOpen(false);
  };

  return (
    <div className="relative select-dropdown-container" ref={dropdownRef}>
      <button
        className="forseller px-[6px] py-[3px] rounded-[6px] transition-all duration-300 flex items-center gap-[6px]"
        onClick={toggleDropdown}
      >
        <p className="font-normal text-[14px] leading-[21px] text-neutral-800">
          For sellers
        </p>
        <img
          src={isOpen ? chevronUp : chevronDown}
          className={`tablet:w-[16px] tablet:h-[16px] transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
          width="16px"
          height="16px"
          alt="Chevron"
        />
      </button>
      {isOpen && (
        <div className="w-[136px] flex flex-col absolute mt-[7px] left-0 p-[4px] bg-white forseller-modal select-dropdown">
          {/* Supplier Benefits */}
          <a
            href="/for-sellers"
            onClick={(e) => {
              e.preventDefault();
              RedirectToJoin();
            }}
            className="supplier-benefit transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left pl-2 pt-1.5 hover:bg-[#FAF1DC]"
          >
            Supplier Benefits
          </a>
          {/* Get Listed */}
          <a
            href="/contact-us#contact-form"
            onClick={(e) => {
              e.preventDefault();
              redirectToGetListed();
            }}
            className="get-listed transition-all duration-300 w-full h-[32px] font-medium text-[14px] leading-[21px] text-neutral-800 text-left pl-2 pt-1.5 hover:bg-[#FAF1DC]"
          >
            Get Listed
          </a>
        </div>
      )}
    </div>
  );
};

export default SellerDropdown;
