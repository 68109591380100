import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETCOUNTRYLIST } from "../api";
export const getCountryList = createAsyncThunk("/getCountryList", async () => {
  const response = await axios.get(GETCOUNTRYLIST);
  return response.data;
});

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const countryDropdownSlice = createSlice({
  name: "country",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCountryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
      });
  },
});
export default countryDropdownSlice.reducer;
