import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETSUPPLIERS_CATEGORY } from "../api";

export const getCommonSupplierCategory = createAsyncThunk(
  "/getCommonSupplierCategory",
  async (data, { rejectWithValue }) => {
    try {
      // Prepare query parameters for search, searchMode, and sortBy
      const params = new URLSearchParams();

    //   if (data.page) params.append("page", data.page);

      if (Array.isArray(data.search) && data.search.length) {
        params.append("search", data.search.join(","));
      }
      if (data.searchMode) params.append("searchMode", data.searchMode);
      if (data.sortBy) params.append("sortBy", data.sortBy);
      if (data.dealId) params.append("category", data.dealId);
      if (Array.isArray(data.country) && data.country.length) {
        params.append("country", data.country.join(","));
      }
      if (
        Array.isArray(data.business_type) &&
        data.business_type.length
      ) {
        params.append("business_type", data.business_type.join(","));
      }

      const queryString = params.toString() ? `?${params.toString()}` : "";
      // Fetch deals based on the dealId and other parameters
      const response = data.token
        ? await axios.get(`${GETSUPPLIERS_CATEGORY}${queryString}`, {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          })
        : await axios.get(`${GETSUPPLIERS_CATEGORY}${queryString}`);

      // Handle potential empty response
      if (!response.data) {
        return rejectWithValue("No deals found.");
      }

      return response.data; // Ensure this is the expected format
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the deal profile."
      );
    }
  }
);

const initialState = {
  data: {},
  isLoading: true,
  currentPage: 1,
  itemsPerPage: 10,
  totalPage: 0,
  error: null,
};

const supplierCategorySlice = createSlice({
  name: "supplierCategory",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommonSupplierCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCommonSupplierCategory.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getCommonSupplierCategory.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setPage, setError } = supplierCategorySlice.actions;
export default supplierCategorySlice.reducer;
