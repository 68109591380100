import React, { useEffect } from "react";

// components
import TestimonialsHeader from "./TestimonialsHeader";
import TestimonialSlider from "./TestimonialSlider";
import TestimonialGrid from "./TestimonialGrid";

// custom hooks
import { useMediaQuery } from "../../../../../../hooks";

const TestimonialsSection = ({ testimonials }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  useEffect(() => {
    // console.log("isMobile", isMobile);
  }, [isMobile]);

  return (
    <div className="w-full bg-white">
      <div className="w-full max-w-[1440px] mx-auto px-[56px] py-[40px] phone:px-[24px] phone:py-[32px]">
        <div className="w-full bg-[#FAFAFA] p-[48px] phone:p-[16px] rounded-[24px]">
          <TestimonialsHeader />
          {isMobile ? (
            <TestimonialSlider testimonials={testimonials} />
          ) : (
            <TestimonialGrid testimonials={testimonials} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
