import React, { useEffect, useState } from "react";
import eyeoff from "../../../../../assets/images/v1/deals/eyeoff.svg";
import heart from "../../../../../assets/images/v1/icons/heart-rounded.svg";
import liked from "../../../../../assets/images/v1/deals/heart-rounded-liked.svg";
import Modal from "../Modal/Modal";
import LoginForm from "../LoginForm/LoginForm";
import LikeForm from "../LikeForm/LikeForm";
import { getToken } from "../../../../../hooks/cookies";
import { useDispatch, useSelector } from "react-redux";
import { onUnLikeClick } from "../../../../../store/api/likeDeals";
import { onHideClick } from "../../../../../store/api/hideShowDeals";
import { getCommonDeals } from "../../../../../store/slices/commonDealsSlice";
import { useLocation } from "react-router-dom";
import amazon from "../../../../../assets/images/website/amazon.png";
import ebay from "../../../../../assets/images/website/ebay.png";
import { useMediaQuery, useRedirection } from "../../../../../hooks";
import { trimText } from "../../constant";
import {
  getCurrencyCode,
  getCurrencySymbol,
} from "../../../../../store/slices/currencySlice";
import { getConvertedRate } from "../../../../sharedComponent/Calculation/CurrencyCalculation";
const Card = ({
  image,
  title,
  price,
  prices,
  markup,
  rrp,
  inVat,
  grossProfit,
  featuredDate,
  comparison,
  units,
  likeButtonShow,
  like,
  id,
  categoryId,
  fldDealCurrencyId,
  slug,
}) => {
  const currencySymbol = useSelector(getCurrencySymbol);
  const currencyCode = useSelector(getCurrencyCode);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(like && like.length);
  const isToken = getToken();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const dispatch = useDispatch();
  const { redirectoDeal } = useRedirection();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)"); // Define mobile breakpoint)
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const token = getToken();
  const initialSearchMode = queryParams.get("searchMode") || "";
  const initialSortBy = queryParams.get("sortBy") || "";
  const initialSearchTerm = queryParams.get("search")
    ? queryParams.get("search").split(",")
    : [];
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchMode, setSearchMode] = useState(initialSearchMode);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const currencies = useSelector((state) => state.currencies.data);
  const { deals, isLoading, totalDeals } = useSelector(
    (state) => state.common_deals.data
  );
  const initialCountry = queryParams.get("country")
    ? queryParams.get("country").split(",").map(Number)
    : [];
  const initialGrade = queryParams.get("product_condition")
    ? queryParams.get("product_condition").split(",")
    : [];
  const [selectedGradeOptions, setSelectedGradeOptions] =
    useState(initialGrade);
  const [selectedCountries, setSelectedCountries] = useState(initialCountry);
  const itemsPerPage = 10;
  const findCurrency = (currency) => {
    return currencySymbol;
  };
  const findLowestPrice = (data) => {
    return data.reduce(
      (min, item) => Math.min(min, parseFloat(item.fldPrice)),
      Infinity
    );
  };
  useEffect(() => {
    if (like && like.length) {
      setIsLiked(like.length);
    }
  }, [like]);
  const dispatchCommonDeals = () => {
    const dealIdToUse = categoryId ? categoryId : "";
    dispatch(
      getCommonDeals({
        dealId: dealIdToUse,
        page: `${currentPage}`,
        search: searchTerm,
        searchMode,
        sortBy,
        country: selectedCountries,
        product_condition: selectedGradeOptions,
        token,
      })
    );
  };

  // Optimized unLike function
  const unLike = () => {
    onUnLikeClick(id).then(() => {
      setIsLiked(false);
      dispatchCommonDeals();
    });
  };

  // Optimized hideCard function
  const hideCard = () => {
    onHideClick({ dealId: id }).then(() => {
      dispatchCommonDeals();
    });
  };
  const retailer = useSelector((item) => item.common.data);

  const getDealImageAlt = (retailerID) => {
    const retailerdata = retailer.filter(
      (item) => item.fldRetailerId == retailerID
    );
    return retailerdata.map((item) => item.fldRetailerName);
  };

  const getDealImage = (retailerID) => {
    const retailerdata = retailer.filter(
      (item) => item.fldRetailerId == retailerID
    );
    if (
      retailerdata.map((item) => item.fldRetailerName.toLowerCase()) == "amazon"
    ) {
      return amazon;
    } else if (
      retailerdata.map((item) => item.fldRetailerName.toLowerCase()) == "ebay"
    ) {
      return ebay;
    }
  };

  function getSingleUnitList(value) {
    if (value[0] == "Per Piece") {
      return "Pieces";
    } else if (value[0] == "Per Pair") {
      return "Pairs";
    } else if (value[0] == "Per Box") {
      return "Boxes";
    } else if (value[0] == "Per Pallet") {
      return "Pallets";
    } else if (value[0] == "Per Lot") {
      return "Lots";
    } else if (value[0] == "Per Kilogram") {
      return "Kilograms";
    }
  }
  const onSingleDeal = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    redirectoDeal(slug);
  };
  return (
    <div className="w-full bg-white border border-neutral-200 rounded-xl overflow-hidden flex flex-col">
      {/* Badge Section */}
      <div
        className="relative top-0 p-4 pt-0 w-10"
        style={{
          width: "70px",
          height: "69px",
          backgroundImage:
            "url(../../../../../assets/images/v1/icons/badge.svg)",
          backgroundRepeat: "no-repeat",
          marginLeft: "20px",
        }}
      >
        {/* <img
          src="../../../../../assets/images/v1/icons/badge.svg"
          className="w-16 h-16 absolute"
          alt="badge"
        /> */}
        <p className=" text-[12px] leading-[18px] font-semibold text-white text-center absolute top-0  pt-[6px] left-2">
          %Markup
        </p>
        <h1 className="absolute top-6 left-[1px] text-secondary-main font-bold text-[16px] flex justify-center w-[100%]">
          {/* font-bold absolute z-10 pt-[6px] top-[16px] right-0 text-secondary-main text-[16px] leading-[24px] text-center */}
          {markup}%
        </h1>
      </div>

      {/* Product Image */}
      {/* <div className="w-full">
        <a href="#" className={isMobile ? "flex justify-center" : ""}>
          <img
            src={image}
            alt="product"
            className={
              isMobile ? "w-[120px] h-[110px]" : "w-full h-[309px] p-4"
            }
          />
        </a>
      </div> */}
      <div className="w-full px-[20px] py-[8px] mt-[16px] mr-[16px]">
        <button onClick={onSingleDeal} className="w-full h-auto">
          <img
            src={image}
            className={"w-full h-48 object-contain rounded-[16px] mx-auto"}
            alt="product"
          />
        </button>
      </div>

      {/* Product Info */}
      <div className="p-4">
        <div className="flex items-center justify-between">
          <p className="text-[20px] phone:text-[16px] phone:leading-[24px] font-bold leading-[27px] text-secondary-main">
            {findCurrency(fldDealCurrencyId)}

            {getConvertedRate(price, currencyCode)}
            <span className="font-normal phone:text-[12px] phone:leading-[18px] text-[14px] leading-[21px] text-neutral-500">
              {inVat ? "inc.Vat " : "ex.VAT"}
            </span>
          </p>
          {!isMobile && likeButtonShow ? (
            <div className="flex items-center gap-4">
              <button
                className="relative"
                onClick={isToken ? hideCard : openModal}
              >
                <img src={eyeoff} alt="Hide" className="w-6 h-6" />
              </button>
              {isLiked ? (
                <button className="relative" onClick={unLike}>
                  <img src={liked} alt="Like" className="w-6 h-6" />
                </button>
              ) : (
                <button className="relative" onClick={openModal}>
                  <img src={heart} alt="Like" className="w-6 h-6" />
                </button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <p className=" text-[12px] font-normal leading-[18px] text-neutral-600 my-[4px]">
          Deal First Featured On: {featuredDate}
        </p>

        <button
          onClick={onSingleDeal}
          className="font-bold text-md  text-left text-[14px] leading-[21px] text-neutral-900 block hover:text-[#0355A5] transition-all duration-300 line-clamp-2 overflow-hidden"
        >
          {isMobile ? trimText(title, 25) : title}
        </button>

        {isMobile ? (
          ""
        ) : (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <p className="text-xs text-neutral-600">RRP</p>
              <h4 className="font-bold text-sm text-secondary-main">
                {findCurrency(fldDealCurrencyId)}

                {getConvertedRate(rrp, currencyCode)}
              </h4>
            </div>
            <div>
              <p className="text-xs text-neutral-600">Gross Profit</p>
              <h4 className="font-bold text-sm text-secondary-main">
                {findCurrency(fldDealCurrencyId)}
                {getConvertedRate(grossProfit, currencyCode)}
                <span className="text-neutral-600">
                  / {units}{" "}
                  {prices &&
                    prices.length &&
                    getSingleUnitList(prices.map((item) => item.fldPriceType))}
                </span>
              </h4>
            </div>
          </div>
        )}

        {/* Comparison Section */}
        {!isMobile && comparison && comparison.length && (
          <div className="mt-4 bg-neutral-50 rounded-lg p-3">
            <p className="text-xs text-neutral-600 border-b pb-3">
              Compare with other platform
            </p>

            {comparison &&
              comparison.length &&
              comparison.map((platform, index) => (
                <div
                  className="flex items-center justify-between py-3 border-b"
                  key={index}
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={getDealImage(platform.fldRetailerId)}
                      alt={getDealImageAlt(platform.name)}
                      className="w-8 h-8"
                    />
                    <div>
                      <h4 className="font-bold text-sm text-neutral-900">
                        ${platform.fldDealSamplePrice}
                      </h4>
                      <p className="text-xs text-neutral-500">exc.VAT</p>
                    </div>
                  </div>
                  <a
                    className="text-sm font-semibold text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={platform.fldDealSampleLink || "#"}
                  >
                    Verify
                  </a>
                </div>
              ))}
          </div>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        // title="Edit Plan"
        customWidth={isToken ? "desktop:40vw" : ""}
      >
        {isToken ? (
          <LikeForm
            id={id}
            closeModal={closeModal}
            dispatchCommonDeals={dispatchCommonDeals}
          />
        ) : (
          <LoginForm setIsModalOpen={setIsModalOpen} />
        )}
      </Modal>
    </div>
  );
};

export default Card;
