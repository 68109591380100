import React, { useState } from "react";

// Star SVG components
const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      fill="none"
      stroke="#ccc"
      strokeWidth="2"
      d="M12 2L9.1 8.63L2 9.27l5.46 4.73L5.82 21L12 17.77L18.18 21l-1.64-6.99L22 9.27l-7.1-.64L12 2z"
    />
  </svg>
);

const StarSelectedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      fill="gold"
      stroke="gold"
      strokeWidth="2"
      d="M12 2L9.1 8.63L2 9.27l5.46 4.73L5.82 21L12 17.77L18.18 21l-1.64-6.99L22 9.27l-7.1-.64L12 2z"
    />
  </svg>
);

const StarRating = ({ rating, setRating }) => {
  // To track the user's rating
  const [hover, setHover] = useState(0); // To track hover state

  return (
    <div className="flex">
      {[1, 2, 3, 4, 5].map((value) => (
        <span
          key={value}
          onClick={() => setRating(value)} // Set the clicked rating
          onMouseEnter={() => setHover(value)} // Highlight stars on hover
          onMouseLeave={() => setHover(0)} // Remove highlight on mouse leave
        >
          {value <= (hover || rating) ? <StarSelectedIcon /> : <StarIcon />}
        </span>
      ))}
    </div>
  );
};

export default StarRating;
