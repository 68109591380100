import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SCROLLER_DEALS } from "../api";
import Cookies from "js-cookie";

// Modify the thunk to accept an optional `id`
export const getScrollerDeals = createAsyncThunk(
  "scroller_deals/getScrollerDeals",
  async ({ id } = {}, { rejectWithValue }) => {
    const getToken = Cookies.get("wd-team");
    try {
      const config = {
        headers: {},
        params: {},
      };
      
      if (getToken) {
        config.headers.Authorization = `Bearer ${getToken}`;
      }

      if (id) {
        // Assuming your API expects `categoryId` as a query parameter
        config.params.categoryId = id;
      }
      const response = await axios.get(`${SCROLLER_DEALS}`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch scroller deals.");
    }
  }
);

const initialState = {
  data: {},
  isLoading: true,
  currentPage: 1,
  itemsPerPage: 10,
  totalPage: 0,
  error: null,
};

const scrollerDealsSlice = createSlice({
  name: "scroller_deals",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScrollerDeals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getScrollerDeals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getScrollerDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to load scroller deals.";
      });
  },
});

// Exporting the action creators and the reducer
export const { setPage } = scrollerDealsSlice.actions;

export default scrollerDealsSlice.reducer;
