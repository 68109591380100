import React, { useState } from "react";
import { saveCookiePreferences } from "../../../hooks/cookies"; // adjust path as needed

const ManageCookieModel = ({ onClose }) => {

  const [marketingCookies, setMarketingCookies] = useState(true);
  const [personalisationCookies, setPersonalisationCookies] = useState(true);
  const [analyticsCookies, setAnalyticsCookies] = useState(true);

  const handleMarketingToggle = () => {
    setMarketingCookies((prev) => !prev);
  };

  const handlePersonalisationToggle = () => {
    setPersonalisationCookies((prev) => !prev);
  };

  const handleAnalyticsToggle = () => {
    setAnalyticsCookies((prev) => !prev);
  };

  // "Allow All" button: sabhi optional cookies ko true karein
  const handleAllowAll = () => {
    setMarketingCookies(true);
    setPersonalisationCookies(true);
    setAnalyticsCookies(true);
    saveCookiePreferences({
      marketing: true,
      personalisation: true,
      analytics: true,
    });
    onClose();
  };

  // "Save Settings" button: user ke toggle ke hisaab se save karein
  const handleSaveSetting = () => {
    saveCookiePreferences({
      marketing: marketingCookies,
      personalisation: personalisationCookies,
      analytics: analyticsCookies,
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50">
      <div className="w-full phone:max-w-[327px] max-w-[488px] phone:ml-0 z-[20] tablet:ml-auto ml-auto grid gap-[24px] phone:gap-[20px] absolute bg-white phone:rounded-[12px] rounded-[20px] phone:py-[24px] phone:px-[16px] p-[32px]">
        {/* Header */}
        <div className="w-full rounded-t-[20px] flex items-center justify-between">
          <h1 className="text-[20px] font-semibold leading-[27px] text-neutral-800">
            🍪 Manage cookies
          </h1>
          <button className="btn-close-manage-cookies" onClick={onClose}>
            <img
              src="../assets/icons/x.svg"
              className="w-[24px] h-[24px]"
              alt="close"
            />
          </button>
        </div>

        {/* Cookie Options */}
        <div className="w-full grid phone:gap-[12px] gap-[20px]">
          {/* Essential cookies – always allowed */}
          <div className="flex items-center justify-between w-full">
            <p className="text-neutral-700 text-[16px] phone:text-[14px] phone:leading-[21px] leading-[24px] font-semibold">
              Essential cookies
            </p>
            <p className="text-primary-main text-[16px] leading-[24px] font-bold">
              Required
            </p>
          </div>

          {/* Marketing cookies */}
          <div className="flex items-center justify-between w-full">
            <p className="text-neutral-700 text-[16px] phone:text-[14px] phone:leading-[21px] leading-[24px] font-semibold">
              Marketing cookies
            </p>
            <div className="w-fit flex items-center ml-auto gap-[8px]">
              <p className="font-medium text-right text-neutral-500">
                {marketingCookies ? "Allow" : "Don't Allow"}
              </p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={marketingCookies}
                  onChange={handleMarketingToggle}
                  className="hidden"
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>

          {/* Personalisation cookies */}
          <div className="flex items-center justify-between w-full">
            <p className="text-neutral-700 text-[16px] phone:text-[14px] phone:leading-[21px] leading-[24px] font-semibold">
              Personalisation cookies
            </p>
            <div className="w-fit flex items-center ml-auto gap-[8px]">
              <p className="font-medium text-right text-neutral-500">
                {personalisationCookies ? "Allow" : "Don't Allow"}
              </p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={personalisationCookies}
                  onChange={handlePersonalisationToggle}
                  className="hidden"
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>

          {/* Analytics cookies */}
          <div className="flex items-center justify-between w-full">
            <p className="text-neutral-700 text-[16px] phone:text-[14px] phone:leading-[21px] leading-[24px] font-semibold">
              Analytics cookies
            </p>
            <div className="w-fit flex items-center ml-auto gap-[8px]">
              <p className="font-medium text-right text-neutral-500">
                {analyticsCookies ? "Allow" : "Don't Allow"}
              </p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={analyticsCookies}
                  onChange={handleAnalyticsToggle}
                  className="hidden"
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>

        {/* Footer buttons */}
        <div className="w-full flex phone:flex-col justify-center items-center gap-[20px] phone:mt-[4px] phone:gap-[12px]">
          <button
            className="w-full allow-all-cookie py-[12px] px-[20px] rounded-[8px] bg-primary-surface hover:bg-primary-surface/50 transition-all duration-200 flex items-center justify-center gap-[8px]"
            onClick={handleAllowAll}
          >
            <img
              src="../assets/icons/cookie-orange.svg"
              width="20"
              height="20"
              alt="allow all"
            />
            <p className="text-[16px] leading-[24px] font-medium text-primary-main">
              Allow all Cookies
            </p>
          </button>
          <button
            className="w-full save-setting-cookie py-[12px] px-[20px] rounded-[8px] bg-primary-main hover:bg-primary-hover transition-all duration-200 flex justify-center items-center gap-[8px]"
            onClick={handleSaveSetting}
          >
            <img
              src="../assets/icons/checkwhite.svg"
              width="20"
              height="20"
              alt="save"
            />
            <p className="text-[16px] leading-[24px] font-medium text-white">
              Save Settings
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageCookieModel;
