import React, { useState, useEffect } from "react";
import heartroundedlikedhover from "../../../../../assets/images/v1/single/heart-rounded-liked-hover.svg";
import heartRounded from "../../../../../assets/images/v1/icons/heart-rounded.svg";
import Modal from "../Modal/Modal";
import LoginForm from "../LoginForm/LoginForm";
import { getToken } from "../../../../../hooks/cookies";
import CategoryLikeForm from "../LikeForm/CategoryLikeForm";
import { onUnLikeCategories } from "../../../../../store/api/likeUnlikeCategories";
import { useMediaQuery } from "../../../../../hooks";
import { trimText } from "../../constant";

const OfferHeaderComponent = ({
  main,
  breadcrumbs,
  count,
  categoryPath,
  id,
}) => {
  const [liked, setLiked] = useState(categoryPath && categoryPath.liked);
  const isMobile = useMediaQuery("(max-width: 768px)"); // Define mobile breakpoint)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const isToken = getToken();
  const handleLikeToggle = () => {
    setLiked(!liked);
  };
  useEffect(() => {
    // console.log("categoryPath", categoryPath);
    // console.log("id", id);
    // console.log("categoryPath", categoryPath);
    categoryPath && setLiked(categoryPath.liked);
    // console.log("categoryPath", categoryPath);
  }, [categoryPath, breadcrumbs, id]);

  function createHeadingFromBreadcrumbs(breadcrumbs) {
    // Extract labels from the breadcrumb array and join them with commas
    if (breadcrumbs && breadcrumbs.length) {
      return breadcrumbs.map((crumb) => crumb.label).join(", ");
    }
    return [];
  }
  const dispatchCommonDeals = () => {};

  const unLike = () => {
    onUnLikeCategories(id).then(() => {
      handleLikeToggle();
    });
  };

  const heading = createHeadingFromBreadcrumbs(breadcrumbs);
  return (
    <div className="w-full flex phone:flex-col phone:items-start items-center gap-[8px] my-[12px]">
      <div className="flex items-center gap-[8px]">
        <div className="relative flex justify-center block">
          {/* Popover */}
          <div className="popover z-[10] hidden absolute top-[-28px] rounded-[8px] bg-[#475569] whitespace-nowrap h-[28px] flex items-center px-[10px]">
            <span className="popover-text text-[12px] leading-[18px] tracking-[0.4px] text-white"></span>
          </div>
          {/* Like Button */}
          {main ? (
            ""
          ) : (
            <div className="flex items-center gap-4">
              {liked ? (
                <button className="relative" onClick={unLike}>
                  <img
                    src={heartroundedlikedhover}
                    alt="Like"
                    className="w-6 h-6 mr-2"
                  />
                </button>
              ) : (
                <button className="relative" onClick={openModal}>
                  <img src={heartRounded} alt="Like" className="w-6 h-6 mr-2" />
                </button>
              )}
            </div>
          )}

          {/* Title */}
          <h3 className="text-[24px] leading-[33.6px] phone:text-[20px] phone:leading-[27px] font-bold text-neutral-900">
            {main ? "All Deals" : isMobile ? trimText(heading, 25) : heading}
          </h3>
        </div>
        {/* Offer Count */}

        {!isMobile && count ? (
          <p className="text-neutral-500 text-[18px] leading-[27px] phone:text-[14px] phone:leading-[21px] font-medium">
            ({count} offers)
          </p>
        ) : (
          ""
        )}
      </div>
      {isMobile && count ? (
        <p className="text-neutral-500 text-[18px] leading-[27px] phone:text-[14px] phone:leading-[21px] font-medium">
          ({count} offers)
        </p>
      ) : (
        ""
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        // title="Edit Plan"
        customWidth={isToken ? "desktop:40vw" : ""}
      >
        {isToken ? (
          <CategoryLikeForm
            id={id}
            closeModal={closeModal}
            dispatchCommonDeals={dispatchCommonDeals}
          />
        ) : (
          <LoginForm setIsModalOpen={setIsModalOpen} />
        )}
      </Modal>
    </div>
  );
};

export default OfferHeaderComponent;
