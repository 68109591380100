import React from "react";

const DealsSearchHeading = ({ dealsCount, keyword }) => {
  const lastKeyword = Array.isArray(keyword) ? keyword[keyword.length - 1] : keyword;
  return (
    <h1 className="font-bold my-[24px] text-[16px] leading-[24px] phone:text-[14px] phone:leading-[21px] text-[#000]">
      {dealsCount} Live Wholesaler Deals for keyword{" "}
      <span className="text-secondary-main">“{lastKeyword}”</span>
    </h1>
  );
};

export default DealsSearchHeading;
