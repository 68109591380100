import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LOGIN, REGISTER, CHECK_USERNAME } from "../api";
import { createCookie, deleteCookie } from "../../hooks/cookies";

export const LoginUser = createAsyncThunk(
  "login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(LOGIN, credentials);
      return response.data;
    } catch (error) {
      // Check if it's an Axios error
      if (error.response && error.response.data) {
        // Return a specific error message from the response
        return rejectWithValue(error.response.data.message || "Login failed");
      }
      return rejectWithValue(error.message || "Network Error");
    }
  }
);

export const RegisterUser = createAsyncThunk(
  "register",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(REGISTER, credentials);
      return response.data;
    } catch (error) {
      // Handle the error in a similar way for register
      if (error.response && error.response.data) {
        return rejectWithValue(
          error.response.data.message || "Registration failed"
        );
      }
      return rejectWithValue(error.message || "Network Error");
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await axios.post("/api/auth/logout");
});

export const checkUserName = createAsyncThunk(
  "/checkUserName",
  async (username, { rejectWithValue }) => {
    try {
      const response = await axios.post(CHECK_USERNAME, username);
      return response.data;
    } catch (error) {
      // Handle the error in a similar way for register
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message || "Network Error");
    }
  }
);

export const fetchAuthStatus = createAsyncThunk(
  "auth/fetchStatus",
  async (token) => {
    return token && token.trim() !== "";
  }
);

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    message: "",
    isLoading: true,
    error: null,
    isAuthenticated: false,
  },
  reducers: {
    clearAuth: (state) => {
      state.user = null;
      deleteCookie(); // Remove cookies
    },
    setAuth: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginUser.pending, (state) => {
        state.message = "";
        state.isLoading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
        createCookie(
          action?.payload?.data?.token,
          action?.payload?.data?.remember_me,
          action?.payload?.isFirstLogin ? true : false,
          action?.payload?.data?.username
        );
      })
      .addCase(LoginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message; // Use the message from rejectWithValue or a fallback
      })
      .addCase(RegisterUser.pending, (state) => {
        state.message = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(RegisterUser.fulfilled, (state, action) => {
        state.message = action.payload.message;
        console.log(action.payload);
        createCookie(
          action?.payload?.data?.token,
          action?.payload?.data?.remember_me === false,
          action?.payload?.isFirstLogin ? true : false,
          action?.payload?.data?.user?.fldMemberId
        );
      })
      .addCase(RegisterUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(checkUserName.pending, (state) => {
        state.message = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkUserName.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(checkUserName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAuthStatus.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload;
      });
  },
});

export const { clearAuth, setAuth } = authSlice.actions;

export default authSlice.reducer;
