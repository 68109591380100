import Cookies from "js-cookie";
import { API_STARTING } from "../api";

export const onLikeSubmit = async (payload) => {
  try {
    const response = await fetch(`${API_STARTING}/user/deal-add-favourite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("wd-team")}`, // If authorization token is required
      },
      body: JSON.stringify(payload), // Stringify the payload before sending
    });

    if (!response.ok) {
      throw new Error("Failed to add to favourites");
    }

    const data = await response.json();
    console.log("Successfully added to favourites:", data);
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export const onUnLikeClick = async (dealId) => {
  // Payload structure for removing a favorite
  const payload = {
    dealId: dealId, // Deal ID that needs to be removed from favorites
  };

  try {
    const response = await fetch(`${API_STARTING}/user/remove-deal-favorite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("wd-team")}`, // Assuming an authorization token is required
      },
      body: JSON.stringify(payload), // Stringify the payload
    });

    if (!response.ok) {
      throw new Error("Failed to remove from favorites");
    }

    const data = await response.json();
    console.log("Successfully removed from favorites:", data);
  } catch (error) {
    console.error("Error:", error.message);
  }
};
