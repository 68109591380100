import React from "react";

const LoadingCard = () => {
  return (
    <div className="w-full bg-white border border-neutral-200 rounded-xl overflow-hidden flex flex-col animate-pulse">
      {/* Badge Section */}
      <div className="relative top-0 p-4 pt-0">
        <div className="w-16 h-16 bg-gray-300 rounded-full absolute"></div>
        <div className="w-12 h-4 bg-gray-300 rounded-sm absolute z-10 top-0 left-5"></div>
        <div className="w-16 h-6 bg-gray-300 rounded-sm absolute top-6 left-1"></div>
      </div>

      {/* Product Image */}
      <div className="w-full bg-gray-300 h-64 p-4 rounded-md"></div>

      {/* Product Info */}
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div className="w-24 h-6 bg-gray-300 rounded"></div>
          <div className="flex items-center gap-4">
            <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
            <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
          </div>
        </div>

        <div className="w-full h-4 bg-gray-300 rounded-sm my-2"></div>

        <div className="w-full h-6 bg-gray-300 rounded-md my-2"></div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="w-full h-4 bg-gray-300 rounded-sm"></div>
          <div className="w-full h-4 bg-gray-300 rounded-sm"></div>
        </div>

        {/* Comparison Section */}
        <div className="mt-4 bg-neutral-50 rounded-lg p-3">
          <div className="w-full h-4 bg-gray-300 rounded-sm border-b pb-3"></div>
          {[...Array(2)].map((_, index) => (
            <div
              className="flex items-center justify-between py-3 border-b"
              key={index}
            >
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
                <div className="w-24 h-4 bg-gray-300 rounded-sm"></div>
              </div>
              <div className="w-16 h-4 bg-gray-300 rounded-sm"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingCard;
