import React, { useEffect, useState } from "react";
import BuyerSellerNav from "./components/BuyerSellerNav";
import TopNav from "./components/TopNav";
import MainNav from "./components/MainNav";
import { useLocation } from "react-router-dom";
import { useRedirection } from "../../../hooks";
import { checkUserToken } from "../../../store/api/validateToken";
import Cookies from "js-cookie";
const MobileHeader = React.lazy(() =>
  import("../MobileHeaderMenu/MobileHeader")
);
const MobileHeaderDown = React.lazy(() =>
  import("../MobileHeaderMenu/MobileHeaderDown")
);

const Navigation = () => {
  const [login, setLogin] = useState(false);
  const location = useLocation();
  const { redirectToLoginPage } = useRedirection();
  const isAuthRoute =
    location.pathname.includes("/userlogin") ||
    location.pathname.includes("/reset-password");
  const ShowRegisterForm = () => {
    redirectToLoginPage();
  };
  const ShowLoginForm = () => {
    redirectToLoginPage();
  };
  useEffect(() => {
    checkAuths();
  }, []);
  useEffect(() => {
    checkAuths();
  }, [location.pathname]);
  const checkAuths = async () => {
    const token = Cookies.get("wd-team");
    if (token) {
      const data = await checkUserToken();
       setLogin(data)
    } else {
      setLogin(false);
    }
    // console.log(data);
  };
  return (
    <>
      {" "}
      <TopNav />
      <BuyerSellerNav />
      {!isAuthRoute && <MainNav ShowRegisterForm={ShowRegisterForm} />}
      {!isAuthRoute && !login && (
        <MobileHeader
          ShowRegisterForm={ShowRegisterForm}
          ShowLoginForm={ShowLoginForm}
        />
      )}
      {!isAuthRoute && !login && <MobileHeaderDown />}
    </>
  );
};

export default Navigation;
