// src/redux/slices/favCategoriesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GET_FAVORITE_CAT } from "../api"; // Ensure this is correctly defined

// Async thunk to fetch favorite categories with token
export const fetchFavCategories = createAsyncThunk(
  'favCategories/fetchFavCategories',
  async ({ token }, { rejectWithValue }) => { // Accept an object containing token
    try {
      const response = await axios.get(GET_FAVORITE_CAT, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in headers
        },
      });

      if (response.data.success) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Initial state
const initialState = {
  categories: [],
  isLoading: false,
  error: null,
};

const favCategoriesSlice = createSlice({
  name: 'favCategories',
  initialState,
  reducers: {
    // Optional: Add a reducer to handle category removal without refetching
    removeCategory: (state, action) => {
      state.categories = state.categories.filter(
        (category) => category.fldFavouriteCategoryId !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFavCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload;
      })
      .addCase(fetchFavCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message || 'Failed to fetch favorite categories';
      });
  },
});

export const { removeCategory } = favCategoriesSlice.actions;
export default favCategoriesSlice.reducer;
