import React from "react";

const GuaranteeCard = () => {
  return (
    <div className="tablet:w-1/2 w-full border-[3px] guarantee-card border-[#FEC802] h-[242px] text-center tablet:mt-[24px] mt-[72px] p-[32px] phone:px-[16px] phone:pb-[24px] tablet:px-[24px] relative pt-[96px] phone:pt-[34px] phone:h-auto rounded-[24px] bg-[#fec8024d]">
      <img
        src="../assets/images/v1/single/guarantee.png"
        className="w-[120px] h-[120px] phone:w-[88px] phone:h-[88px] absolute left-1/3 phone:left-1/3 -mt-10 mx-auto top-1.5 phone:top-0"
        alt="guarantee"
      />
      <h1 className="font-bold text-[18px] leading-[27px] text-primary-main mt-4 tablet:mt-0 mb-2">
        100% Money Back Guarantee
      </h1>
      <p className="text-[14px] leading-[21px] font-medium text-[#454545]">
        "At Wholesale Deals, we guarantee that the supplier has this deal at the
        quoted price or your money back!"
      </p>
    </div>
  );
};

export default GuaranteeCard;
