import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { LoginUser, setAuth } from "../../../../store/slices/authSlice";
import { useRedirection } from "../../../../hooks";
import Cookies from "js-cookie"; // Importing js-cookie
import Spinner from "../../../../assets/spinner/Spinner";
import { eyeClose, eyeOpen } from "../../../../assets/images/v1/single";

// Validation Schema
const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = ({ setForgetPass }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const dispatch = useDispatch();
  const { redirectUserDashboard } = useRedirection();
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  // Toggle password visibility
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);

  // Taking props for forgetting password
  const ShowForgetPassword = () => {
    setForgetPass();
  };
  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const response = await dispatch(LoginUser(values));
      if (
        response.type === "login/fulfilled" &&
        response.payload.message === "Login successful"
      ) {
        // Store username in cookies upon successful login
        Cookies.set("username", values.username, { expires: 7 }); // Store for 7 days
        Cookies.set("wholesale_popup", true, { expires: 1 });
        setIsLoginSuccess(true);
        redirectUserDashboard();
        dispatch(setAuth(true));
      } else if (response.type === "login/rejected") {
        setFieldError(
          "general",
          response.payload || "Login failed. Please try again."
        );
        setSubmitting(false);
      }
    } catch (error) {
      setFieldError("general", "An error occurred. Please try again.");
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{ username: "", password: "", remember_me: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors }) =>
          isSubmitting || isLoginSuccess ? (
            <Spinner />
          ) : (
            <>
              <div className="flex flex-col gap-3 phone:gap-[16px]">
                <div className="flex items-center gap-2">
                  <svg
                    width={24}
                    height={25}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.12">
                      <path
                        d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
                        fill="#E66927"
                      />
                      <path
                        d="M16 17.5H8C5.79086 17.5 4 19.2909 4 21.5C4 22.0523 4.44772 22.5 5 22.5H19C19.5523 22.5 20 22.0523 20 21.5C20 19.2909 18.2091 17.5 16 17.5Z"
                        fill="#E66927"
                      />
                    </g>
                    <path
                      d="M4.00002 22.3174C4.6026 22.5 5.41649 22.5 6.8 22.5H17.2C18.5835 22.5 19.3974 22.5 20 22.3174M4.00002 22.3174C3.87082 22.2783 3.75133 22.2308 3.63803 22.173C3.07354 21.8854 2.6146 21.4265 2.32698 20.862C2 20.2202 2 19.3802 2 17.7V7.3C2 5.61984 2 4.77976 2.32698 4.13803C2.6146 3.57354 3.07354 3.1146 3.63803 2.82698C4.27976 2.5 5.11984 2.5 6.8 2.5H17.2C18.8802 2.5 19.7202 2.5 20.362 2.82698C20.9265 3.1146 21.3854 3.57354 21.673 4.13803C22 4.77976 22 5.61984 22 7.3V17.7C22 19.3802 22 20.2202 21.673 20.862C21.3854 21.4265 20.9265 21.8854 20.362 22.173C20.2487 22.2308 20.1292 22.2783 20 22.3174M4.00002 22.3174C4.00035 21.5081 4.00521 21.0799 4.07686 20.7196C4.39249 19.1329 5.63288 17.8925 7.21964 17.5769C7.60603 17.5 8.07069 17.5 9 17.5H15C15.9293 17.5 16.394 17.5 16.7804 17.5769C18.3671 17.8925 19.6075 19.1329 19.9231 20.7196C19.9948 21.0799 19.9996 21.5081 20 22.3174M16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10Z"
                      stroke="#E66927"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h5 className="text-xl text-neutral-900 font-bold phone:text-lg">
                    Already a Member?
                  </h5>
                </div>
                <p className="text-sm text-neutral-500">
                  Please enter your username and password below to login to your
                  existing account.
                </p>
              </div>
              <Form id="loginForm" className="mt-6">
                {/* Username Field */}
                <div className="flex flex-col gap-2 mb-4">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="username"
                      className="text-sm text-neutral-900 font-medium tracking-[0.2px]"
                    >
                      Username
                    </label>
                  </div>
                  <div className="flex justify-between px-4 py-[11.5px] border border-neutral-200 rounded-lg">
                    <Field
                      type="text"
                      name="username"
                      placeholder="Enter username..."
                      className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal outline-none border-none"
                    />
                  </div>
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>

                {/* Password Field */}
                <div className="flex flex-col gap-2 mb-6">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="text-sm text-neutral-900 font-medium tracking-[0.2px]"
                    >
                      Password
                    </label>
                    <div>
                      <a
                        className="text-sm text-[#0355A5] font-semibold tracking-[0.2px] cursor-pointer"
                        onClick={ShowForgetPassword}
                      >
                        Forgotten Password?
                      </a>
                    </div>
                  </div>
                  <div className="flex justify-between px-4 py-[11.5px] border border-neutral-200 rounded-lg">
                    <Field
                      type={showNewPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter password..."
                      className="w-full text-sm tracking-[0.2px] font-semibold placeholder:font-normal"
                    />
                    <span
                      className="cursor-pointer ml-2 text-gray-600"
                      onClick={toggleShowNewPassword}
                    >
                      {showNewPassword ? (
                        <img src={eyeClose} alt="Hide" />
                      ) : (
                        <img src={eyeOpen} alt="Show" />
                      )}
                    </span>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>

                {/* Remember Me Checkbox */}
                <div className="flex items-center gap-2 mb-6">
                  <Field
                    type="checkbox"
                    name="remember_me"
                    id="remember_me"
                    className="w-6 h-6 accent-primary-main border border-[#CBD5E1] cursor-pointer"
                  />
                  <label htmlFor="remember_me" className="text-sm font-medium">
                    Remember Me
                  </label>
                </div>

                {/* Error Message */}
                {errors.general && (
                  <div className="text-red-600 text-sm mb-4">
                    {errors.general}
                  </div>
                )}

                {/* Submit Button */}
                <div className="flex flex-col gap-4 justify-center items-center">
                  <button
                    type="submit"
                    className="py-[11.5px] flex justify-center items-center text-sm text-white font-semibold bg-[#0355A5] rounded-lg w-full tracking-[0.2px]"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner /> : "LOGIN"}
                  </button>
                </div>
              </Form>
            </>
          )
        }
      </Formik>
    </>
  );
};

export default LoginForm;
