import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { CANCEL_PAYSTRAX_SUBSCRIPTION } from '../api';
import logout from '../../_helperfunctions/Logout'; 

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const cancelPaystraxSubscription = createAsyncThunk(
  'cancelSubscription/cancelPaystraxSubscription',
  async ({ token, transactionId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CANCEL_PAYSTRAX_SUBSCRIPTION,
        { transactionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        logout();
      }
      return rejectWithValue(
        error.response?.data || {
          message: 'Something went wrong while canceling the subscription.',
        }
      );
    }
  }
);

const cancelSubscriptionSlice = createSlice({
  name: 'cancelSubscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cancelPaystraxSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelPaystraxSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(cancelPaystraxSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default cancelSubscriptionSlice;
