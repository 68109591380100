import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FAVORITE_CATEGORIES } from "../api";

export const getFavoriteCategoryList = createAsyncThunk(
  "favorite/getFavoriteCategoryList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${FAVORITE_CATEGORIES}?type=${data.type}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data; // Make sure the response contains { success, message, data } structure
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: "Unknown error" }
      );
    }
  }
);

const favoriteCategoriesSlice = createSlice({
  name: "favoriteCategories",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFavoriteCategoryList.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.data = action.payload.data; // Use action.payload.data based on the API response
          state.isLoading = false;
          state.error = null;
        } else {
          state.error = action.payload.message || "Unexpected response format";
        }
      })
      .addCase(getFavoriteCategoryList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFavoriteCategoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message || "Something went wrong";
      });
  },
});

// Export the reducer
export default favoriteCategoriesSlice.reducer;
