import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_BUYER_ORDER } from "../api";
import logout from "../../_helperfunctions/Logout";
// Thunk for placing an order
export const getBuyerOrderDetails = createAsyncThunk(
  "order/getBuyerOrderDetails",
  async (data, { rejectWithValue }) => {
    if (data.search.length > 0) {
      try {
        const response = await axios.get(
          `${GET_BUYER_ORDER}?search=${data.search.join(",")}`,
          {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    } else {
      try {
        const response = await axios.get(GET_BUYER_ORDER, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

const buyerOrderSlice = createSlice({
  name: "buyerOrder",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuyerOrderDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBuyerOrderDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getBuyerOrderDetails.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default buyerOrderSlice.reducer;
