import React from "react";

// components
import DealImageComponent from "../DealImageComponent/DealImageComponent";

// stores
import { useSelector } from "react-redux";
import {
  getCurrencyCode,
  getCurrencySymbol,
} from "../../../../../../store/slices/currencySlice";
import { getConvertedRate } from "../../../../../sharedComponent/Calculation/CurrencyCalculation";

const ComparePlatforms = ({
  title,
  description,
  imageUrl,
  price,
  rrp,
  vat,
  discount,
  fldDealCurrencyId,
  platforms,
}) => {
  const retailer = useSelector((state) => state.common.data);
  const currencySymbol = useSelector(getCurrencySymbol);
  const currencyCode = useSelector(getCurrencyCode);
  // Get retailer data for the given ID
  function getName(retailerID) {
    const retailerData = retailer.find(
      (item) => item.fldRetailerId === retailerID
    );
    const dealName = retailerData ? retailerData.fldRetailerName : "";
  }

  // Determine the alt text and image based on retailer
  const findCurrency = (currency) => {
    return currencySymbol;
  };
  // {findCurrency(fldDealCurrencyId)}
  // {getConvertedRate(rrp, currencyCode)}

  return (
    <div className="w-full overflow-auto">
      <div className="w-full phone:w-[468px] grid grid-cols-4 rounded-t-[8px]">
        {/* Header */}
        <div className="w-full text-left p-[12px] bg-neutral-100 rounded-tl-[8px] border-t border-l border-neutral-200">
          <p className="text-[12px] leading-[18px] text-neutral-500 font-medium">
            Platform
          </p>
        </div>
        <div className="w-full text-left p-[12px] bg-neutral-100 border-t border-neutral-200">
          <p className="text-[12px] leading-[18px] text-neutral-500 font-medium">
            Your Price
          </p>
        </div>
        <div className="w-full text-left p-[12px] bg-neutral-100 border-t border-neutral-200">
          <p className="text-[12px] leading-[18px] text-neutral-500 font-medium">
            Your gross profit
          </p>
        </div>
        <div className="w-full text-left p-[12px] bg-neutral-100 border-t border-r border-neutral-200 rounded-tr-[8px]">
          <p className="text-[12px] leading-[18px] text-neutral-500 font-medium">
            %Markup
          </p>
        </div>

        {/* Platform Rows */}
        {platforms.map((platform, index) => (
          <React.Fragment key={index}>
            <div className="w-full py-[17.5px] pl-[12px] grid gap-[9px] border-b border-l rounded-bl-[8px] border-neutral-200">
              {/* <img src={platform.imageUrl} alt={platform.name} /> */}
              <DealImageComponent retailerID={platform.retailerID} />
              <a
                href={platform.link}
                className="text-[10px] no-underline leading-[15px] text-primary-main font-semibold"
                target="_blank"
                rel="noopener noreferrer"
              >
                Verify on {getName(platform.retailerID)}
              </a>
            </div>

            <div className="w-full py-[16px] pl-[12px] grid gap-[2px] border-b border-neutral-200">
              <h3 className="font-bold text-[18px] leading-[27px] text-neutral-900">
                {/* ${platform.price} */}
                {findCurrency(fldDealCurrencyId)}
                {getConvertedRate(platform.price, currencyCode)}
              </h3>
              <p className="text-[12px] leading-[18px] font-normal text-neutral-500">
                {vat ? "inc.VAT" : "ex.VAT"}
              </p>
            </div>

            <div className="w-full py-[16px] pl-[12px] grid gap-[2px] border-b border-neutral-200">
              <h3 className="font-bold text-[18px] leading-[27px] text-neutral-900">
                {findCurrency(fldDealCurrencyId)}
                {getConvertedRate(platform.grossProfit, currencyCode)}
                {/* ${platform.grossProfit} */}
              </h3>
              <p className="text-[12px] leading-[18px] font-normal text-neutral-500">
                {vat ? "inc.VAT" : "ex.VAT"}
              </p>
            </div>

            <div
              className={`w-full flex items-center pl-[12px] ${
                index === platforms.length - 1
                  ? "border border-l-[0px] border-t-[0px] border-neutral-200 rounded-br-[8px]"
                  : "border-b border-r border-neutral-200"
              }`}
            >
              <h1 className="font-bold text-[20px] leading-[27px] text-secondary-main">
                {platform.markup}%
              </h1>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ComparePlatforms;
