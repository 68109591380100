import React from "react";

const DeleteSvgIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 4.00033C10.6667 3.26395 11.2637 2.66699 12.0001 2.66699H20.0001C20.7365 2.66699 21.3334 3.26395 21.3334 4.00033C21.3334 4.73671 20.7365 5.33366 20.0001 5.33366H12.0001C11.2637 5.33366 10.6667 4.73671 10.6667 4.00033Z"
        fill="#E04C3F"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.66675 8.00033C2.66675 7.26395 3.2637 6.66699 4.00008 6.66699H28.0001C28.7365 6.66699 29.3334 7.26395 29.3334 8.00033C29.3334 8.73671 28.7365 9.33366 28.0001 9.33366H26.5809L25.7253 22.1676C25.6581 23.1749 25.6026 24.0084 25.503 24.6869C25.3992 25.3933 25.2347 26.0388 24.892 26.6404C24.3585 27.5769 23.5537 28.3298 22.5838 28.7998C21.9608 29.1018 21.3057 29.223 20.594 29.2795C19.9104 29.3337 19.075 29.3337 18.0655 29.3337H13.9347C12.9252 29.3337 12.0898 29.3337 11.4062 29.2795C10.6945 29.223 10.0395 29.1018 9.41643 28.7998C8.44651 28.3298 7.64176 27.5769 7.10824 26.6404C6.76552 26.0388 6.601 25.3933 6.49728 24.6869C6.39767 24.0084 6.34211 23.1749 6.27498 22.1676L5.41938 9.33366H4.00008C3.2637 9.33366 2.66675 8.73671 2.66675 8.00033ZM13.3334 12.667C14.0698 12.667 14.6667 13.2639 14.6667 14.0003V20.667C14.6667 21.4034 14.0698 22.0003 13.3334 22.0003C12.597 22.0003 12.0001 21.4034 12.0001 20.667V14.0003C12.0001 13.2639 12.597 12.667 13.3334 12.667ZM20.0001 14.0003C20.0001 13.2639 19.4031 12.667 18.6667 12.667C17.9304 12.667 17.3334 13.2639 17.3334 14.0003V20.667C17.3334 21.4034 17.9304 22.0003 18.6667 22.0003C19.4031 22.0003 20.0001 21.4034 20.0001 20.667V14.0003Z"
        fill="#E04C3F"
      ></path>
    </svg>
  );
};

export default DeleteSvgIcon;
