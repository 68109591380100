import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode"; // Install this package using `npm install jwt-decode`

export const createCookie = (jwt, remember_me, isFirstLogin,username) => {
  let expireTime = new Date();

  // Decode the token to get the expiration time
  try {
    const decodedToken = jwtDecode(jwt);
    if (decodedToken.exp) {
      // Convert the token's `exp` to a JavaScript date
      expireTime = new Date(decodedToken.exp * 1000);
    }
  } catch (error) {
    console.error("Invalid JWT token:", error);
  }

  // Use a fallback of 24 hours if `remember_me` is true and `exp` is unavailable
  if (
    (typeof remember_me === "boolean" && remember_me) ||
    remember_me === "true"
  ) {
    if (!expireTime || expireTime <= new Date()) {
      expireTime = new Date();
      expireTime.setTime(expireTime.getTime() + 24 * 60 * 60 * 1000); // 24 hours
    }
    Cookies.set("remember_me", true, { expires: expireTime });
    Cookies.set("wd-team", jwt, { expires: expireTime });
    Cookies.set("isFirstLogin", isFirstLogin ? "true" : "false", {
      expires: expireTime,
    });
    Cookies.set("wholesale_popup", true, { expires: expireTime });
    Cookies.set("username", username, { expires: expireTime });
  } else {
    // Short expiration for `remember_me` false
    const shortExpireTime = 1; // 1 full day
    Cookies.set("remember_me", false, { expires: shortExpireTime });
    Cookies.set("wd-team", jwt, { expires: shortExpireTime });
    Cookies.set("isFirstLogin", isFirstLogin ? "true" : "false", {
      expires: shortExpireTime,
    });
    Cookies.set("wholesale_popup", true, { expires: shortExpireTime});
    Cookies.set("username", username, { expires: shortExpireTime });
  }
};


export const deleteCookie = () => {
  // Get the current value of the cookie (optional)
  const token = Cookies.get("wd-team");
  const token1 = Cookies.get("username");
  const token2 = Cookies.get("remember_me");
  // Remove the cookie if it exists
  if (token) {
    Cookies.remove("wd-team");
  }
  if (token1) {
    Cookies.remove("username");
  }
  if (token2) {
    Cookies.remove("remember_me");
  }
};
//function to checkif cookie is already present
export const getCookieWhenPageLoads = () => {
  const cookietoken = Cookies.get("wdteam-browserCookie");
  if (cookietoken) return true;
  return false;
};

export const getToken = () => {
  const token = Cookies.get("wd-team");
  return token;
};
//function to checkif cookie is already present
export const acceptCookieWhenPageLoads = (cookiedata) => {
  const expireTime = new Date();
  expireTime.setTime(expireTime.getTime() + 60 * 60 * 1000); // 1 hour from now
  const cookietoken = Cookies.set(
    "wdteam-browserCookie",
    JSON.stringify(cookiedata),
    {
      expires: expireTime,
    }
  );
  if (cookietoken) return true;
  return false;
};




const EXPIRY_DAYS = 90; // 90 days


export const acceptAllCookies = () => {
  Cookies.set("essential_cookies", true, { expires: EXPIRY_DAYS });
  
  // Set optional cookies only if they are not already set
  if (Cookies.get("marketing_cookies") === undefined) {
    Cookies.set("marketing_cookies", true, { expires: EXPIRY_DAYS });
  }
  if (Cookies.get("personalisation_cookies") === undefined) {
    Cookies.set("personalisation_cookies", true, { expires: EXPIRY_DAYS });
  }
  if (Cookies.get("analytics_cookies") === undefined) {
    Cookies.set("analytics_cookies", true, { expires: EXPIRY_DAYS });
  }
  
  // Set cookie consent flag regardless
  Cookies.set("cookie_consent", true, { expires: EXPIRY_DAYS });
};



export const saveCookiePreferences = ({ marketing, personalisation, analytics }) => {
  // Essential cookies always true (required)
  Cookies.set("essential_cookies", true, { expires: EXPIRY_DAYS });
  // Optional cookies as per user selection
  Cookies.set("marketing_cookies", marketing, { expires: EXPIRY_DAYS });
  Cookies.set("personalisation_cookies", personalisation, { expires: EXPIRY_DAYS });
  Cookies.set("analytics_cookies", analytics, { expires: EXPIRY_DAYS });
  // Set cookie_consent to true so that popup is not shown again
  Cookies.set("cookie_consent", true, { expires: EXPIRY_DAYS });
};

// Helper to check if consent is already given
export const hasCookieConsent = () => {
  return Cookies.get("cookie_consent") === "true" || Cookies.get("cookie_consent") === true;
};
