import React from "react";
import { decodeHtmlEntities } from "../../../../sharedComponent/AllCategories/constants";

const DealDetails = ({ deals }) => {
  return (
    <div className="w-full bg-white">
      <div className="w-full max-w-[1440px] mx-auto py-[40px] px-[56px] phone:px-[24px]">
        <div className="w-full bg-neutral-50 rounded-[16px] p-[32px] grid gap-[24px] phone:max-h-[304px] max-h-[280px] overflow-auto phone:p-[20px] phone:gap-[16px]">
          <h3 className="text-[#000] font-bold text-[24px] leading-[33.6px] phone:text-[20px] phone:leading-[27px]">
            {decodeHtmlEntities(deals?.categoryTitle)}
          </h3>
          <div className="grid gap-[24px]">
            <p className="text-neutral-600 phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-normal">
              {deals?.categoryDescription}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealDetails;
