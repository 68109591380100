import React from "react";
import { useRedirection } from "../../../../../../hooks";

const TestimonialsHeader = () => {
  const { redirectTotestimonials } = useRedirection();
  return (
    <div className="w-full flex phone:flex-col phone:items-start phone:gap-[20px] items-center justify-between phone:pb-[20px] pb-[12px] border-b border-neutral-200">
      <div className="w-fit flex items-center gap-[12px]">
        <img
          src="../assets/images/v1/icons/thumb.svg"
          className="w-[23px] h-[32px] phone:w-[16px] phone:h-[24px]"
          alt="thumb"
        />
        <h3 className="text-[32px] font-bold leading-[40px] phone:text-[20px] phone:leading-[27px] text-neutral-900">
          Testimonials
        </h3>
      </div>
      <button
        onClick={() => redirectTotestimonials()}
        className="w-fit no-underline flex items-center gap-[6px] phone:ml-[24px]"
      >
        <p className="text-[16px] leading-[24px] font-medium text-primary-main">
          View all testimonials
        </p>
        <img
          src="../assets/images/v1/icons/chevron-orange.svg"
          className="w-[16px] h-[16px]"
          alt="chevron"
        />
      </button>
    </div>
  );
};

export default TestimonialsHeader;
