import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import down from "../../../../../assets/images/v1/deals/down.svg";
const GradeDropDown = ({
  title,
  options,
  selectedGradeOptions,
  setSelectedGradeOptions,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (value) => {
    setSelectedGradeOptions((prevSelected) => {
      if (prevSelected.includes(value)) {
        // If the option is already selected, remove it
        return prevSelected.filter((selectedValue) => selectedValue !== value);
      } else {
        // Otherwise, add it to the selected list
        return [...prevSelected, value];
      }
    });
  };

  useEffect(() => {
    // Update the URL with selected grade options
    const searchParams = new URLSearchParams(location.search);
    if (selectedGradeOptions.length > 0) {
      searchParams.set("product_condition", selectedGradeOptions.join(","));
      searchParams.set("page", 1);
    } else {
      searchParams.delete("product_condition");
    }
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [selectedGradeOptions, navigate, location.search]);

  return (
    <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
      <button
        className="w-full py-[16px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
          {title}
        </h1>
        <img
          data-icon="arrow"
          src={down}
          alt="Toggle Dropdown"
          className={`w-[24px] h-[24px] transition-transform duration-300 ${
            isOpen ? "" : "rotate-180"
          }`}
        />
      </button>

      {isOpen && (
        <div className="w-full filter-dropdown-container">
          {options.map((option, index) => (
            <label
              key={index}
              className="cursor-pointer w-full flex items-center gap-[12px] py-[12px]"
            >
              <input
                type="checkbox"
                className="w-[20px] accent-primary-main h-[20px] cursor-pointer"
                name={option.value}
                checked={selectedGradeOptions.includes(option.value)}
                onChange={() => handleOptionChange(option.value)}
              />
              <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                {option.label}
              </p>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default GradeDropDown;
