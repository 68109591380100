import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ShowFlag from "../../../../sharedComponent/Flag/ShowFlag";
import chevronDown from "../../../../../assets/images/v1/icons/chevron-down.svg";
import { getCommonDeals } from "../../../../../store/slices/commonDealsSlice";
import down from "../../../../../assets/images/v1/deals/down.svg";

const CountryFilterDropdown = ({
  countries,
  selectedCountries,
  setSelectedCountries,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleCountries, setVisibleCountries] = useState(5);

  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const showMoreCountries = () => {
    setVisibleCountries((prevCount) => prevCount + 5);
  };

  const handleCountrySelection = (countryId) => {
    setSelectedCountries((prevSelected) => {
      if (prevSelected.includes(countryId)) {
        return prevSelected.filter((id) => id !== countryId);
      } else {
        return [...prevSelected, countryId];
      }
    });
  };

  const filteredCountries = countries.filter((country) =>
    country.fldCountryName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    // Update the URL with selected countries
    const searchParams = new URLSearchParams(location.search);
    if (selectedCountries.length > 0) {
      searchParams.set("country", selectedCountries.join(","));
      searchParams.set("page", 1);
    } else {
      searchParams.delete("country");
    }
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [selectedCountries, navigate, location.search]);

  const countriesToDisplay = filteredCountries.slice(0, visibleCountries);

  return (
    <div className="filter-dropdown w-full py-[8px] px-[20px] border-t border-neutral-200">
      <button
        className="w-full py-[16px] flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <h1 className="font-bold text-[16px] leading-[24px] text-left text-neutral-900">
          All Countries
        </h1>
        <img
          data-icon="arrow"
          src={down}
          alt="Toggle Dropdown"
          className={`w-[24px] h-[24px] transition-transform duration-300 ${
            isOpen ? "" : "rotate-180"
          }`}
        />
      </button>

      {isOpen && (
        <div className="w-full filter-dropdown-container mt-[8px]">
          <div className=" search-countries">
            <input
              type="text"
              className="w-full py-[12px] px-[16px] rounded-[10px] min-h-[48px] mb-[8px] border border-neutral-200 text-[16px] leading-[24px] font-normal text-neutral-400 focus:outline-[#E66927]"
              placeholder="Search Countries"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {countriesToDisplay.length > 0 ? (
            countriesToDisplay.map((country) => (
              <label
                key={country.fldCountryId}
                className="cursor-pointer w-full flex items-center gap-[12px] py-[12px]"
              >
                <input
                  type="checkbox"
                  className="w-[20px] accent-primary-main h-[20px] cursor-pointer"
                  checked={selectedCountries.includes(country.fldCountryId)}
                  onChange={() => handleCountrySelection(country.fldCountryId)}
                />
                <div className="flex items-center gap-[8px]">
                  <ShowFlag
                    countryName={country.fldCountryName}
                    countryList={countries}
                  />
                  <p className="text-[16px] leading-[24px] text-neutral-600 font-normal">
                    {country.fldCountryName}
                  </p>
                </div>
              </label>
            ))
          ) : (
            <span className="text-[16px] leading-[24px] text-neutral-600 font-normal py-[12px]">
              Not found
            </span>
          )}

          {filteredCountries.length > visibleCountries && (
            <button
              onClick={showMoreCountries}
              className="text-primary-main text-[16px] leading-[24px] font-normal py-[12px] w-full text-left"
            >
              Show More
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CountryFilterDropdown;
