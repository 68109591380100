import Cookies from "js-cookie";
import { API_STARTING } from "../api";

export const onHideClick = async (payload) => {
  try {
    console.log("Payload being sent:", payload); // Log the payload
    const url = `${API_STARTING}/user/hide-deal`;
    console.log("API URL:", url); // Log the final URL

    const token = Cookies.get("wd-team");
    console.log("Authorization token:", token); // Log the token

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    console.log("Response status:", response.status); // Log response status
    const responseText = await response.text();
    console.log("Response body:", responseText); // Log response body

    if (!response.ok) {
      throw new Error(`Failed to hide deal, status: ${response.status}`);
    }

    const data = JSON.parse(responseText);
    console.log("Successfully hid the deal", data);
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export const onShowClick = async (payload) => {
  try {
    console.log("Payload being sent:", payload); // Log the payload
    const url = `${API_STARTING}/user/show-deal`;
    console.log("API URL:", url); // Log the final URL

    const token = Cookies.get("wd-team");
    console.log("Authorization token:", token); // Log the token

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    console.log("Response status:", response.status); // Log response status
    const responseText = await response.text();
    console.log("Response body:", responseText); // Log response body

    if (!response.ok) {
      throw new Error(`Failed to show deal, status: ${response.status}`);
    }

    const data = JSON.parse(responseText);
    console.log("Successfully showed the deal", data);
  } catch (error) {
    console.error("Error:", error.message);
  }
};
