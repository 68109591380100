import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ALPHABETIC_SEARCH } from "../api";
export const getAlphabetList = createAsyncThunk(
  "/getAlphabetList",
  async (data) => {
    const response = await axios.get(
      `${ALPHABETIC_SEARCH}?letter=${data.alphabet}&page=${data.page}`
    );
    return response.data;
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const alphabeticSearchSlice = createSlice({
  name: "alphabeticSearch",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAlphabetList.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAlphabetList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAlphabetList.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
      });
  },
});
export default alphabeticSearchSlice.reducer;
