import React, { useEffect } from "react";
import Card from "./Card";
import LoadingCard from "./LoadingCard";
import {
  formatDate,
  GetCurrentUrl,
  getIdBySlug,
  getLowestPriceObject,
  getSlugFromUrl,
} from "../../constant";
import { getProductingleImage } from "../../../../sharedComponent/ImageMaster/ImageMaster";
import { useDispatch, useSelector } from "react-redux";
import { getMyFavoriteCategory } from "../../../../../store/slices/favoriteSlice";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { calculateProfitAndOrderUnits } from "../../../../sharedComponent/Calculation/Calculation";
const CardContainer = ({ deals, isLoading, searchTerm }) => {
  const token = Cookies.get("wd-team");
  const location = useLocation();
  const dispatch = useDispatch();
  const favCategories = useSelector((state) => state.favorite.data);
  const categories = useSelector((state) => state.categories.data);
  const slug = getSlugFromUrl(GetCurrentUrl(location));
  const categoryId = getIdBySlug(categories, slug);
  useEffect(() => {
    if (favCategories && favCategories.length === 0) {
      if (token) {
        dispatch(getMyFavoriteCategory({ token }));
      }
    }
  }, []);
  return (
    <div className="container mx-auto py-6">
      <div
        className={
          deals && deals.length
            ? "grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-6"
            : "w-full"
        }
      >
        {isLoading ? (
          [...Array(10)].map((_, index) => <LoadingCard />)
        ) : deals && deals.length ? (
          deals.map((deal, index) => (
            <Card
              key={index}
              image={
                getProductingleImage(deal.fldDealImage1) ||
                "../../../../../assets/images/v1/homepage/shoes.png"
              }
              inVat={getLowestPriceObject(deal.prices).fldIncVAT}
              title={deal.fldDealTitle || "title na"}
              price={getLowestPriceObject(deal.prices).fldPrice || "NA"}
              markup={deal.fldDealMarkup || "markup na"}
              rrp={deal.fldDealRRP || "deal.rrp"}
              grossProfit={calculateProfitAndOrderUnits(deal)}
              featuredDate={
                formatDate(deal.fldDealCreationDate) || "deal.featuredOn"
              }
              units={deal.fldMinimumOrder}
              comparison={
                (deal.samples && deal.samples.length && deal.samples) || [
                  {
                    name: "Amazon",
                    logo: "../../../../../assets/images/v1/homepage/amazon.svg",
                    price: "12.35",
                  },
                  {
                    name: "Ebay",
                    logo: "../../../../../assets/images/v1/homepage/ebay.svg",
                    price: "12.35",
                  },
                ]
              }
              fldDealCurrencyId={deal.fldDealCurrencyId}
              likeButtonShow={true}
              like={deal.favorite}
              id={deal.fldDealId}
              categoryId={categoryId}
              prices={deal.prices}
              slug={deal.fldDealSlug}
            />
          ))
        ) : searchTerm.length ? (
          ""
        ) : (
          <div className="text-center w-full">No Deals to Show</div>
        )}
      </div>
    </div>
  );
};

export default CardContainer;
