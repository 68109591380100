import React from "react";

const SoldOutTag = () => {
  return (
    <div className="w-full py-[12px] px-[16px] grid gap-[8px] bg-primary-main/20 rounded-[8px]">
      <div className="flex items-center gap-[8px]">
        <img
          src="../assets/images/v1/single/alert.svg"
          alt="Alert Icon"
          className="w-[20px] h-[20px]"
        />
        <p className="phone:text-[14px] phone:leading-[21px] text-[16px] leading-[24px] font-bold text-secondary-main">
          Sold Out
        </p>
      </div>
      <p className="phone:text-[12px] phone:leading-[19px] text-[14px] leading-[21px] font-medium text-neutral-600">
        Wishlist this item so you can be reminded when stock is available again
        or{" "}
        <a href="#" className="font-bold text-primary-main">
          look for similar items.
        </a>
      </p>
    </div>
  );
};

export default SoldOutTag;
