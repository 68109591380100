// utils/toast.js or wherever your utils are stored
import React from 'react';
import { createRoot } from 'react-dom/client';
import CustomToast from './Toast';


export function showGlobalToast({ type, message }) {
  const toastContainer = document.createElement('div');
  document.body.appendChild(toastContainer);

  const root = createRoot(toastContainer);

  const removeToast = () => {
    root.unmount();
    document.body.removeChild(toastContainer);
  };

  root.render(
    <CustomToast type={type} message={message} closeToast={removeToast} />
  );
}
