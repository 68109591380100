import StatisticCard from "./StatisticCard";

const StatisticsSection = ({ title, iconSrc, stats }) => {
  return (
    <div className="w-full bg-white">
      <div className="w-full max-w-[1440px] mx-auto phone:px-[24px] px-[56px] py-[32px] tablet:px-[56px] tablet:py-[32px]">
        <div className="w-full tablet:rounded-[16px] phone:rounded-[16px] p-[32px] tablet:p-[20px] phone:p-[16px] rounded-[16px] bg-[#FBD78B]">
          <div className="flex items-center gap-[12px]">
            <img src={iconSrc} className="w-[28px] h-[32px]" alt="icon" />
            <h3 className="font-bold text-neutral-900 phone:text-[20px] phone:leading-[27px] text-[24px] leading-[33.6px]">
              {title}
            </h3>
          </div>
          <div className="w-full desktop:mt-[32px] mt-[12px] grid md:grid-cols-3 gap-[12px] desktop:[20px]">
            {stats.map((stat, index) => (
              <StatisticCard
                key={index}
                title={stat.title}
                value={stat.value}
                description={stat.description}
                iconSrc={stat.iconSrc}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSection;
