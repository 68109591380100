// src/slices/currencySlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currencyCode: localStorage.getItem("currencycode") || "GBP",
  currencySymbol: localStorage.getItem("currencysymbol") || "£",
};
export const getConvertedRate = (state, rate) => {
  const currencyCode = state.currency.currencyCode;

  let convertedRate;
  switch (currencyCode) {
    case "EUR":
      convertedRate = Number(rate) * 0.85;
      break;
    case "USD":
      convertedRate = Number(rate) * 0.78;
      break;
    case "GBP":
      convertedRate = rate;
      break;
    default:
      convertedRate = rate;
  }

  return Number(convertedRate).toFixed(2);
};


const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrencyCode: (state, action) => {
      state.currencyCode = action.payload;
      localStorage.setItem("currencycode", action.payload);
    },
    setCurrencySymbol: (state, action) => {
      const data=action.payload
      state.currencySymbol = data[0];
      localStorage.setItem("currencysymbol", data[0]);
    }
    

  },
});

// Export actions for use in components
export const { setCurrencyCode, setCurrencySymbol,getRate } = currencySlice.actions;

// Selector to get the currency code and symbol

export const getCurrencyCode = (state) => state.currency.currencyCode;
export const getCurrencySymbol = (state) => state.currency.currencySymbol;

// Selector to calculate the converted rate based on the currency code

export default currencySlice.reducer;
