import { DISLIKE_CATEGORY, LIKE_CATEGORY } from "../api";
import Cookies from "js-cookie";

export const onLikeCategories = async (payload) => {
  try {
    const response = await fetch(LIKE_CATEGORY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("wd-team")}`, // If authorization token is required
      },
      body: JSON.stringify(payload), // Stringify the payload before sending
    });

    if (!response.ok) {
      throw new Error("Failed to add to favourites");
    }
  } catch (error) {
  }
};

export const onUnLikeCategories = async (dealId) => {
  // Payload structure for removing a favorite
  const payload = {
    category_id: dealId, // Deal ID that needs to be removed from favorites
  };

  try {
    const response = await fetch(DISLIKE_CATEGORY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("wd-team")}`, // Assuming an authorization token is required
      },
      body: JSON.stringify(payload), // Stringify the payload
    });

    if (!response.ok) {
      throw new Error("Failed to remove from favorites");
    }

    const data = await response.json();
  } catch (error) {
  }
};
