import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { HIDDEN_DEALS } from "../api";
import logout from "../../shared/logout";

// Async thunk for fetching favorite deals
export const getHiddenDeals = createAsyncThunk(
  "/getHiddenDeals",
  async (data, { rejectWithValue }) => {
    // Destructure slug and token here
    try {
      if (data.dealId === "") {
        const response = await axios.get(`${HIDDEN_DEALS}`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        return response.data;
      } else if (data.dealId !== "") {
        const response = await axios.get(
          `${HIDDEN_DEALS}?page=${data.dealId}`,
          {
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          }
        );

        return response.data; // Ensure this is the expected format
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching hiddendeals."
      );
    }
  }
);

// Define the initial state with `data` as an array
const initialState = {
  data: [], // Make sure data is an array
  isLoading: false,
  error: null,
};

const hiddenDealsSlice = createSlice({
  name: "hiddenDeals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHiddenDeals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getHiddenDeals.fulfilled, (state, action) => {
        state.isLoading = false;

        state.data = action.payload.data; // Assign the fetched array directly
      })
      .addCase(getHiddenDeals.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to load deals.";
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
      });
  },
});

export default hiddenDealsSlice.reducer;
