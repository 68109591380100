import React from "react";

const ProductDescription = ({ title, description }) => {
  return (
    <div className="w-full py-[40px] phone:py-[24px] grid gap-[20px]">
      <h1 className="font-bold text-[24px] phone:text-[20px] phone:leading-[27px] leading-[33.6px] text-[#000]">
        {title}
      </h1>
      <p className="text-[18px] leading-[27px] phone:text-[14px] phone:leading-[21px] font-normal text-[#716F70]">
        {description}
      </p>
    </div>
  );
};

export default ProductDescription;
