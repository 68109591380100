import { useNavigate } from "react-router-dom";
import { routes } from "../routes/route";

const useRedirection = () => {
  const navigate = useNavigate();

  const redirectAdminDashboard = () => {
    navigate("/user/admin-dashboard");
  };
  const redirectUserDashboard = () => {
    navigate("/user/supplier-dashboard");
  };
  const redirectLogin = () => {
    navigate("/");
  };
  const redirectToLoginPage = () => {
    navigate("/userlogin");
  };
  const redirectPasswordReset = (token) => {
    if (token) {
      navigate(`/reset-password/${token}`);
    }
  };
  const redirectAccountProfile = () => {
    navigate("/user/account-profile");
  };
  const redirectToAddDeals = () => {
    navigate("/user/add-deals");
  };
  const redirectToDeals = () => {
    navigate(`/${routes.deals}`);
  };
  const redirectoSearchDeals = (data) => {
    navigate(`/${routes.dealsSlug}/${data}`);
  };
  const redirectoEditDeals = (id) => {
    navigate(`/user/edit-deals/${id}`);
  };
  const redirectToSupplierProfile = () => {
    navigate("/user/supplier-profile");
  };
  const redirectToSubscribe = () => {
    navigate("/subscribe");
  };
  const redirectToTour = () => {
    navigate("/tour-step-1");
  };
  const redirectoManageDeals = () => {
    navigate("/user/manage-deals");
  };
  const redirectToDealsBasketEmpty = () => {
    navigate("/my-deals-basket-empty");
  };
  const redirectToDealsBasketNoLogin = () => {
    navigate("/my-deals-basket-no-empty");
  };
  const redirectToDealsBasketFilled = () => {
    navigate("/my-deals-basket-filled");
  };
  const redirectTotestimonials = () => {
    navigate("/all-testimonials");
  };

  const redirectoDeal = (data) => {
    navigate(`${routes.single_deals_root}${data}`);
  };

  return {
    redirectAdminDashboard,
    redirectUserDashboard,
    redirectLogin,
    redirectPasswordReset,
    redirectAccountProfile,
    redirectToAddDeals,
    redirectToLoginPage,
    redirectToDeals,
    redirectoSearchDeals,
    redirectoEditDeals,
    redirectToSupplierProfile,
    redirectToSubscribe,
    redirectToTour,
    redirectoManageDeals,
    redirectoDeal,
    redirectToDealsBasketEmpty,
    redirectToDealsBasketNoLogin,
    redirectToDealsBasketFilled,
    redirectTotestimonials,
  };
};
export default useRedirection;
