import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  RegisterUser,
  checkUserName,
} from "../../../../store/slices/authSlice"; // Adjust the path as needed
import Spinner from "../../../../assets/spinner/Spinner"; // Adjust the path as needed
import { eyeClose, eyeOpen } from "../../../../assets/images/v1/single";
import error from "../../../../assets/images/v1/single/error.svg";
import success from "../../../../assets/images/v1/single/success.svg";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import GoogleIcon from "../../../../assets/icons/JSXIcons/GoogleIcon";
import CustomToast from "../../../sharedComponent/Toast/Toast";

const validationSchema = Yup.object({
  username: Yup.string().required("Username is Required"),
  gender: Yup.string()
    .oneOf(["male", "female"], "Please choose salutation")
    .required("Please Choose Salutation"),
  firstName: Yup.string().required("First Name is Required"),
  lastName: Yup.string().required("Last Name is Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is Required")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      "Password should be at least 8 characters, include at least one letter, one number, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required"),
  agree: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
  newsletter_subscribe: Yup.boolean(),
});

const RegisterForm = ({ goToLoginModal }) => {
  const dispatch = useDispatch();

  // States for toggling password visibility, checking availability, and custom toast messages.
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [passwordEye, setPasswordEye] = useState(eyeOpen);
  const [cPasswordEye, setCPasswordEye] = useState(eyeOpen);
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loadingField, setLoadingField] = useState("");
  const [customToast, setCustomToast] = useState(null);

  // Check username or email availability.
  const handleChange = async (field, value) => {
    if (value !== "" && value.length > 3) {
      setLoadingField(field);
      try {
        const response = await dispatch(
          checkUserName({ username: value })
        ).unwrap();
        if (response.data.isAvailable === true) {
          field === "username"
            ? setUsernameError("Username Available")
            : setEmailError("Email is Available");
        } else {
          field === "username"
            ? setUsernameError("Username Not Available")
            : setEmailError("Email is Not Available");
        }
      } catch (error) {
        // Optionally handle error here.
      } finally {
        setLoadingField("");
      }
    } else if (value !== "" && value.length <= 3) {
      setUsernameError("");
      setEmailError("");
    } else if (value === "") {
      setUsernameError("");
      setEmailError("");
    }
  };

  // Form submission handler.
  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    try {
      const {
        username,
        email,
        confirmPassword,
        firstName,
        lastName,
        gender,
        newsletter_subscribe,
      } = values;
      const genderofperson = gender === "male" ? "M" : "F";
      // Invert newsletter_subscribe so that if unchecked (false) the user is subscribed.
      const newObj = {
        username,
        email,
        password: confirmPassword,
        firstName,
        lastName,
        gender: genderofperson,
        newsletter_subscribe: !newsletter_subscribe,
      };

      setSubmitting(true);
      const response = await dispatch(RegisterUser(newObj));

      if (
        response.type === "register/fulfilled" &&
        response.payload.message === "User registered successfully"
      ) {
        setIsRegisterSuccess(true);
        setCustomToast({
          type: "success",
          message: "User registered successfully",
        });
        resetForm();
      } else if (response.type === "register/rejected") {
        setFieldError(
          "general",
          response.payload || "Register failed. Please try again."
        );
        setCustomToast({
          type: "error",
          message: "Register failed. Please try again.",
        });
      }
    } catch (error) {
      setFieldError("general", "An error occurred. Please try again.");
      setCustomToast({ type: "error", message: "Registration failed" });
    } finally {
      setSubmitting(false);
    }
  };

  // Google registration success handler.
  const handleGoogleRegisterSuccess = async (credentialResponse) => {
    try {
      const token = credentialResponse.credential;
      if (!token) {
        console.error("No Google token found", credentialResponse);
        return;
      }
      const googlePayload = {
        authToken: token,
        is_google_login: "true",
      };

      const response = await dispatch(RegisterUser(googlePayload));
      if (response.type === "register/fulfilled") {
        if (
          response.payload.message &&
          response.payload.message.includes("already registered")
        ) {
          setCustomToast({
            type: "error",
            message: "User is already registered. Please login.",
          });
        } else {
          setCustomToast({
            type: "success",
            message: "User registered successfully via Google",
          });
          window.location.href = "/";
        }
      } else {
        console.error("Google registration failed", response.payload);
        setCustomToast({
          type: "error",
          message: "Google registration failed",
        });
      }
    } catch (error) {
      console.error("Error during Google registration", error);
      setCustomToast({
        type: "error",
        message: "Error during Google registration",
      });
    }
  };

  const handleGoogleRegisterFailure = () => {
    console.error("Google Registration Failed");
    setCustomToast({ type: "error", message: "Google Registration Failed" });
  };

  return (
    <div>
      {/* Render the custom toast if set */}
      {customToast && (
        <CustomToast {...customToast} closeToast={() => setCustomToast(null)} />
      )}
      <Formik
        initialValues={{
          username: "",
          gender: "",
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          agree: true,
          newsletter_subscribe: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, setFieldValue }) =>
          isSubmitting || isRegisterSuccess ? (
            <div className="flex justify-center items-center h-screen">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-3 phone:gap-4">
                <div className="flex items-center gap-2">
                  <svg
                    width={24}
                    height={25}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.12"
                      d="M3 18.8257C3 18.3365 3 18.0919 3.05526 17.8618C3.10425 17.6577 3.18506 17.4626 3.29472 17.2837C3.4184 17.0818 3.59136 16.9089 3.93726 16.563L16.5 4.00023C17.3285 3.1718 18.6716 3.1718 19.5 4.00023C20.3285 4.82865 20.3285 6.1718 19.5 7.00023L6.93729 19.563C6.59138 19.9089 6.41843 20.0818 6.2166 20.2055C6.03765 20.3152 5.84256 20.396 5.63849 20.445C5.40832 20.5002 5.16372 20.5002 4.67454 20.5002H3V18.8257Z"
                      fill="#E66927"
                    />
                    <path
                      d="M12 20.5002H21M3 20.5002H4.67454C5.16372 20.5002 5.40832 20.5002 5.63849 20.445C5.84256 20.396 6.03765 20.3152 6.2166 20.2055C6.41843 20.0818 6.59138 19.9089 6.93729 19.563L19.5 7.00023C20.3285 6.1718 20.3285 4.82865 19.5 4.00023C18.6716 3.1718 17.3285 3.1718 16.5 4.00023L3.93726 16.563C3.59136 16.9089 3.4184 17.0818 3.29472 17.2837C3.18506 17.4626 3.10425 17.6577 3.05526 17.8618C3 18.0919 3 18.3365 3 18.8257V20.5002Z"
                      stroke="#E66927"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h5 className="text-xl text-neutral-900 font-bold phone:text-lg">
                    Register Free
                  </h5>
                </div>
                <p className="text-sm leading-[21px] text-neutral-500">
                  Sign up today and join thousands of other Wholesale Deals
                  users completely free. Our weekly e-news publication, packed
                  with trade news, secrets and offers comes free with every
                  registration.
                </p>
              </div>
              <Form id="registerForm" className="mt-6">
                {/* Username Field */}
                <div className="flex flex-col gap-2 mb-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      required
                      htmlFor="username"
                      className="text-sm font-medium flex items-center"
                    >
                      Choose Your Username{" "}
                      <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                    </label>
                    {loadingField === "" &&
                      usernameError === "Username Not Available" && (
                        <div className="flex items-center mt-1">
                          <div className="text-red-600 text-sm">
                            {usernameError}
                          </div>
                        </div>
                      )}
                    {loadingField === "" &&
                      usernameError === "Username Available" && (
                        <div className="flex items-center mt-1">
                          <div className="text-green-600 text-sm">
                            {usernameError}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="relative">
                    <Field
                      type="text"
                      name="username"
                      placeholder="Enter username..."
                      className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg pr-10"
                      onChange={(e) => {
                        handleChange("username", e.target.value);
                        setFieldValue("username", e.target.value);
                      }}
                    />
                    {loadingField === "username" && (
                      <span
                        className="loader absolute right-2"
                        style={{ marginTop: "0.8rem" }}
                      ></span>
                    )}
                    {loadingField === "" &&
                      usernameError === "Username Not Available" && (
                        <img
                          id="unavailableUsernameIcon"
                          className="absolute right-3 top-3 w-[20px] h-[20px]"
                          src={error}
                          alt="Unavailable"
                        />
                      )}
                    {loadingField === "" &&
                      usernameError === "Username Available" && (
                        <img
                          id="availableUsernameIcon"
                          className="absolute right-3 top-3 w-[20px] h-[20px]"
                          src={success}
                          alt="Available"
                        />
                      )}
                  </div>
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>
                {/* Gender */}
                <div className="flex items-center gap-1">
                  <label
                    required
                    htmlFor="gender"
                    className="text-sm font-medium flex items-center gap-1"
                  >
                    Salutation{" "}
                    <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                  </label>
                  <br />
                </div>
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 ">
                  <div
                    className="flex items-center gap-2 border border-neutral-200 w-full sm:w-[17rem] py-3 px-4 rounded cursor-pointer"
                    onClick={() =>
                      document.getElementById("genderMale").click()
                    }
                  >
                    <Field
                      id="genderMale"
                      type="radio"
                      name="gender"
                      value="male"
                      className="accent-primary-main cursor-pointer"
                    />
                    <label htmlFor="genderMale">Mr.</label>
                  </div>
                  <div
                    className="flex items-center gap-2 border border-neutral-200 w-full sm:w-[17rem] py-3 px-4 rounded cursor-pointer"
                    onClick={() =>
                      document.getElementById("genderFemale").click()
                    }
                  >
                    <Field
                      id="genderFemale"
                      type="radio"
                      name="gender"
                      value="female"
                      className="accent-primary-main cursor-pointer"
                    />
                    <label htmlFor="genderFemale">Ms.</label>
                  </div>
                </div>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="text-red-600 text-sm mt-2 mb-4"
                />
                {/* Full Name */}
                <div
                  className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4"
                  style={{ marginTop: "1rem" }}
                >
                  <div className="flex flex-col gap-2 mb-4">
                    <label
                      required
                      htmlFor="firstName"
                      className="text-sm font-medium flex items-center gap-1"
                    >
                      First Name{" "}
                      <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                    </label>
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="Enter your first name..."
                      className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-red-600 text-sm"
                    />
                  </div>
                  <div className="flex flex-col gap-2 mb-4">
                    <label
                      required
                      htmlFor="lastName"
                      className="text-sm font-medium flex items-center gap-1"
                    >
                      Last Name{" "}
                      <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                    </label>
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Enter your last name..."
                      className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-600 text-sm"
                    />
                  </div>
                </div>
                {/* Email */}
                <div className="flex flex-col gap-2 mb-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      required
                      htmlFor="email"
                      className="text-sm font-medium flex items-center gap-1"
                    >
                      Email{" "}
                      <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                    </label>
                    {loadingField === "" &&
                      emailError === "Email is Not Available" && (
                        <div className="flex items-center mt-1">
                          <div className="text-red-600 text-sm">
                            {emailError}
                          </div>
                        </div>
                      )}
                    {loadingField === "" &&
                      emailError === "Email is Available" && (
                        <div className="flex items-center mt-1">
                          <div className="text-green-600 text-sm">
                            {emailError}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="relative">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your best email address..."
                      className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                      onChange={(e) => {
                        handleChange("email", e.target.value);
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {loadingField === "email" && (
                      <span
                        className="loader absolute right-2"
                        style={{ marginTop: "0.8rem" }}
                      ></span>
                    )}
                    {loadingField === "" &&
                      emailError === "Email is Available" && (
                        <img
                          id="availableUsernameIcon"
                          className="absolute right-3 top-3 w-[20px] h-[20px]"
                          src={success}
                          alt="Available"
                        />
                      )}
                    {loadingField === "" &&
                      emailError === "Email is Not Available" && (
                        <img
                          id="unavailableUsernameIcon"
                          className="absolute right-3 top-3 w-[20px] h-[20px]"
                          src={error}
                          alt="Unavailable"
                        />
                      )}
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>
                {/* Password & Confirm Password */}
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="flex flex-col gap-2 mb-4">
                    <label
                      required
                      htmlFor="password"
                      className="text-sm font-medium flex items-center gap-1"
                    >
                      Choose Your Password{" "}
                      <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                    </label>
                    <div className="relative">
                      <Field
                        type={passwordEye === eyeOpen ? "password" : "text"}
                        name="password"
                        placeholder="Enter a password of your choice..."
                        className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                      />
                      <img
                        src={passwordEye}
                        alt=""
                        className="absolute top-3 right-3"
                        onClick={() =>
                          passwordEye === eyeOpen
                            ? setPasswordEye(eyeClose)
                            : setPasswordEye(eyeOpen)
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-600 text-sm"
                    />
                  </div>
                  <div className="flex flex-col gap-2 mb-4">
                    <label
                      required
                      htmlFor="confirmPassword"
                      className="text-sm font-medium flex items-center gap-1"
                    >
                      Confirm Password{" "}
                      <i className="bi bi-star-fill text-red-600 text-[0.4rem] pb-2 inline-block"></i>
                    </label>
                    <div className="relative">
                      <Field
                        type={cPasswordEye === eyeOpen ? "password" : "text"}
                        name="confirmPassword"
                        placeholder="Re-enter password..."
                        className="w-full text-sm border border-neutral-200 px-4 py-3 rounded-lg"
                      />
                      <img
                        src={cPasswordEye}
                        alt=""
                        className="absolute top-3 right-3"
                        onClick={() =>
                          cPasswordEye === eyeOpen
                            ? setCPasswordEye(eyeClose)
                            : setCPasswordEye(eyeOpen)
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="text-red-600 text-sm"
                    />
                  </div>
                </div>
                {/* Newsletter Checkbox */}
                <div className="flex items-center gap-2 mb-6">
                  <Field
                    id="newsletter_subscribe"
                    type="checkbox"
                    name="newsletter_subscribe"
                    className="accent-primary-main cursor-pointer"
                  />
                  <label
                    htmlFor="newsletter_subscribe"
                    className="text-sm cursor-pointer"
                  >
                    I do not wish to receive the newsletter
                  </label>
                  <ErrorMessage
                    name="newsletter_subscribe"
                    component="div"
                    className="text-red-600 text-sm"
                  />
                </div>
                {/* Terms Agreement */}
                <div className="flex items-center gap-2 mb-6">
                  <Field
                    id="termsAgreement"
                    type="checkbox"
                    name="agree"
                    className="w- accent-primary-main border border-[#CBD5E1] cursor-pointer"
                  />
                  <label
                    htmlFor="agree"
                    className="text-sm font-medium"
                    onClick={() =>
                      document.getElementById("termsAgreement").click()
                    }
                  >
                    I have read and agree to the Wholesale Deals Terms
                  </label>
                </div>
                <ErrorMessage
                  name="agree"
                  component="div"
                  className="text-red-600 text-sm mt-[-1.1rem] mb-4"
                />
                {errors.general && (
                  <div className="text-red-600 text-sm mb-4">
                    {errors.general}
                  </div>
                )}
                <div className="flex flex-col gap-4 justify-center items-center">
                  <button
                    type="submit"
                    className="py-3 flex justify-center items-center text-sm text-white font-semibold bg-[#0355A5] rounded-lg w-full"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner /> : "SIGN UP"}
                  </button>
                </div>
              </Form>
              {/* Google Registration Section */}
              <div className="my-6">
                <div className="flex items-center justify-center mt-2 mb-4">
                  <div className="flex items-center w-full max-w-xs">
                    <div className="flex-grow h-px bg-gray-200"></div>
                    <span className="px-3 text-sm font-medium text-gray-500">
                      Or Register with
                    </span>
                    <div className="flex-grow h-px bg-gray-200"></div>
                  </div>
                </div>
                <div className="my-4 flex flex-col justify-center w-full">
                  <GoogleOAuthProvider clientId="122097334230-0o8rkpub3dgkktpgat1oufei1vgqbv88.apps.googleusercontent.com">
                    <div className="w-full relative">
                      <div className="border-white border-2 z-10 relative flex justify-center items-center rounded-md w-full">
                        <GoogleLogin
                          onSuccess={handleGoogleRegisterSuccess}
                          onError={handleGoogleRegisterFailure}
                          width="100%"
                          useOneTap
                        />
                      </div>
                      <div className="absolute inset-0 z-20 pointer-events-none flex items-center justify-center gap-3 bg-[#F8FaFC] text-black py-2.5 px-4 rounded-md w-full">
                        <GoogleIcon />
                        <p className="font-medium text-[#1C1917] text-base">
                          Register with Google
                        </p>
                      </div>
                    </div>
                  </GoogleOAuthProvider>
                </div>
              </div>
            </>
          )
        }
      </Formik>
    </div>
  );
};

export default RegisterForm;
