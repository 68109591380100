import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETRETAILERSLIST } from "../api";
export const getRetailersList = createAsyncThunk(
  "/getRetailersList",
  async () => {
    const response = await axios.get(GETRETAILERSLIST);
    return response.data;
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const commonDropdownSlice = createSlice({
  name: "common",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRetailersList.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getRetailersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRetailersList.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload;
      });
  },
});
export default commonDropdownSlice.reducer;
