import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GETBUYERPROFILE, UPDATEBUYERPROFILE } from "../api";
import logout from "../../shared/logout";
export const getBuyerProfile = createAsyncThunk(
  "/getbuyerbyid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GETBUYERPROFILE}/${data.userId}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Return a custom error message using rejectWithValue
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the buyer profile."
      );
    }
  }
);

export const UpdateBuyerUser = createAsyncThunk(
  "/updatebuyerbyid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPDATEBUYERPROFILE, data.formData, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("UpdateBuyerUser", response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating buyer user:", error);
      // If the error is a response from the server (with status code)

      if (error.response) {
        // Return the error response from the server
        return rejectWithValue(error.response.data);
      } else if (error.request) {
        // Request was made but no response was received
        console.log("error.request", error.request);
        return rejectWithValue("No response from server");
      } else {
        // Something else caused the error
        console.log("error.message", error.message);
        return rejectWithValue("Error: " + error.message);
      }
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBuyerProfile.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
        state.error = null; // Clear any previous error
      })
      .addCase(getBuyerProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Clear any previous error while loading
      })
      .addCase(getBuyerProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
        console.log("action.payload.message", action);
      })
      .addCase(UpdateBuyerUser.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(UpdateBuyerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpdateBuyerUser.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload.message === "Forbidden: Invalid Token") {
          logout();
        }
        console.log(action.payload);
        // state.error = action.payload.error;
      });
  },
});
export default buyerSlice.reducer;
