import React from "react";

const TestimonialCard = ({ testimonial }) => {
  const { rating, text, author, company } = testimonial;

  return (
    <div className="w-full bg-white p-[32px] grid gap-[24px] rounded-[24px] border border-[#F9FBFB]">
      <div className="w-full flex items-center gap-[5px]">
        {Array(rating)
          .fill()
          .map((_, i) => (
            <img
              key={i}
              src="../assets/images/v1/icons/box-rating.svg"
              className="w-[24px] h-[24px]"
              alt="rating"
            />
          ))}
      </div>
      <p className="text-[16px] leading-[24px] text-neutral-900 font-medium">
        {text}
      </p>
      <div className="w-fit grid gap-[3px]">
        <h1 className="font-semibold text-[16px] leading-[24px] text-neutral-800">
          {author}
        </h1>
        <p className="text-neutral-600 text-[14px] leading-[21px] font-normal">
          {company}
        </p>
      </div>
    </div>
  );
};

export default TestimonialCard;
