import React from "react";

const AccessOptions = ({
  subscribeLink,
  subscribeText,
  subscribeIcon,
  addToCartText,
  addToCartIcon,
}) => {
  return (
    <div className="w-full flex items-center tablet:flex-col phone:flex-col gap-[16px] phone:gap-1">
      {/* Subscribe Button */}
      <a
        href={subscribeLink}
        className="w-full phone:min-w-auto flex items-center justify-center gap-[8px] text-white px-[32px] py-[9.5px] phone:px-0 rounded-[8px] bg-primary-main hover:bg-primary-hover transition-all duration-200 font-semibold text-[14px] leading-[21px]"
      >
        <img
          src={subscribeIcon}
          alt="Subscribe Icon"
          className="w-[20px] h-[20px]"
        />
        {subscribeText}
      </a>

      {/* Divider with "Or" text */}
      <div className="flex items-center gap-1 tablet:w-full">
        <div className="hidden tablet:block w-full h-[1px] bg-neutral-200"></div>
        <p className="text-[14px] phone:hidden leading-[21px] font-medium text-neutral-500">
          Or
        </p>
        <div className="hidden tablet:block w-full h-[1px] bg-neutral-200"></div>
      </div>

      {/* Add to Cart Button */}
      <button className="py-[10px] px-[24px] phone:w-full tablet:w-full min-w-fit rounded-[8px] bg-additional-blue flex gap-2 items-center justify-center hover:bg-additional-bluehover transition-all duration-200 text-sm font-semibold text-white">
        <img
          src={addToCartIcon}
          alt="Cart Icon"
          className="w-[20px] h-[20px]"
        />
        <p>{addToCartText}</p>
      </button>
    </div>
  );
};

export default AccessOptions;
