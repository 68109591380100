import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  typeOfSearch: "",
  searchValues: [],
};

// Correctly define the `getGlobalSearch` function
export const getGlobalSearch = createAsyncThunk("getSearch", async (data) => {
  return data;
});

const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    // Add other reducers here as needed
  },
  extraReducers: (builder) => {
    builder.addCase(getGlobalSearch.fulfilled, (state, action) => {
      state.typeOfSearch = action.payload.typeOfSearch;
      state.searchValues = action.payload.searchValues;
      state.isLoading = false;
    });
  },
});

export default globalSearchSlice.reducer;
