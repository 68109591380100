import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {FAVORITE_SUPPLIERS} from "../api";
import logout from "../../shared/logout";
export const getFavouriteSuppliers = createAsyncThunk(
    "/getFavouriteSuppliers",
    async (data , { rejectWithValue }) => { 
      try {
        if (data.dealId === "") {
        const response = await axios.get(`${FAVORITE_SUPPLIERS}`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });
        return response.data;
      } else if(data.dealId !== ""){
        const response = await axios.get(`${FAVORITE_SUPPLIERS}?page=${data.dealId}`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });

        return response.data; // Ensure this is the expected format
      }
      } catch (error) {
       
        return rejectWithValue(
          error.response?.data || "Something went wrong while fetching stats."
        );
      }
    }
  );
  

    const initialState = {
        data:{},
        isLoading: false,
        error: null,
    }
    const favoriteSuppliersSlice = createSlice({
        name: "favorite_suppliers",
        initialState,
        reducers:{},
        extraReducers: (builder) => {
            builder
            .addCase(getFavouriteSuppliers.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getFavouriteSuppliers.fulfilled, (state, action) => {
                // Handle the case when the request is successful
                state.isLoading = false;
                state.data = action.payload.data; // Store the fetched data
              })
              .addCase(getFavouriteSuppliers.rejected, (state, action) => {
                // Handle the case when the request fails
                state.isLoading = false;
                state.error = action.payload || "Failed to load deals.";
                if (action.payload.message === "Forbidden: Invalid Token") {
                  logout();
                }
              });
        }
    })
    export default favoriteSuppliersSlice.reducer;
