import React from "react";

// components
import ProductCard from "../components/ProductCard/ProductCard";
import GuaranteeCard from "../components/GuaranteeCard/GuaranteeCard";
import DealDetailHeading from "../components/DealDetailHeading/DealDetailHeading";
import PriceHideFav from "../components/PriceHideFavWrapper/PriceHideFavWrapper";
import ComparePlatforms from "../components/ComparePlatforms/ComparePlatforms";
import AccessOptions from "../components/AccessOptions/AccessOptions";
import ProductInfo from "../components/ProductInfo/ProductInfo";
import CompanyProfile from "../components/CompanyProfile/CompanyProfile";

// constants
import { getFullAddress, mapGrade } from "../constant";
import { formatDate, getLowestPriceObject } from "../../constant";

const DealsDetalsWrapper = ({ singleData, soldOut }) => {
  const { user, supplier } = singleData;
  const markupPercentage =
    (singleData &&
      singleData.fldDealMarkup &&
      parseFloat(singleData.fldDealMarkup)) ||
    0; // Fallback to 0 if undefined
  const mainImage =
    singleData && typeof singleData === "object" && singleData.fldDealImage1;
  const badgeSrc = "../../../../../assets/images/v1/icons/badge.svg"; // Placeholder for badge image

  // Build thumbnail images array
  const thumbnails = [
    singleData &&
      typeof singleData === "object" &&
      singleData.fldDealImage1 && {
        src: singleData.fldDealImage1,
        isActive: true,
      },
    singleData &&
      typeof singleData === "object" &&
      singleData.fldDealImage2 && {
        src: singleData.fldDealImage2,
        isActive: false,
      },
    singleData &&
      typeof singleData === "object" &&
      singleData.fldDealImage3 && {
        src: singleData.fldDealImage3,
        isActive: false,
      },
  ].filter(Boolean);

  function compareData(dataArray) {
    if (dataArray && dataArray.length) {
      return dataArray.map((data) => ({
        name: "Amazon",
        imageUrl: "../assets/images/v1/single/payment/amazon.png",
        link: data.fldDealSampleLink,
        price: data.fldDealSamplePrice,
        grossProfit: data.fldDealPerUnitProfit,
        markup: data.fldSampleMarkUp,
        retailerID: data.fldRetailerId,
        fldDealCurrencyId: data.fldDealCurrencyId,
      }));
    } else {
      return [];
    }
  }

  const platforms = compareData(singleData.samples);
  const productData = { platforms };

  const price =
    singleData &&
    singleData.prices &&
    singleData.prices.length &&
    getLowestPriceObject(singleData.prices).fldPrice;

  const vat =
    singleData &&
    singleData.prices &&
    singleData.prices.length &&
    getLowestPriceObject(singleData.prices).fldIncVAT
      ? "inc.Vat "
      : "ex.VAT";

  console.log("singleData", singleData);
  return (
    <div className="w-full flex tablet:flex-col phone:flex-col justify-between mb-[56px] tablet:mb-[24px] phone:mb-[16px] gap-[40px] tablet:gap-[32px] phone:gap-[16px]">
      <div className="tablet:w-1/2 phone:max-w-full w-full max-w-[328px]">
        <ProductCard
          soldOut={soldOut}
          markupPercentage={markupPercentage}
          mainImage={mainImage}
          badgeSrc={badgeSrc}
          thumbnails={thumbnails}
        />
        <GuaranteeCard />
      </div>
      {/*  Information product  */}
      <div className="w-full phone:max-w-full phone:min-w-full max-w-[512px] min-w-[512px] tablet:max-w-full h-fit grid gap-[24px] phone:gap-[16px]">
        <DealDetailHeading
          date={formatDate(
            singleData &&
              typeof singleData === "object" &&
              singleData.fldDealCreationDate
          )}
          soldOut={soldOut}
          title={singleData.fldDealTitle}
        />
        <PriceHideFav
          price={price}
          vat={vat}
          rrp={singleData.fldDealRRP}
          soldOut={soldOut}
          id={singleData.fldDealId}
          like={singleData && singleData.favorite && singleData.favorite.length}
          slug={singleData && singleData.fldDealSlug && singleData.fldDealSlug}
          hide={singleData && singleData.hidden && singleData.hidden.length}
          fldDealCurrencyId={singleData && singleData.fldDealCurrencyId}
        />

        <ComparePlatforms {...productData} />
        <AccessOptions
          subscribeLink="https://wholesaledeals.vercel.app/wholesale/subscribe.html"
          subscribeText="Subscribe for Full Access"
          subscribeIcon="../assets/images/v1/deals/lock.svg"
          addToCartText="Add to Cart"
          addToCartIcon="../assets/images/v1/single/payment/cart.svg"
        />
        <ProductInfo
          country={singleData.country_name}
          countryIcon="../assets/images/v1/single/uk.svg"
          minOrder={singleData.fldMinimumOrder}
          availableUnits={singleData.fldAvailableQuantity}
          grade={mapGrade(singleData.fldProductCondition)}
          ean={singleData.fldEANUPCASIN}
          messageText="Message"
          messageIcon="../assets/images/v1/single/payment/message.svg"
          phoneCallText="Phone Call"
          phoneCallIcon="../assets/images/v1/single/payment/phone.svg"
        />
      </div>
      {/*  Desktop version  */}
      {user && (
        <div className="w-full tablet:hidden h-fit p-[24px] phone:p-[16px] company rounded-[12px] bg-white border border-neutral-200">
          <CompanyProfile
            companyName={
              user && user.fldCompanyName && user.fldCompanyName
                ? user.fldCompanyName
                : ""
            }
            companyDescription={supplier.fldProductsOffered}
            isVerified={true}
            country={user.country_name}
            countryIcon="../assets/images/v1/single/uk.svg"
            city={user.fldCity}
            postalCode={user.fldZipCode}
            street={getFullAddress(user.fldAddress, user.fldAddressline2)}
            website={supplier.fldWebsite}
            contactName={user.fldFirstName}
            position={user.fldRoleInCompany}
            surname={user.fldLastName}
            phoneNumber={user.fldPhone || user.fldMobile}
          />
        </div>
      )}
    </div>
  );
};

export default DealsDetalsWrapper;
