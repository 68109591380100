import React, { useEffect } from "react";
import AllCategoriesNav from "../../sharedComponent/AllCategories/components/AllCategoriesNav";
import CounterSection from "../../sharedComponent/CounterSection";

import Footer from "../../sharedComponent/Footer";
import MobileFilterComponent from "./components/MobileFilterComponent/MobileFilterComponent";
import { useDispatch, useSelector } from "react-redux";
import { getDeals } from "../../../store/slices/dealsSlice";
import Cookies from "js-cookie";
import RelatedSearches from "./components/RelatedSearches/RelatedSearches";
import EmailCta from "./components/EmailCTA.jsx/EmailCta";
import DealDetails from "./components/DealDetails/DealDetails";
import MostPopularOffers from "./components/MostPopularOffers/MostPopularOffers";
import Deals from "./Deals";
import { getIdBySlug, getSlugFromUrl } from "./constant";
import { useLocation } from "react-router-dom";
const DealWrapper = () => {
  const mostPopularOffers = useSelector(
    (state) => state.wholesalesuppliers.data
  );
  const location = useLocation();
  const categories = useSelector((state) => state.categories.data);
  const GetCurrentUrl = () => {
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

    return fullUrl;
  };
  // Get the current page, search query, and search mode from query params
  const slug = getSlugFromUrl(GetCurrentUrl());
  const id = getIdBySlug(categories, slug);
  const deals = useSelector((state) => state.common_deals.data);
  console.log("deals", deals);
  return (
    <div className="bg-[#F8FAFC] hello">
      <CounterSection />
      <AllCategoriesNav />
      {/* <MobileFilterComponent /> */}
      <Deals main={false} />
      {mostPopularOffers && mostPopularOffers.length ? (
        <MostPopularOffers data={mostPopularOffers} />
      ) : (
        ""
      )}
      {deals?.categoryDescription && <DealDetails deals={deals} />}
      <RelatedSearches />
      <EmailCta />
      {/* End Form Login */}
      <Footer />
    </div>
  );
};

export default DealWrapper;
