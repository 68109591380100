import React from "react";

// swipers
import { Swiper, SwiperSlide } from "swiper/react";

// components
import TestimonialCard from "./TestimonialCard";

const TestimonialSlider = ({ testimonials }) => {
  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1} // Adjust the number of slides you want to show at once
      navigation={false} // Add navigation arrows
      pagination={{ clickable: true }} // Add pagination if needed
      className="testimonial-slider phone:block hidden w-full mt-[16px]"
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <TestimonialCard testimonial={testimonial} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialSlider;
