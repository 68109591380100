
import React from "react";
import { Link } from "react-router-dom";
import { acceptAllCookies } from "../../../hooks/cookies";
import ReactDOM from "react-dom";

const ManageCookiePopup = ({ onManageClick, onClose }) => {
  // Handler for the Accept Cookies button
  const handleAcceptCookies = () => {
    acceptAllCookies();
    onClose();
  };

  return ReactDOM.createPortal(
    <div className="w-full animate-[cookies-appear_1s_ease_forwards] shadow-[0px_4px_42px_0px_rgba(8,15,52,0.16)] bottom-[-100%] opacity-0 border-t-[1px] border-[#D9DBE9] py-[40px] px-[56px] phone:p-[24px] flex phone:flex-col tablet:flex-col tablet:items-start items-center justify-between fixed bottom-0 left-0 bg-white z-[9999]">
      <div className="phone:w-full desktop:w-[700px] flex phone:flex-col phone:items-start items-center gap-[24px] phone:gap-[16px]">
        <div className="flex items-center justify-between phone:w-full">
          <img
            src="../assets/icons/cookies.svg"
            width="64"
            height="64"
            alt="cookies"
            className="phone:w-[40px] tablet:w-[64px] tablet:h-[64px] phone:h-[40px]"
          />
          <button
            className="hidden close-cookies phone:block"
            onClick={onClose}
          >
            <img
              src="../assets/icons/x-close.svg"
              width="20"
              height="20"
              alt="close"
            />
          </button>
        </div>
        <p className="text-[18px] phone:text-[14px] phone:leading-[21px] leading-[27px] font-medium text-neutral-600">
          Our website uses cookies. By continuing to navigate, you grant us
          permission to deploy cookies as detailed in our{" "}
          <Link to="/privacy-policy">
            <span className=" text-blue-500">Privacy Policy</span>
          </Link>
          .
        </p>
        <button className="hidden close-cookies tablet:block" onClick={onClose}>
          <img
            src="../assets/icons/x-close.svg"
            className="min-w-[20px]"
            width="20"
            height="20"
            alt="close"
          />
        </button>
      </div>
      <div className="w-fit tablet:mt-[24px] phone:w-full tablet:ml-[60px] flex phone:flex-col items-center phone:gap-[12px] gap-[20px] phone:mt-[24px]">
        <button
          className="phone:w-full manage-cookies-btn py-[12px] px-[20px] rounded-[8px] bg-primary-surface justify-center flex items-center gap-[8px]"
          onClick={onManageClick} // Opens the cookie management modal
        >
          <img
            src="../assets/icons/setting.svg"
            width="20"
            height="20"
            alt="setting"
          />
          <p className="text-[16px] leading-[24px] font-medium text-primary-main">
            Manage Cookie
          </p>
        </button>
        <button
          className="phone:w-full accept-cookies-btn py-[12px] px-[20px] rounded-[8px] transition-all duration-200 hover:bg-[#ca5515] bg-primary-main flex justify-center items-center gap-[8px]"
          onClick={handleAcceptCookies}
        >
          <img
            src="../assets/icons/cookies-white.svg"
            width="20"
            height="20"
            alt="cookie"
          />
          <p className="text-[16px] leading-[24px] font-medium text-white">
            Accept Cookies
          </p>
        </button>
        <button
          className="phone:hidden tablet:hidden close-cookies"
          onClick={onClose}
        >
          <img
            src="../assets/icons/x-close.svg"
            width="20"
            height="20"
            alt="close"
          />
        </button>
      </div>
    </div>,
    document.body
  );
};

export default ManageCookiePopup;
