import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LATEST_SUPPLIERS } from "../api";
export const lastestSuppliers = createAsyncThunk(
  "/lastestSuppliers",
  async (token,{rejectWithValue}) => {
    if (token && token.trim() != "") {
      try {
        const response = await axios.get(`${LATEST_SUPPLIERS}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data; // Make sure the response contains { success, message, data } structure
      } catch (error) {
        return rejectWithValue(
          error.response?.data || { message: "Unknown error" }
        );
      }
    } else {
      const response = await axios.get(LATEST_SUPPLIERS);
      return response.data;
    }
  }
);
const initialState = {
  data: [],
  isLoading: true,
  error: null,
};
const latestSuppliersSlice = createSlice({
  name: "latestsuppliers",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(lastestSuppliers.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoading = false;
      })
      .addCase(lastestSuppliers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lastestSuppliers.rejected, (state, action) => {
        state.isLoading = false;
        state.rejected = action.payload && action.payload.error;
      });
  },
});
export default latestSuppliersSlice.reducer;
