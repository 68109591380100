import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_SUPPLIERS_DETAILS } from "../api";

export const getSingleSupplier = createAsyncThunk(
  "/getSingleSupplier",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_SUPPLIERS_DETAILS}/${data.slug}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          "Something went wrong while fetching the deal profile."
      );
    }
  }
);

const initialState = {
  data: [],
  isLoading: true,
  error: null,
};

const singleSupplierSlice = createSlice({
  name: "single_supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSingleSupplier.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSingleSupplier.fulfilled, (state, action) => {
        // Handle the case when the request is successful
        state.isLoading = false;
        state.data = action.payload.data; // Store the fetched data
      })
      .addCase(getSingleSupplier.rejected, (state, action) => {
        // Handle the case when the request fails
        state.isLoading = false;
        state.error = action.payload || "Failed to load deals.";
      });
  },
});

export default singleSupplierSlice.reducer;
